var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo-app", attrs: { id: "fc_div" } }, [
    _vm.codeImage
      ? _c("div", { staticClass: "qcodeDiv" }, [
          _c("div", { staticClass: "pop" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "imgDiv" },
            [
              _c("div", { staticClass: "codeView" }, [
                _vm._v('请使用"掌管店"APP扫码登录'),
              ]),
              _vm._v(" "),
              _c("el-image", {
                staticClass: "codeImg",
                attrs: { src: _vm.codeImage },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticStyle: { display: "flex" } }, [
      _vm.tabIndex == 1
        ? _c(
            "div",
            { staticClass: "demo-app-main" },
            [
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showSelectShop,
                    "append-to-body": "",
                    width: "40%",
                    title: "切换门店",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showSelectShop = $event
                    },
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "align-items": "center",
                        "justify-content": "center",
                        height: "150px",
                        "margin-bottom": "40px",
                      },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择" },
                          on: { change: _vm.optionChange },
                          model: {
                            value: _vm.shopValue,
                            callback: function ($$v) {
                              _vm.shopValue = $$v
                            },
                            expression: "shopValue",
                          },
                        },
                        _vm._l(_vm.options1, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    visible: _vm.showAddPop,
                    width: "40%",
                    title: "预约信息",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.showAddPop = $event
                    },
                  },
                },
                [
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        "append-to-body": "",
                        visible: _vm.showEditTagPop,
                        width: "40%",
                        title: "编辑服务",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.showEditTagPop = $event
                        },
                      },
                    },
                    [
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            visible: _vm.showSelectServicePop,
                            "append-to-body": "",
                            width: "40%",
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.showSelectServicePop = $event
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "edit_title_box" }, [
                            _vm._v("新建服务"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "inputBox",
                              staticStyle: {
                                padding: "20px 30px 30px 30px",
                                display: "flex",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.serviceTxt,
                                    expression: "serviceTxt",
                                  },
                                ],
                                staticClass: "input_txt",
                                staticStyle: {
                                  padding: "0 10px",
                                  height: "40px",
                                  border: "1px solid #cbcbcb",
                                  "border-radius": "4px",
                                },
                                attrs: { placeholder: "请输入服务名称" },
                                domProps: { value: _vm.serviceTxt },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.serviceTxt = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "input_confirm",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "center",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "btn",
                                  staticStyle: {
                                    height: "45px",
                                    width: "100px",
                                    "background-color": "var(--main-color)",
                                    color: "#fff",
                                    "border-radius": "8px",
                                    display: "flex",
                                    "justify-content": "center",
                                    "align-items": "center",
                                  },
                                  on: { click: _vm.confirmServiceTag },
                                },
                                [_vm._v("确认")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "edit_service" }, [
                        _c(
                          "div",
                          {
                            staticClass: "edit_tag",
                            staticStyle: { margin: "10px" },
                          },
                          [_vm._v("已选服务")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "service_input",
                            staticStyle: { "min-height": "50px" },
                          },
                          _vm._l(_vm.serviceData, function (a, b) {
                            return _c(
                              "div",
                              {
                                staticClass: "service_item",
                                staticStyle: {
                                  "background-color": "var(--main-color)",
                                  color: "#ffffff",
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(a.product_name) +
                                    "\n              "
                                ),
                                _c("img", {
                                  attrs: {
                                    src: require("../../src/static/image/close.png"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeService(a)
                                    },
                                  },
                                }),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "edit_time_data",
                            staticStyle: {
                              "justify-content": "flex-start",
                              display: "flex",
                              "flex-direction": "column",
                              "margin-bottom": "20px",
                              "font-size": "18px",
                              "font-weight": "600",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "edit_tag",
                                staticStyle: {
                                  margin: "10px",
                                  "font-size": "14px",
                                },
                              },
                              [_vm._v("全部服务")]
                            ),
                            _vm._v(" "),
                            this.serviceList && this.serviceList.length > 0
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "edit_service_tag",
                                    staticStyle: { "margin-bottom": "20px" },
                                  },
                                  _vm._l(this.serviceList, function (x, y) {
                                    return _c(
                                      "div",
                                      {
                                        staticClass: "service_item",
                                        class: x.status ? "act" : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.tapServiceTag(x)
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(x.product_name) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "addService",
                                on: { click: _vm.selectService },
                              },
                              [
                                _vm._v("+"),
                                _c(
                                  "span",
                                  { staticStyle: { "margin-left": "5px" } },
                                  [_vm._v("新建服务")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "input_confirm",
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "center",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "btn",
                              staticStyle: {
                                height: "45px",
                                width: "100px",
                                "background-color": "var(--main-color)",
                                color: "#fff",
                                "border-radius": "8px",
                                display: "flex",
                                "justify-content": "center",
                                "align-items": "center",
                              },
                              on: { click: _vm.confirmEditTag },
                            },
                            [_vm._v("确认")]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        visible: _vm.showSearch,
                        "append-to-body": "",
                        width: "40%",
                        title: "预约信息",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.showSearch = $event
                        },
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "user_list",
                          attrs: { id: "scroll_list" },
                        },
                        [
                          _c("div", { staticClass: "user_search_input" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.search_value,
                                  expression: "search_value",
                                },
                              ],
                              attrs: { placeholder: "请输入顾客名" },
                              domProps: { value: _vm.search_value },
                              on: {
                                confirm: _vm.searchFun,
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.search_value = $event.target.value
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "searchBtn",
                                on: { click: _vm.searchFun },
                              },
                              [_vm._v("搜索")]
                            ),
                          ]),
                          _vm._v(" "),
                          _vm._l(_vm.customer_list, function (item, index) {
                            return _c(
                              "div",
                              {
                                key: index,
                                staticClass: "user_list_item",
                                on: {
                                  click: function ($event) {
                                    return _vm.selectConsumer(item)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "name" }, [
                                  _vm._v("姓名：" + _vm._s(item.user_name)),
                                ]),
                                _vm._v(" "),
                                item.mobile
                                  ? _c("div", { staticClass: "phone" }, [
                                      _vm._v(
                                        "手机：" +
                                          _vm._s(
                                            item.mobile.substr(0, 4) +
                                              "****" +
                                              item.mobile.substr(8, 11)
                                          )
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-dialog",
                    {
                      attrs: {
                        visible: _vm.userPop,
                        "append-to-body": "",
                        width: "40%",
                        title: "系统检测到相似客户",
                      },
                      on: {
                        "update:visible": function ($event) {
                          _vm.userPop = $event
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "card_cont" }, [
                        _c(
                          "div",
                          {
                            staticClass: "tip",
                            staticStyle: { "font-size": "15px" },
                          },
                          [
                            _c("div", { staticClass: "txt" }, [
                              _vm._v("客户名字：" + _vm._s(_vm.cu_name)),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "txt" }, [
                              _vm._v("手机：" + _vm._s(_vm.user_phone)),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "txt",
                                staticStyle: {
                                  "margin-top": "20px",
                                  "font-weight": "600",
                                  "font-size": "15px",
                                },
                              },
                              [_vm._v("是否要启用该档案?")]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "card_foot" }, [
                          _c(
                            "div",
                            {
                              staticClass: "card_cancle foot_btn",
                              staticStyle: {
                                "font-weight": "600",
                                color: "#999",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.userPop = false
                                },
                              },
                            },
                            [_vm._v("取消")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "card_confirm foot_btn",
                              staticStyle: { "font-weight": "600" },
                              on: {
                                click: function ($event) {
                                  return _vm.submitCard(_vm.newData)
                                },
                              },
                            },
                            [_vm._v("确认\n            ")]
                          ),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "editData", on: { click: _vm.closeCusPop } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "edit_section",
                          staticStyle: {
                            "margin-bottom": "10px",
                            display: "flex",
                            "align-items": "center",
                            "justify-content": "space-between",
                            "flex-direction": "row",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "head_time" },
                            [
                              _vm._v("日期：\n              "),
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  placeholder: "选择日期",
                                  format: "yyyy 年 MM 月 dd 日",
                                  "value-format": "yyyy-MM-dd",
                                },
                                on: { change: _vm.changeTime },
                                model: {
                                  value: _vm.popDate,
                                  callback: function ($$v) {
                                    _vm.popDate = $$v
                                  },
                                  expression: "popDate",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "edit_section",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "edit_title",
                              staticStyle: {
                                display: "flex",
                                "align-items": "center",
                                "justify-content": "space-between",
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-weight": "600" } },
                                [_vm._v("技师")]
                              ),
                              _c(
                                "span",
                                {
                                  staticStyle: {
                                    color: "var(--main-color)",
                                    "font-weight": "600",
                                  },
                                  on: { click: _vm.addUser },
                                },
                                [_vm._v("+添加技师")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "edit_time_data",
                              staticStyle: {
                                "justify-content": "flex-start",
                                "flex-wrap": "wrap",
                              },
                            },
                            _vm._l(_vm.service_arr, function (item, index) {
                              return _vm.cardData.service_arr &&
                                _vm.cardData.service_arr.length > 0
                                ? _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "edit_user_data",
                                      staticStyle: {
                                        border: "1px solid var(--main-color)",
                                        "border-radius": "6px",
                                        padding: "20px",
                                        "margin-bottom": "10px",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "edit_time" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "edit_time_data",
                                              staticStyle: { flex: "1" },
                                            },
                                            [
                                              _c("el-time-select", {
                                                staticClass: "stime",
                                                attrs: {
                                                  "picker-options": {
                                                    start: "08:00",
                                                    step: "00:15",
                                                    end: "22:00",
                                                  },
                                                  placeholder: "选择时间",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.dateConfirm(
                                                      $event,
                                                      item,
                                                      index,
                                                      1
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.s_time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "s_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.s_time",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "line" },
                                                [_vm._v("-")]
                                              ),
                                              _vm._v(" "),
                                              _c("el-time-select", {
                                                staticClass: "etime",
                                                attrs: {
                                                  "picker-options": {
                                                    start: "08:00",
                                                    step: "00:15",
                                                    end: "22:00",
                                                  },
                                                  placeholder: "选择时间",
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.dateConfirm(
                                                      $event,
                                                      item,
                                                      index,
                                                      2
                                                    )
                                                  },
                                                },
                                                model: {
                                                  value: item.e_time,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "e_time",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.e_time",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "userview",
                                              staticStyle: { border: "0px" },
                                              attrs: { "value-key": "id" },
                                              on: {
                                                change: function ($event) {
                                                  return _vm.userConfirm(
                                                    $event,
                                                    item,
                                                    index
                                                  )
                                                },
                                              },
                                              model: {
                                                value: item.name,
                                                callback: function ($$v) {
                                                  _vm.$set(item, "name", $$v)
                                                },
                                                expression: "item.name",
                                              },
                                            },
                                            _vm._l(
                                              _vm.user_list,
                                              function (item) {
                                                return _c("el-option", {
                                                  key: item.id,
                                                  attrs: {
                                                    label: item.title,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "edit_service" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "edit_tag",
                                              staticStyle: {
                                                "font-weight": "600",
                                              },
                                            },
                                            [_vm._v("已加服务")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "edit_time_data",
                                              staticStyle: {
                                                "justify-content": "flex-start",
                                              },
                                            },
                                            [
                                              item.product_arr &&
                                              item.product_arr.length > 0
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "edit_service_tag",
                                                    },
                                                    _vm._l(
                                                      item.product_arr,
                                                      function (x, y) {
                                                        return _c(
                                                          "div",
                                                          {
                                                            key: y,
                                                            staticClass:
                                                              "service_item",
                                                            class: x.status
                                                              ? "act"
                                                              : "",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  x.product_name
                                                                ) +
                                                                "\n                        "
                                                            ),
                                                            _c("img", {
                                                              attrs: {
                                                                src: require("../../src/static/image/close-bold.png"),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.closeService(
                                                                      x
                                                                    )
                                                                  },
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "addService",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.editService(
                                                        index,
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v("+"),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "margin-left": "5px",
                                                        "font-weight": "600",
                                                      },
                                                    },
                                                    [_vm._v("编辑服务")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("img", {
                                        attrs: {
                                          src: require("../../src/static/image/close1.png"),
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.delFun(item)
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e()
                            }),
                            0
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "edit_section" }, [
                        _c("div", { staticClass: "edit_title" }, [
                          _vm._v("客户"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "edit_time_data",
                            staticStyle: { "justify-content": "flex-start" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "search_user",
                                on: { click: _vm.toSearch },
                              },
                              [_vm._v("\n                搜索\n              ")]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "edit_section" }, [
                        _c("div", { staticClass: "edit_title" }, [
                          _vm._v("姓名"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "edit_time_data",
                            staticStyle: {
                              "justify-content": "flex-start",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.userName,
                                  expression: "userName",
                                },
                              ],
                              staticClass: "userview",
                              class: _vm.selectConsumerStatus ? "active" : "",
                              attrs: { disabled: _vm.selectConsumerStatus },
                              domProps: { value: _vm.userName },
                              on: {
                                input: [
                                  function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.userName = $event.target.value
                                  },
                                  _vm.inputIng,
                                ],
                              },
                            }),
                            _vm._v(" "),
                            _vm.customer_arr &&
                            _vm.customer_arr.length > 0 &&
                            _vm.userName &&
                            _vm.showCusPop
                              ? _c("div", { staticClass: "seachUserListPop" }, [
                                  _c(
                                    "div",
                                    { staticStyle: { height: "135px" } },
                                    [
                                      _c(
                                        "div",
                                        _vm._l(
                                          _vm.customer_arr,
                                          function (n, m) {
                                            return _c(
                                              "div",
                                              {
                                                key: m,
                                                staticClass: "useritem",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.tapUserData(n)
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(n.user_name) +
                                                    "\n                    "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.selectConsumerStatus
                              ? _c("img", {
                                  staticStyle: { top: "12px", right: "10px" },
                                  attrs: {
                                    src: require("../../src/static/image/close1.png"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clearInput(0)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "edit_section" }, [
                        _c("div", { staticClass: "edit_title" }, [
                          _vm._v("电话"),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "edit_time_data",
                            staticStyle: { "justify-content": "flex-start" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  display: "flex",
                                  "align-items": "center",
                                  "justify-content": "center",
                                  position: "relative",
                                  flex: "1",
                                },
                              },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.user_phone,
                                      expression: "user_phone",
                                    },
                                  ],
                                  staticClass: "userview",
                                  class: _vm.selectConsumerStatus
                                    ? "active"
                                    : "",
                                  attrs: { disabled: _vm.selectConsumerStatus },
                                  domProps: { value: _vm.user_phone },
                                  on: {
                                    input: function ($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.user_phone = $event.target.value
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.selectConsumerStatus
                                  ? _c("img", {
                                      staticStyle: {
                                        top: "12px",
                                        right: "10px",
                                      },
                                      attrs: {
                                        src: require("../../src/static/image/close1.png"),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearInput(1)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            !_vm.selectConsumerStatus
                              ? _c(
                                  "el-checkbox",
                                  {
                                    staticStyle: { "margin-left": "15px" },
                                    model: {
                                      value: _vm.checked,
                                      callback: function ($$v) {
                                        _vm.checked = $$v
                                      },
                                      expression: "checked",
                                    },
                                  },
                                  [_vm._v("该客户不建档")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "edit_section",
                          staticStyle: { "margin-bottom": "100px" },
                        },
                        [
                          _c("div", { staticClass: "edit_title" }, [
                            _vm._v("备注"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "edit_time_data" }, [
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.remark,
                                  expression: "remark",
                                },
                              ],
                              staticClass: "edit_textarea",
                              attrs: { maxlength: "200" },
                              domProps: { value: _vm.remark },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.remark = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.cardData &&
                  _vm.cardData.confirmation != 2 &&
                  _vm.cardData.confirmation != 3
                    ? _c("div", { staticClass: "pop_foot" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btns",
                            staticStyle: { "background-color": "#9d9d9d" },
                            on: { click: _vm.delUser },
                          },
                          [_vm._v("\n            删除\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "btns",
                            staticStyle: {
                              "background-color": "var(--main-color)",
                            },
                            on: { click: _vm.savePopData },
                          },
                          [_vm._v("\n            保存\n          ")]
                        ),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.show_calendar
                ? _c("FullCalendar", {
                    ref: "fullCalendar",
                    staticClass: "demo-app-calendar",
                    attrs: { options: _vm.calendarOptions },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "eventContent",
                          fn: function (arg) {
                            return [
                              _c("b", [_vm._v(_vm._s(arg.timeText))]),
                              _vm._v(" "),
                              _c("i", [_vm._v(_vm._s(arg.event.title))]),
                              _vm._v(" "),
                              arg.event.extendedProps &&
                              arg.event.extendedProps.tagStr
                                ? _c("i", [
                                    _vm._v(
                                      _vm._s(arg.event.extendedProps.tagStr)
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2215970076
                    ),
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }