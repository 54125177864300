import request from '../request'
// 获取你要的数据
export function getData(data) {
	return request({
		url: 'api/mch/user/getDayCoordinate',
		method: 'post',
        data
	})
}
export function courseSave(data) {
	return request({
		url: 'api/mch/school/courseEdit',
		method: 'post',
        data
	})
}
export function getStaffClassList(data) {
	return request({
		url: 'api/mch/user/getStaffClassList',
		method: 'post',
        data
	})
}
export function getMchEmployeeClassList(data) {
	return request({
		url: 'api/mch/mchStore/getMchEmployeeClassList',
		method: 'post',
        data
	})
}
export function addMchActivity(data) {
	return request({
		url: 'api/mch/mchStore/addMchActivity',
		method: 'post',
        data
	})
}
export function getMchActivityList(data) {
	return request({
		url: 'api/mch/mchStore/getMchActivityList',
		method: 'post',
        data
	})
}
export function delMchActivityDate(data) {
	return request({
		url: 'api/mch/mchStore/delMchActivityDate',
		method: 'post',
        data
	})
}
export function setMyMonthClass(data) {
	return request({
		url: 'api/mch/mchStore/setMchEmployeeMonthClass',
		method: 'post',
        data
	})
}
export function delActiveApi(data) {
	return request({
		url: 'api/mch/mchStore/delMchActivityInfo',
		method: 'post',
        data
	})
}
export function setMchActivityDate(data) {
	return request({
		url: 'api/mch/mchStore/setMchActivityDate',
		method: 'post',
        data
	})
}
export function getMchEmployeeList(data) {
	return request({
		url: 'api/mch/mchStore/getMchEmployeeList',
		method: 'post',
        data
	})
}

export function getAttendanceList(data) {
	return request({
		url: 'api/mch/work/getAttendanceList',
		method: 'post',
        data
	})
}

export function editClockinRecord(data) {
	return request({
		url: 'api/mch/employee/editClockinRecord',
		method: 'post',
        data
	})
}

export function courseDelete(data) {
	return request({
		url: 'api/mch/school/courseDel',
		method: 'post',
        data
	})
}

export function upload(data) {
	return request({
		url: 'api/mch/common/uploadFile',
		method: 'post',
        data
	})
}
export function specialTrainingList(data) {
	return request({
		url: 'api/mch/school/specialTrainingList',
		method: 'post',
        data
	})
}
export function specialTrainingSave(data) {
	return request({
		url: 'api/mch/school/specialTrainingSave',
		method: 'post',
        data
	})
}
export function specialTrainingDel(data) {
	return request({
		url: 'api/mch/school/specialTrainingDel',
		method: 'post',
        data
	})
}
export function specialTrainingChapterSave(data) {
	return request({
		url: 'api/mch/school/specialTrainingChapterSave',
		method: 'post',
        data
	})
}
export function courseCateList(data) {
	return request({
		url: 'api/mch/school/categoryList',
		method: 'post',
        data
	})
}
export function courseCateSave(data) {
	return request({
		url: 'api/mch/school/categoryEdit',
		method: 'post',
        data
	})
}
export function courseCateDelete(data) {
	return request({
		url: 'api/mch/school/categoryDel',
		method: 'post',
        data
	})
}
export function getAllTemplateList(data) {
	return request({
		url: 'mch/getAllTemplateList',
		method: 'post',
        data
	})
}
export function ChapterEdit(data) {
	return request({
		url: 'api/mch/school/ChapterEdit',
		method: 'post',
        data
	})
}
export function courseList(data) {
	return request({
		url: 'api/mch/school/CourseList',
		method: 'post',
        data
	})
}
export function getInitData(data) {
	return request({
		url: 'api/mch/specialTraining/getInitData',
		method: 'post',
        data
	})
}
export function setMchClassInfo(data) {
	return request({
		url: 'api/mch/mchStore/setMchClassInfo',
		method: 'post',
        data
	})
}

export function getQrCode(data) {
	return request({
		url: 'api/mch/other/getLoginQrCode',
		method: 'post',
        data
	})
}
export function searchCustomers(data) {
	return request({
		url: 'api/mch/user/SearchCustomers',
		method: 'post',
        data
	})
}
export function moveAppointmentCard(data) {
	return request({
		url: 'api/mch/user/moveAppointmentCard',
		method: 'post',
        data
	})
}
export function instroSave(data) {
	return request({
		url: 'api/mch/user/instroSave',
		method: 'post',
        data
	})
}
export function changeCustomerStage(data) {
	return request({
		url: 'api/mch/customer/changeCustomerStage',
		method: 'post',
        data
	})
}
export function getMchStageList(data) {
	return request({
		url: 'api/mch/customer/getMchStageList',
		method: 'post',
        data
	})
}
export function getCustomerStage(data) {
	return request({
		url: 'api/mch/customer/getCustomerStage',
		method: 'post',
        data
	})
}
export function getCustomerTag(data) {
	return request({
		url: 'api/mch/user/getCustomerTag',
		method: 'post',
        data
	})
}
export function getTagGroupList(data) {
	return request({
		url: 'api/mch/user/getTagGroupList',
		method: 'post',
        data
	})
}
export function addTagInfo(data) {
	return request({
		url: 'api/mch/user/addTagInfo',
		method: 'post',
        data
	})
}
export function getTagList(data) {
	return request({
		url: 'api/mch/user/getTagList',
		method: 'post',
        data
	})
}
export function setCustomerTag(data) {
	return request({
		url: 'api/mch/user/setCustomerTag',
		method: 'post',
        data
	})
}
export function getDayReservationFun(data) {
	return request({
		url: 'api/mch/user/getDayReservation',
		method: 'post',
        data
	})
}
export function getServiceProductList(data) {
	return request({
		url: 'api/mch/user/getServiceProductList',
		method: 'post',
        data
	})
}
export function delAppointmentInfo(data) {
	return request({
		url: 'api/mch/user/delAppointmentInfo',
		method: 'post',
        data
	})
}
export function checkMchUserMobile(data) {
	return request({
		url: 'api/mch/user/checkMchUserMobile',
		method: 'post',
        data
	})
}
export function editAppointmentInfo(data) {
	return request({
		url: 'api/mch/user/editAppointmentInfo',
		method: 'post',
        data
	})
}
export function getMonthReservation(data) {
	return request({
		url: 'api/mch/user/getMonthReservation',
		method: 'post',
        data
	})
}
export function myCustomerHomepage(data) {
	return request({
		url: 'api/mch/user/myCustomerHomepage?is_boss='+data,
		method: 'get',
        data
	})
}
export function addServiceProductTag(data) {
	return request({
		url: 'api/mch/user/addServiceProductTag',
		method: 'post',
        data
	})
}
//话术列表
export function getTemplateItemList(data) {
	return request({
		url: 'api/mch/mchStore/getTemplateItemList',
		method: 'post',
		data
	})
}
//话术节点列表
export function getAllTemplateLists(data) {
	return request({
		url: 'api/mch/mchStore/getAllTemplateList',
		method: 'post',
		data
	})
}
//提交话术
export function submitCustomItem(data) {
	return request({
		url: 'api/mch/user/submitCustomItem',
		method: 'post',
		data
	})
}
