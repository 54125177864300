<template>
    <div>
      <el-container>
        <el-main style="background: white">
          <div class="search">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item label="">
                <el-input
                  placeholder="请输入频道标题"
                  v-model="searchForm.title"
                  clearable=""
                ></el-input>
              </el-form-item>
              <el-form-item label="">
                <el-button class="btn1" @click="getList">查找</el-button>
              </el-form-item>
              <el-form-item label="" style="float: right">
                <el-button type="primary" @click="Edit({})">添加</el-button>
              </el-form-item>
            </el-form>
          </div>
  
          <el-table
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            ref="table"
            style="width: 100%"
            v-loading="loading"
            :data="list"
            :row-style="{ height: '81px' }"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{
              color: '#333',
              fontSize: '14px',
              backgroundColor: '#F6F7FB',
              fontWeight: 'normal',
              textAlign: 'center',
            }"
          >
            <el-table-column prop="mch_name" label="店铺名"></el-table-column>
             <el-table-column prop="rule_type" label="频道权限类型">
              <template slot-scope="scope">
                <div>{{scope.row.rule_type==1?'全员':(scope.row.rule_type==2?'老板':'干部')}}</div>
              </template>
            </el-table-column>
          <el-table-column
            prop="title"
            label="频道名称"
            align="center"
            min-width="120"
          >
          </el-table-column>
          <el-table-column
            prop="subscribe_num"
            label="收藏量"
            align="center"
            min-width="100"
          >
          </el-table-column>
          <el-table-column
            prop="collection_num"
            label="订阅量"
            align="center"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="start_time"
            label="开始时间"
            align="center"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="end_time"
            label="失效时间"
            align="center"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="sort"
            label="排序"
            align="center"
            min-width="50"
          >
          </el-table-column>
          <el-table-column
            label="课程内容"
            align="center"
            min-width="150"
          >
              <template slot-scope="scope">
                <el-link  @click="chapterEdit(scope.row)" type="primary">章节管理 ( {{scope.row.chapter_count}} )</el-link>
              </template>
            </el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
            align="center"
            min-width="150"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="状态"
            align="center"
            min-width="80"
          >
            <template slot-scope="scope">
              <div>
              {{scope.row.status==0?'失效':'有效'}}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" min-width="120" fixed="right">
            <template slot-scope="scope">
                <div class="operation">
                  <el-button class="text_btn" type="text" @click="Edit(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    class="text_btn"
                    type="text"
                    @click="Delete(scope.row)"
                    >删除</el-button
                  >
                </div>
              </template>
          </el-table-column>
          </el-table>
  
          <div class="pagination">
            <el-pagination
              @size-change="handleLimitChange"
              @current-change="handlePageChange"
              :current-page="searchForm.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="searchForm.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </el-main>
  
        <!-- 新建 -->
      <StudyCreate ref="edit" @fetch-data="getList"></StudyCreate>
      <!-- 章节管理 -->
      <StudyChapter ref="chapteredit" @fetch-data="getList"></StudyChapter>
  
      </el-container>
    </div>
  </template>
  <script>
  import StudyCreate from './studyCreate.vue'
  import StudyChapter from './studyChapter.vue'
  import { courseCateList, courseCateSave, courseCateDelete } from "../../src/utils/API/api";
  import { upload } from '../../src/utils/API/api'

  export default {
    components: {
      StudyCreate,
      StudyChapter,
    },
    data() {
      return {
        loading: false,
        buttonLoading: false,
        total: 0,
        list: [],
        advPosList: [],
        title: "添加频道",
        advEditVisible: false,
        cateEditForm: {
          id: 0,
          pos_id: "",
          title: "",
          url: "",
          picture: "",
          sort: 100,
          start_time: "",
          end_time: "",
        },
        searchForm: {
          title: "",
          query_mch_id: "",
          rule_type: '',
          page: 1,
          limit: 10,
        },
        hideUpload: false,
        uploadData: {
            type: '2'
          },
        mch_list: [],
        rule_list: [],
      };
    },
    watch: {},
    created() {
      this.getList();
    },
    mounted() {
      this.tableHeight =
        window.innerHeight - this.$refs.table.$el.offsetTop - 229;
    },
  
    methods: {
      async getList() {
        this.loading = true;
        let res = await courseCateList(this.searchForm);
        if (res.data.code == 0) {
          this.list = res.data.data.list;
          this.total = res.data.data.dataCount;
          this.loading = false;
        }
      },
      handleLimitChange(val) {
        this.searchForm.limit = val;
        this.getList();
      },
      handlePageChange(val) {
        this.searchForm.page = val;
        this.getList();
      },
      Edit(row) {
        if (row) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      async Save() {
        this.$refs["cateEditForm"].validate(async (valid) => {
          if (valid) {
            this.buttonLoading = true;
            let res = await courseCateSave(this.cateEditForm);
            if (res.data.code == 0) {
              this.$message({
                showClose: true,
                message: res.data.data,
                type: "success",
              });
              this.getList();
              this.$refs["cateEditForm"].resetFields();
              this.advEditVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
              });
            }
            this.buttonLoading = false;
          }
        });
      },
      Delete(row) {
        this.$confirm("删除后无法恢复，确认删除？", "提示", {
          cancelButtonClass: "btn-white",
          confirmButtonClass: "btn-blue",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false,
        })
          .then(async () => {
            let res = await courseCateDelete({ id: row.id });
            if (res.data.code == 0) {
              this.$message({
                showClose: true,
                message: res.data.data,
                type: "success",
              });
              this.getList();
              this.advEditVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
              });
            }
          })
          .catch(() => {});
      },
      //章节管理
      chapterEdit(row) {
        this.$refs['chapteredit'].showEdit(row)
      },
      closeDialog(form) {
        console.log(form);
        this.$refs['cateEditForm'].resetFields();
        this.advEditVisible = false;
      },
      handleUploadChange(file, fileList) {
        this.hideUpload = fileList.length >= 1;
      },
      handleUploadRemove(file, fileList) {
        this.cateEditForm.picture = "";
        this.hideUpload = fileList.length >= 1;
      },
      beforeAvatarUpload(file) {
        let types = ["image/jpeg", "image/jpg", "image/png"];
        const isImage = types.includes(file.type);
        const isLtSize = file.size / 1024 / 1024 < 200;
        if (!isImage) {
          this.$message.error("上传图片只能是JPG、JPEG及PNG格式!");
          return false;
        }
        if (!isLtSize) {
          this.$message.error("上传图片大小不能超过 200MB!");
          return false;
        }
        return isImage && isLtSize;
      },
      httpRequest11(obj){
          var that =this
          var formdata=new FormData()
          formdata.append ("file" , obj.file);
          formdata.append ("name" ,obj.file.name);
          upload(formdata).then(function (res) {
            that.cateEditForm.picture= res.data.data.url
          }).catch(function (err) {
            console.log(err)
          })
        },
    },
  };
  </script>
  <style lang="scss" scoped>
    .pagination {
    width: 100%;
    margin: 30px 0;

    .el-pagination {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #2362fb;
  }

  .el-pagination .btn-next,
  .el-pagination .btn-prev {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #fff;
    border-radius: 6px;
    padding: 0;
    min-width: 30px;
    margin: 0 5px;
    border: 1px solid rgba(19, 58, 179, 0.2);
  }

  .el-pager li {
    width: 30px;
    height: 30px;
    line-height: 30px;
    background: #ffffff;
    border-radius: 6px;
    min-width: 30px;
    border: 1px solid rgba(19, 58, 179, 0.2);
    margin: 0 5px;
  }
  img {
    max-width: 100%;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-radius: 6px;
  }

  </style>
  