<template>
    <div class="login-container">
        <div>
            <el-form :inline="true">
                <el-form-item>
                    <el-input placeholder="请输入话术标题" v-model="query.name"  clearable></el-input>
                </el-form-item>
                <el-form-item>
                    <el-select v-model="query.type" placeholder="请选择话术类型" filterable clearable>
                        <el-option v-for="item in pay_setting_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item>-->
<!--                    <el-select v-model="query.store_id" placeholder="请选择商城类型" filterable clearable>-->
<!--                        <el-option v-for="item in getStoreList" :key="item.id" :value="item.id" :label="item.name"></el-option>-->
<!--                    </el-select>-->
<!--                </el-form-item>-->
                <el-form-item>
                    <el-cascader
                            v-model="query.temp_id"
                            :options="dataList"
                            :show-all-levels="false"
                            change-on-select
                            clearable
                            :props="{ expandTrigger: 'click',value:'id',children:'sub_list',label:'name', checkStrictly: true}"
                            @change="handleChange">

                    </el-cascader>
                </el-form-item>
                <el-form-item>
                    <el-button @click="search" type="primary">搜索</el-button>
                </el-form-item>
            </el-form>
        </div>
<!--        <el-form :inline="true">-->
<!--            <el-form-item style="float: right;">-->
<!--                <el-button @click="add" type="primary">新增</el-button>-->
<!--            </el-form-item>-->
<!--        </el-form>-->
        <el-table :data="list" stripe v-loading="table_loading" style="width: 100%">
            <!-- <el-table-column prop="id" label="id"></el-table-column>
            <el-table-column prop="store_name" label="商城"></el-table-column> -->
            <el-table-column prop="name" label="话术标题"></el-table-column>
            <el-table-column prop="type_str" label="话术类型"></el-table-column>
<!--            <el-table-column prop="is_default" label="话术归属"></el-table-column>-->
                  <el-table-column prop="mch_id" label="话术归属">
                    <template slot-scope="scope">
                      <div>{{scope.row.mch_id==0?'平台':'自定义'}}</div>
                    </template>
                  </el-table-column>
            <el-table-column prop="temp_arr" label="节点归属">
                <template slot-scope="scope">
                    <div v-if="scope.row.temp_arr.length>0">
                        {{scope.row.temp_arr.join(',')}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template slot-scope="scope">
                    <el-button @click="edit(scope.row)" type="primary" size="mini">编辑</el-button>
                    <!--          <el-button @click="openRefundModal(scope.row)" type="danger" size="mini">删除</el-button>-->
                </template>
            </el-table-column>
        </el-table>
        <div style="margin-top: 10px; display:flex; justify-content: center;">
<!--            <el-pagination-->
<!--                    layout="total, prev, pager, next"-->
<!--                    :page-size="page"-->
<!--                    :total="count"-->
<!--                    @current-change="handlePageChange">-->
<!--            </el-pagination>-->
            <el-pagination
                    @size-change="handleLimitChange"
                    @current-change="handlePageChange"
                    :current-page="page"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="limit"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count"
            >
            </el-pagination>
        </div>


        <el-dialog  @opened="NewEditor()"    @closed="editorClear()" title="编辑话术" :visible.sync="manageLoding" width="1200px">
            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="话术标题">
                    <el-input  v-model="form.name"></el-input>
                </el-form-item>
                <el-form-item label="话术类型" >
                    <el-select disabled style="width: 100%" v-model="form.type" placeholder="请选择话术类型" filterable clearable>
                        <el-option v-for="item in pay_setting_list" :key="item.id" :value="item.id" :label="item.name"></el-option>
                    </el-select>
                </el-form-item>
<!--                <el-form-item label="天数">-->
<!--                    <el-input type="number" v-model="form.at_day"></el-input>-->
<!--                </el-form-item>-->
                <el-form-item label="话术内容">
                    <div ref="editors" id="editor"></div>
                </el-form-item>
<!--                <el-form-item label="目录节点" label-width="100px">-->
<!--                    <el-tree-->
<!--                            :data="dataList"-->
<!--                            show-checkbox-->
<!--                            default-expand-all-->
<!--                            node-key="id"-->
<!--                            ref="tree"-->
<!--                            highlight-current-->
<!--                            :props="defaultProps">-->
<!--                    </el-tree>-->
<!--                </el-form-item>-->
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="close">取 消</el-button>
                <el-button type="primary" @click="submits()">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

    // import axios from "axios";
    import E from "wangeditor";
    import {getTemplateItemList,getAllTemplateLists,submitCustomItem} from "../../src/utils/API/api";
    // import {upload} from "@/api/ContentManage";
    import { upload } from '../../src/utils/API/api'


    export default {
        name: 'memberManage',
        data() {
            return {
                mch_list: [],
                // 1：SOP标准说明 2：销售话术 3：回访话术 4：素材库 5：满意度调查 6：教学课程
                pay_setting_list: [{
                    id:1,
                    name:'SOP标准说明'
                },{
                    id:2,
                    name:'销售话术'
                },{
                    id:3,
                    name:'回访话术'
                }],

                query: {
                    name: '',
                    type: '',
                    store_id:'',
                    temp_id:''
                },
                page: 1,
                limit: 10,
                table_loading: false,
                list: [],
                count: 0,
                manageLoding: false,
                form: {},
                options: {
                    mch: {},
                    pay_setting: {},
                    open_plugin: {}
                },
                defaultProps: {
                    children: 'sub_list',
                    label: 'name',
                },
                editor: {},
                content: "",
                dataList:[],
                toShowList:[],
                halfCheckedKeys: [],
                checkedKeys: [],
                allChecked: [],
                // getStoreList:[]
            }
        },
        mounted() {
            // this.getInit()
            this.getList()
            this.formInit()
        },
        methods: {
            // 搜索
            search() {
                this.page = 1
                this.getList()
            },
            // 获取页面初始数据
            getInit() {
                axios.get('/mchMember/getMemberInit').then(response => {
                    let res = response.data
                    if (res.code === 0) {
                        this.mch_list = res.data.mch_list
                        // this.pay_setting_list = res.data.pay_setting_list
                    }
                })
            },
            // async getList() {
            //     this.loading = true;
            //     let res = await courseCateList(this.searchForm);
            //     if (res.data.code == 0) {
            //         this.list = res.data.data.list;
            //         this.total = res.data.data.dataCount;
            //         this.loading = false;
            //     }
            // },

            // 获取列表
             getList() {
                this.table_loading = true
               getTemplateItemList({
                    page: this.page,
                    limit:this.limit,
                    name: this.query.name,
                    type: this.query.type,
                    // store_id:this.query.store_id,
                    temp_id:this.query.temp_id[this.query.temp_id.length-1]
                }).then(res=>{
                   this.table_loading = false
                   if (res.data.code === 0) {
                       this.list = res.data.data

                       this.count = res.data.total
                       console.log(this.list,this.count,'this.list')
                   } else {
                       this.$message.error(res.message)
                   }
               })


                // axios.post('/mch/getTemplateItemList', {
                //     params: {
                //         page: this.page,
                //         limit:10,
                //         name: this.query.name,
                //         type: this.query.type,
                //         store_id:this.query.store_id,
                //         temp_id:this.query.temp_id[this.query.temp_id.length-1]
                //     }
                // }).then(response => {
                //     let res = response.data
                //     console.log(res,'res----------------------------')
                //     if (res.code === 0) {
                //         this.list = res.data
                //         this.count = res.total
                //     } else {
                //         this.$message.error(res.message)
                //     }
                // }).finally(() => {
                //     this.table_loading = false
                // })
            },
            // 分页改变事件
            handlePageChange(page) {
                this.page = page
                this.getList()
            },
            handleLimitChange(val) {
                this.limit = val;
                this.getList();
            },
            add() {
                let that = this;
                that.form={}
                // this.formInit();
                that.manageLoding = true
                that.$refs.tree.setCheckedKeys([])
            },
            edit(row){
                let that = this;
                this.form=row
                this.reShow()
                // that.$nextTick(() => {
                //   console.log(row.content)
                //   // that.NewEditor(row)
                // });
                // that.formInit();
                that.manageLoding = true
            },
            reShow() {
                // 所有数据的parentId数组
                let allParentId = []
                this.dataList.forEach(ele => allParentId.push(ele.id))
                //要勾选的节点id列表，数据应该来自后台
                // let checkedKeyArray = this.toShowList.split(',').map(ele => parseInt(ele))
                // console.log(checkedKeyArray,'checkedKeyArray')
                //只需要叶子节点的id
                let children = this.form.temp_ids.filter(menuId => ! allParentId.includes(menuId));
                console.log(children,'children')
                this.$nextTick(() => {
                    this.$refs.tree.setCheckedKeys(children)
                });

            },
            //创建富文本
            NewEditor() {
                this.editor = new E(this.$refs.editors)
                this.editor.config.height = 500
                this.editor.config.customUploadImg = function (resultFiles, insertImgFn) {
                    let formdata = new FormData()
                    formdata.append("file", resultFiles[0]);
                    // formdata.append ("module" ,'media_catalog');
                    // formdata.append ("type" ,'file');
                    upload(formdata).then(function (res) {
                        // 上传图片，返回结果，将图片插入到编辑器中
                        console.log(res,'将图片插入到编辑器中')
                        insertImgFn(res.data.data.url)
                    }).catch(function (err) {
                        // console.log(err)
                    })
                }
                this.editor.config.onchange = (html) => {
                    this.form.content = html
                }
                this.editor.create()
                this.editor.txt.html(this.form.content)
            },
            handleChange(value) {
                console.log(value,this.query.temp_id[this.query.temp_id.length-1],'----------------------');

            },
            editorClear() {
                this.$refs.editors.innerHTML = "";
                this.editor.destroy();
                this.editor = null;
            },
            formInit() {
                let that = this;
                getAllTemplateLists().then(res=>{
                    if (res.data.code === 0) {
                        that.dataList = res.data.data
                        console.log( that.dataList,' that.dataList')
                    }
                })
                // axios.post('/mch/getAllTemplateList').then(response => {
                //     let result = response.data;
                //     if (result.code === 0) {
                //         that.dataList = result.data
                //         console.log( that.dataList,' that.dataList')
                //     }
                // })

                return;
                // axios.post('/mch/getStoreList').then(response => {
                //     let result = response.data;
                //     if (result.code === 0) {
                //         that.getStoreList = result.data
                //         console.log( that.getStoreList,' that.dataList')
                //     }
                // })
                return
                axios.get('/mchMember/getMchLists').then(response => {
                    let result = response.data;
                    if (result.code === 0) {
                        that.options.mch = result.data
                    }
                })
                return;
                axios.get('/mchMember/getMchMemberLists').then(response => {
                    let result = response.data;
                    if (result.code === 0) {
                        that.options.pay_setting = result.data
                    }
                })
                axios.get('/mchMember/getMchPluginLists').then(response => {
                    let result = response.data;
                    if (result.code === 0) {
                        that.options.open_plugin = result.data
                    }
                })
            },
            submits() {
                // this.halfCheckedKeys = this.$refs.tree.getHalfCheckedKeys()
                // this.checkedKeys = this.$refs.tree.getCheckedKeys()
                // this.allChecked = this.halfCheckedKeys.concat(this.checkedKeys)
                // console.log(this.halfCheckedKeys ,this.checkedKeys,this.allChecked )
                // this.form.temp_ids=this.checkedKeys
                this.form.item_id=this.form.id
                submitCustomItem(this.form).then(res=>{
                    if (res.data.code === 0) {
                        this.manageLoding = false;
                        this.$message.success('编辑成功')
                        this.getList()
                    }
                })
                // return
                // axios.post('/mch/submitTemplateItem\n', this.form).then(response => {
                //     let result = response.data;
                //     if (result.code === 0) {
                //         this.manageLoding = false;
                //         this.$message.success('编辑成功')
                //         this.getList()
                //     }
                // })
            },

            close() {
                this.manageLoding = false;
            },
        }
    }
</script>
<style lang="scss" scoped>

    .login-container {
        // 盖掉那个 loading ...
        background-color: rgb(255, 255, 255);
        margin: 25px;
        width: 100%;
    }
</style>
