<template>
	<div>
	  <div class="customerTagBox">
		<div class="tag_box_title" @click="tapEditTag">
		  <div class="title">客户标签</div>
		  <div class="edit">
			<img src="../../src/static/image/edit.png"/>
		  </div>
		</div>
		<el-dialog append-to-body :visible.sync="showEdit" width="40%">
		  <div class="head_title">
			选择标签
			<img src="../../src/static/image/gou.png" @click="submitTag" v-if="!tagStatus" />
			<img src="../../src/static/image/gou.png" @click="notApiSubmit" v-if="tagStatus" />
		  </div>
		  <!-- <div class="tips">
			<span>想要自定义客户标签？</span>
			<div class="tip_btn" @click="tapEditPage">去修改</div>
		  </div> -->
		  <div class="content_box" style="width: 100%;">
			<el-tabs
			  style="width: 100%;"
			  v-model="current"
			  @tab-click="changeTageIndex"
			>
				<el-tab-pane :label="i.title" :name="i.name" v-for="(i,j) in title_list" :key="j">{{ i.title }}</el-tab-pane>
			</el-tabs>
		  </div>
		  <div class="content_search">
			<div class="search_box">
			  <img src="../../src/static/image/search.png" />
			  <input v-model="search_txt" @input="searchInput" class="input_box" />
			</div>
			<div class="search_btn" v-if="current == 'two'" @click="addTag">添加</div>
		  </div>
		  <div class="content_scoll">
			<div style="height: 50vh;margin-top: 15px; overflow: hidden;
    overflow-y: auto;" v-if="current == 'one'">
			  <div v-for="(item, index) in tag_list">
				<div class="scorll_title">
				  <div class="line"></div>
				  {{item.group_name}}
				</div>
				<div class="tag_cont">
				  <div
					class="tagBox"
					@click="tapTagBox(1, x)"
					:class="x.status?'act':''"
					v-for="(x, y) in item.tag_list" :key="y"
				  >{{x.tag_name}}</div>
				</div>
			  </div>
			</div>
			<div
			  style="height: 50vh;overflow: hidden;
    overflow-y: auto;"
			  v-if="current == 'two'"
			>
			  <div class="tag_cont">
				<div
				  class="tagBox"
				  @click="tapTagBox(2,item)"
				  :class="item.status?'act':''"
				  v-for="(item, index) in no_class_tag_list" :key="index"
				>{{item.tag_name}}</div>
			  </div>
			</div>
		  </div>
		</el-dialog>
		<div class="customerTagList">
		  <div
			class="tag_div"
			v-if="tag_list_data && tag_list_data.length>0"
			v-for="(item, index) in tag_list_data"
			:style="{backgroundColor: item.color}"
			:class="item.color=='#FFFFFF'?'tag_txt':''" :key="index"
		  >{{item.name || item.tag_name}}</div>
		  <div class="notData" v-if="!tag_list_data || tag_list_data.length == 0">暂无标签哦！</div>
		</div>
	  </div>
	</div>
  </template>
  
  <script>
  import { getCustomerTag, getTagGroupList,addTagInfo,getTagList,setCustomerTag  } from '../../src/utils/API/api'
  export default {
	name: 'tag_list',
	data() {
	  return {
		showEdit: false,
		colorData: '#FFFFFF',
		tag_list_data: [],
		add_tag_ids: [],
		del_tag_ids: [],
		search_txt: '',
		current: 'one',
		pageNo: 1,
		page: 1,
		tag_list: [],
		title_list: [
		  {
			title: '分组标签',
			name:'one'
		  },
		  {
			title:'未分组标签',
			name: 'two'
		  }
		],
		no_class_tag_list: []
	  }
	},
	props: {
	  tagStatus: {
		type: [Number, String]
	  },
	  detail_id: {
		type: [Number, String],
		default: null
	  }
	},
	mounted() {
	  this.page = 1
	  this.pageNo = 1
	  this.current = 'one'
	  this.getCustomerTagfun()
	  this.getTagList()
	  this.getNoClassTagList()
	},
	methods: {
	  searchInput(e) {
		this.page = 1
		this.pageNo = 1
		const self = this
		clearInterval(self.tiems)
		self.tiems = setTimeout(() => {
		  self.current == 'one' ? self.getTagList() : self.getNoClassTagList()
		}, 500)
	  },
	  tapEditTag() {
		this.showEdit = true
	  },
	  changeTageIndex(tab) {
		this.current = tab.name
		this.search_txt = ''
		this.page = 1
		this.pageNo = 1
		this.current == 'one' ? this.getTagList() : this.getNoClassTagList()
	  },
	  getCustomerTagfun() {
		let that = this
		let param = {
		  user_id: this.detail_id
		}
		getCustomerTag(param)
		  .then(response => {
			that.tag_list_data = response.data.data.info || []
			that.add_tag_ids = response.data.data.tag_ids || []
		  })
		  .catch(error => {
			this.$message.error('调用接口失败！')
		  })
	  },
	  changePageOne() {
		this.getTagList()
	  },
	  changePageTwo() {
		this.getNoClassTagList()
	  },
	  getTagList() {
		let that = this
		let param = {
		  page: this.page,
		  limit: 105,
		  is_tag_list: 1,
		  search: this.search_txt
		}
		getTagGroupList(param)
		  .then(response => {
			if (that.page == 1) {
			  that.tag_list = response.data.data
			} else {
			  that.tag_list = that.tag_list.concat(response.data.data)
			}
			that.tag_list.map(x => {
			  x.tag_list.map(m => {
				that.add_tag_ids.map(a => {
				  if (m.tag_id == a) {
					m.status = true
				  }
				})
			  })
			})
			this.tag_list = JSON.parse(JSON.stringify(this.tag_list))
			that.page++
		  })
		  .catch(error => {
			this.$message.error('调用接口失败！')
		  })
	  },
	  addTag() {
		let that = this
		let param = {
		  group_id: 0,
		  tag_name: this.search_txt,
		  color: '#FFFFFF'
		}
		addTagInfo(param)
		  .then(response => {
			let obj = {
			  tag_id: response.data.data.tag_id,
			  tag_name: this.search_txt,
			  color: '#FFFFFF',
			  status: true
			}
			this.no_class_tag_list.push(obj)
			that.add_tag_ids.push(response.data.data.tag_id)
		  })
		  .catch(error => {
			this.$message.error('调用接口失败！')
		  })
	  },
	  tapTagBox(type, item) {
		let that = this
		if (type == 1) {
		  // 点击分组中的标签
		  if (item.status) {
			that.tag_list.map(a => {
			  a.tag_list.map(n => {
				if (item.tag_id == n.tag_id) {
				  n.status = false
				  that.del_tag_ids.push(n.tag_id)
				  that.add_tag_ids.splice(
					that.add_tag_ids.findIndex(x => x == n.tag_id)
				  )
				}
			  })
			})
		  } else {
			that.tag_list.map(a => {
			  a.tag_list.map(n => {
				if (item.tag_id == n.tag_id) {
				  n.status = true
				  that.add_tag_ids.push(n.tag_id)
				}
			  })
			})
		  }
		  this.tag_list = JSON.parse(JSON.stringify(this.tag_list))
		} else if (type == 2) {
		  // 点击未分组标签
		  if (item.status) {
			that.no_class_tag_list.map(a => {
			  if (item.tag_id == a.tag_id) {
				a.status = false
				that.del_tag_ids.push(a.tag_id)
				that.add_tag_ids.splice(
				  that.add_tag_ids.findIndex(x => x == a.tag_id)
				)
			  }
			})
		  } else {
			that.no_class_tag_list.map(a => {
			  if (item.tag_id == a.tag_id) {
				console.log(a)
				a.status = true
				that.add_tag_ids.push(a.tag_id)
			  }
			})
		  }
		  this.no_class_tag_list = JSON.parse(
			JSON.stringify(this.no_class_tag_list)
		  )
		}
	  },
	//   tapEditPage() {
	// 	this.$message.error('暂不支持，请前往手机端添加！')
	//   },
	  getNoClassTagList() {
		let that = this
		let param = {
		  group_id: 0,
		  page: this.pageNo,
		  limit: 155,
		  search: this.search_txt
		}
		getTagList(param)
		  .then(res => {
			if (res.data.code == 0) {
			  if (that.pageNo == 1) {
				that.no_class_tag_list = res.data.data
			  } else {
				that.no_class_tag_list = that.no_class_tag_list.concat(
				  res.data.data
				)
			  }
			  that.no_class_tag_list.map(x => {
				that.add_tag_ids.map(a => {
				  if (x.tag_id == a) {
					x.status = true
				  }
				})
			  })
			  this.no_class_tag_list = JSON.parse(
				JSON.stringify(this.no_class_tag_list)
			  )
			  that.pageNo++
			}
		  })
		  .catch(error => {
			this.$message.error('调用接口失败！')
		  })
	  },
	  notApiSubmit() {
		let that = this
		let arr = []
		that.no_class_tag_list.map(x => {
		  if (x.status) {
			arr.push(x)
		  }
		})
		that.tag_list.map(a => {
		  a.tag_list.map(n => {
			if (n.status) {
			  arr.push(n)
			}
		  })
		})
		this.tag_list_data = arr
		this.$emit('changes', { a: this.add_tag_ids, b: this.del_tag_ids })
		this.showEdit = false
	  },
	  submitTag() {
		let that = this
		let param = {
		  user_id: this.detail_id,
		  add_tag_ids: this.add_tag_ids,
		  del_tag_ids: this.del_tag_ids
		}
		setCustomerTag(param)
		  .then(res => {
			this.showEdit = false
			this.getCustomerTagfun()
		  })
		  .catch(error => {
			this.$message.error('调用接口失败！')
		  })
	  }
	}
  }
  </script>
  
  <style lang="scss" scoped>
  .customerTagBox {
	margin-top: 15px;
  }
  .list_item {
	padding: 15px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	img {
	  height: 15px;
	  width: 15px;
	}
  }
  .content_search {
	display: flex;
	align-items: center;
	padding: 10px;
  }
  .tips {
	height: 40px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 15px;
	background-color: #fff5e7;
	span {
	  color: #b7905b;
	  font-weight: 600;
	}
	.tip_btn {
	  padding: 4px 12px;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  color: #b7905b;
	  font-size: 13px;
	  border-radius: 40px;
	  border: 1px solid #b7905b;
	}
  }
  .search_btn {
	width: 56px;
	height: 31px;
	background: #181518;
	border-radius: 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
	margin: 0 10px;
  }
  .search_box {
	flex: 1;
	background-color: #f2f6f9;
	padding: 5px 15px;
	display: flex;
	img {
	  height: 20px;
	  margin-right: 10px;
	  width: 20px;
	}
  }
  .input_box{
	  border: 0;
	  background: #f2f6f9;
	  width: 100%;
  }
  /deep/ .el-dialog__header {
  display: none;
  }
  /deep/ .el-dialog__body{
	  padding: 0 20px !important;
  }
  .content_scoll {
	.scorll_title {
	  height: 20px;
	  display: flex;
	  align-items: center;
	  font-size: 15px;
	  font-weight: 600;
	  padding: 0 15px;
	  .line {
		height: 14px;
		width: 4px;
		background-color: #5f5f5f;
		margin-right: 5px;
	  }
	}
  }
  .tag_cont {
	display: flex;
	flex-wrap: wrap;
	padding: 10px;
  }
  .content_box {
	display: flex;
	align-items: center;
	justify-content: center;
  }
  .customerTagList {
	display: flex;
	flex-wrap: wrap;
	background-color: #ffffff;
	padding: 10px 10px 0 10px;
	.notData {
	  width: 100%;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  color: #a8a8a8;
	  margin-bottom: 10px;
	}
  }
  .head_title {
	width: 100%;
	height: 50px;
	font-size: 17px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	img {
	  position: absolute;
	  right: 10px;
	  top: 15px;
	  height: 20px;
	  width: 20px;
	}
  }
  .tag_box_title {
	font-size: 14px;
	display: flex;
	justify-content: space-between;
	color: #666666;
	.title {
	  font-weight: 600;
	}
	.edit {
	  img {
		height: 15px;
		width: 15px;
	  }
	}
  }
  .tagBox {
	height: 28px;
	color: #000000;
	padding: 0 10px;
	display: flex;
	align-items: center;
	border-radius: 40px;
	justify-content: center;
	font-size: 13px;
	margin: 5px;
	border: 1px solid #525252;
  }
  .tag_div {
	height: 28px;
	color: #ffffff;
	padding: 0 10px;
	display: flex;
	align-items: center;
	border-radius: 40px;
	justify-content: center;
	font-size: 13px;
	margin: 5px;
  }
  .tag_txt {
	color: #b7905b !important;
	border: 1px solid #b7905b;
  }
  .act {
	color: #b7905b !important;
	border: 1px solid #b7905b;
	background-color: #fff1df;
  }
  </style>
  