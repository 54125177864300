var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "studyChapter",
      attrs: {
        "lock-scroll": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "addnewcate" },
                [
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.add_cate } },
                    [_vm._v("新增章节")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "padding-top": "15px" } },
                _vm._l(_vm.form.chapter, function (item, index) {
                  return _c(
                    "el-card",
                    {
                      key: index,
                      staticClass: "box-card",
                      attrs: { shadow: "never" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "clearfix chapterCateHeader",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          item.edit == false
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(item.title) +
                                    "\n              "
                                ),
                                _c("i", {
                                  staticClass: "el-icon-edit-outline",
                                  attrs: { size: "15" },
                                  on: {
                                    click: function ($event) {
                                      item.edit = true
                                    },
                                  },
                                }),
                              ])
                            : _c(
                                "span",
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "60%" },
                                      attrs: { placeholder: "请输入章节名称" },
                                      model: {
                                        value: item.title,
                                        callback: function ($$v) {
                                          _vm.$set(item, "title", $$v)
                                        },
                                        expression: "item.title",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { slot: "prepend" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeItem(index)
                                            },
                                          },
                                          slot: "prepend",
                                        },
                                        [_vm._v("删除")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          attrs: { slot: "append" },
                                          on: {
                                            click: function ($event) {
                                              item.edit = false
                                            },
                                          },
                                          slot: "append",
                                        },
                                        [_vm._v("保存")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                          _vm._v(" "),
                          _c(
                            "el-popover",
                            {
                              attrs: { width: "700", trigger: "click" },
                              model: {
                                value: item.isShow,
                                callback: function ($$v) {
                                  _vm.$set(item, "isShow", $$v)
                                },
                                expression: "item.isShow",
                              },
                            },
                            [
                              _c(
                                "el-row",
                                { attrs: { gutter: 20 } },
                                [
                                  _c(
                                    "el-col",
                                    { attrs: { span: 7 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-select",
                                            {
                                              staticStyle: { width: "100%" },
                                              attrs: {
                                                placeholder: "请选择类型",
                                                filterable: "",
                                                clearable: "",
                                              },
                                              model: {
                                                value: _vm.searchForm.goodtype,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.searchForm,
                                                    "goodtype",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "searchForm.goodtype",
                                              },
                                            },
                                            _vm._l(
                                              _vm.typeList,
                                              function (item, index) {
                                                return _c("el-option", {
                                                  key: index,
                                                  attrs: {
                                                    label: item.title,
                                                    value: item.id,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 7 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c("el-input", {
                                            attrs: {
                                              clearable: "",
                                              placeholder: "请输入课程名称",
                                            },
                                            model: {
                                              value: _vm.searchForm.search,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.searchForm,
                                                  "search",
                                                  $$v
                                                )
                                              },
                                              expression: "searchForm.search",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-col",
                                    { attrs: { span: 3 } },
                                    [
                                      _c(
                                        "el-form-item",
                                        {
                                          staticStyle: {
                                            "margin-bottom": "10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              attrs: { type: "primary" },
                                              on: {
                                                click: _vm.searchMallGoodsList,
                                              },
                                            },
                                            [_vm._v("搜索")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-table",
                                {
                                  staticStyle: {
                                    width: "100%",
                                    "min-height": "300px",
                                  },
                                  attrs: {
                                    data: _vm.mallGoodsList,
                                    "max-height": "300",
                                  },
                                  on: {
                                    "selection-change": _vm.selectionchange,
                                  },
                                },
                                [
                                  _c("el-table-column", {
                                    attrs: { type: "selection", width: "60" },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: {
                                      property: "goodtype_t",
                                      label: "类型",
                                      width: "60",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("el-table-column", {
                                    attrs: { property: "title", label: "名称" },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-col",
                                    [
                                      _c("el-pagination", {
                                        staticStyle: {
                                          padding: "10px 0px 0px 0px",
                                          overflow: "hidden",
                                        },
                                        attrs: {
                                          "current-page": _vm.searchForm.page,
                                          layout: _vm.layout,
                                          "page-size": _vm.searchForm.limit,
                                          total: _vm.total,
                                          background: "",
                                        },
                                        on: {
                                          "size-change": _vm.handleSizeChange,
                                          "current-change":
                                            _vm.handleCurrentChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-row",
                                {
                                  staticClass: "el-dialog__footer",
                                  staticStyle: {
                                    margin: "10px -10px -10px -10px",
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      staticStyle: { float: "right" },
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.syncSelectedList()
                                          item.isShow = false
                                        },
                                      },
                                    },
                                    [_vm._v("保存")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    float: "right",
                                    padding: "3px 0",
                                  },
                                  attrs: { slot: "reference", type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getmallGoodsList(index)
                                    },
                                  },
                                  slot: "reference",
                                },
                                [_vm._v("+ 选择课程")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "goods_list",
                          staticStyle: { margin: "-20px" },
                        },
                        [
                          _c(
                            "draggable",
                            {
                              attrs: { group: "goodslist" + item.id },
                              model: {
                                value: item.list,
                                callback: function ($$v) {
                                  _vm.$set(item, "list", $$v)
                                },
                                expression: "item.list",
                              },
                            },
                            [
                              _c(
                                "transition-group",
                                _vm._l(item.list, function (iitem, iindex) {
                                  return _c(
                                    "el-row",
                                    { key: iindex },
                                    [
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "tdc",
                                          attrs: { span: 2 },
                                        },
                                        [_vm._v(_vm._s(iindex + 1))]
                                      ),
                                      _vm._v(" "),
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _vm._v(_vm._s(iitem.title)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "tdc",
                                          attrs: { span: 6 },
                                        },
                                        [_vm._v("拖动排序")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticClass: "tdc",
                                          attrs: { span: 4 },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeChapterItem(
                                                    index,
                                                    iindex
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("移除")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }