var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "specialTrainEdit",
      attrs: {
        "lock-scroll": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
      },
      on: {
        opened: function ($event) {
          return _vm.editorInit()
        },
        closed: function ($event) {
          return _vm.editorClear()
        },
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "auto" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              !_vm.form.hasOwnProperty("id") || _vm.form.id == 0
                ? _c(
                    "el-col",
                    { attrs: { sm: 12, xs: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择商家" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "100%" },
                              attrs: {
                                placeholder: "请选择商家",
                                filterable: "",
                              },
                              model: {
                                value: _vm.form.mch_id,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "mch_id", $$v)
                                },
                                expression: "form.mch_id",
                              },
                            },
                            _vm._l(_vm.mch_list, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.name, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "训练营权限类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { clearable: "" },
                          model: {
                            value: _vm.form.rule_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "rule_type", $$v)
                            },
                            expression: "form.rule_type",
                          },
                        },
                        _vm._l(_vm.rule_list, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: { label: item.name, value: item.id },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开营名称", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请填写特训营名称" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间", prop: "start_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "left",
                          type: "datetime",
                          placeholder: "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          editable: false,
                        },
                        model: {
                          value: _vm.form.start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_time", $$v)
                          },
                          expression: "form.start_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间", prop: "url" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "left",
                          type: "datetime",
                          placeholder: "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          editable: false,
                        },
                        model: {
                          value: _vm.form.end_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "end_time", $$v)
                          },
                          expression: "form.end_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "任务模式", prop: "task_mode" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择任务模式",
                          },
                          model: {
                            value: _vm.form.task_mode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "task_mode", $$v)
                            },
                            expression: "form.task_mode",
                          },
                        },
                        _vm._l(_vm.taskModeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "评论模式", prop: "task_mode" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择评论模式",
                          },
                          model: {
                            value: _vm.form.comment_mode,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "comment_mode", $$v)
                            },
                            expression: "form.comment_mode",
                          },
                        },
                        _vm._l(_vm.commentModeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "频道插图", prop: "picture" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: "#",
                            "http-request": _vm.httpRequest1,
                            data: { type: "img" },
                            "show-file-list": false,
                            "before-upload": _vm.beforeStorePhotoUpload,
                          },
                        },
                        [
                          _c("el-button", { staticStyle: { float: "left" } }, [
                            _vm._v("上传"),
                          ]),
                          _vm._v(" "),
                          _c("span", { staticClass: "tips" }, [
                            _vm._v("请上传jpg/png文件，图片尺寸：375px*196px"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.form.cate_picture
                        ? _c("el-image", {
                            staticClass: "bannerImg",
                            attrs: {
                              src: _vm.form.cate_picture,
                              "z-index": 9999,
                              "preview-src-list": _vm.form.cate_picture_list,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "详情", prop: "sort" } },
                    [_c("div", { ref: "editor", attrs: { id: "editor" } })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }