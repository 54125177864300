<template>
  <div class="clockInTable">
    <div class="clock_head">
        <el-select v-model="shop_value" @change="shopChange" placeholder="请选择门店">
            <el-option
            v-for="item in shopOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        <el-select v-model="user_value" placeholder="请选择员工">
            <el-option
            v-for="item in userOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
            </el-option>
        </el-select>
        <el-date-picker
            v-model="date_value"
            type="daterange"
            value-format="yyyy-MM-dd"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
        </el-date-picker>
        <el-button type="primary" @click="searchFun">查询</el-button>
    </div>
    <el-dialog title="修改时间" :visible.sync="showChangeTimePop" width="30%">
        <div>
            <span>时间：{{changeType == 1?changeTimeData.show_s_time:changeTimeData.show_e_time}}</span>
            <div style="margin: 10px 0;">
                修改时间为：<el-time-picker v-model="selectTime" value-format="HH:mm:ss" placeholder="选择时间"></el-time-picker>
            </div>
            <div>
                <el-button type="primary" @click="confirmChange">确认</el-button>
            </div>
        </div>
    </el-dialog>
    <div class="clock_content" v-if="mchStoreList && mchStoreList.length>0">
        <div class="clock_table_item" v-for="(item,index) in mchStoreList" :key="index">
            <div class="clock_table_item_section">
                <div class="c_section">
                    <div class="c_s_head" style="width: 30px;" v-if="index == 0">序号</div>
                    <div class="c_s_box c_tr" style="width: 30px;">{{ index+1 }}</div>
                </div>
                <div class="c_section">
                    <div class="c_s_head" v-if="index == 0">日期</div>
                    <div class="c_s_box c_tr">
                        <div class="c_s_total" style="border-bottom: 1px solid #ddd;width: 60px;">班次</div>
                        <div class="c_s_time" style="border-left: 1px solid #ddd;width: 60px;">{{ item.user_name }}</div>
                        <div class="c_s_total" style="width: 60px;">合计(时)</div>
                    </div>
                </div>
                <div class="c_section" v-for="(n,m) in item.clock_list.list" :key="m">
                    <div class="c_s_head" v-if="index == 0">{{ n.date.substring(8,10) }}</div>
                    <div class="c_s_box c_tr">
                        <div class="c_s_total" style="border-bottom: 1px solid #ddd;">{{n.class_title}}</div>
                        <div class="c_s_time">
                            <div class="c_start" @click="changeTime(item, n, 1)">{{ n.show_s_time }}</div>
                            <div class="c_end" @click="changeTime(item, n, 2)">{{n.show_e_time}}</div>
                        </div>
                        <div class="c_s_total">{{ n.work_time>0?(n.work_time/60/60).toFixed(1):0 }}</div>
                    </div>
                </div>
            </div>
            <div style="display: flex;align-items: center;justify-content: center;height: 50px;">
                出勤：{{item.clock_list.days}}天，共{{item.clock_list.hours}},（迟到：{{item.clock_list.late_time}}分钟）（早退：{{item.clock_list.early_time}}分钟）（加班：{{item.clock_list.over_time}}分钟）
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import {getMchEmployeeList, getAttendanceList, myCustomerHomepage, editClockinRecord} from "../../src/utils/API/api";
export default {
    data() {
        return {
            shop_value: '',
            user_value: '',
            date_value: ['',''],
            shopOptions: [],
            userOptions: [],
            is_boss:1,
            mchStoreList:[],
            timeData:'',
            selectTime:'',
            changeTimeData:'',
            changeType:1,
            showChangeTimePop:false,
        }
    },
    mounted() {
        this.is_boss = localStorage.getItem('info').is_boss || 1
        this.myCustomerHomepageFun()
    },
    methods:{
        getMchEmployeeList(){
            getMchEmployeeList({mch_store_id:this.shop_value})
            .then(res => {
                let arr = res.data.data.map(item => {
                    return {
                        value: item.user_id,
                        label: item.user_name
                    }
                })
                this.userOptions = arr
            })
            .catch(error => {
                this.$message.error('调用接口失败！')
            })
        },
        shopChange(e){
            console.log(e)
            this.getMchEmployeeList()
        },
        confirmChange(){
            let dateTimes = this.changeTimeData.date+' '+this.selectTime
            let time = this.changeType == 1?time = this.changeTimeData.s_time:time = this.changeTimeData.e_time
            console.log(dateTimes, time)
            editClockinRecord({user_id:this.timeData.user_id,time:time,new_time:dateTimes})
            .then(res => {
                this.searchFun()
                this.showChangeTimePop = false
            })
            .catch(error => {
                this.$message.error('调用接口失败！')
            })
        },
        changeTime(data, e, type){
            this.changeType = type
            this.timeData = data
            this.changeTimeData = e
            this.showChangeTimePop = true
        },
        myCustomerHomepageFun() {
            let that = this
            myCustomerHomepage(this.is_boss)
            .then(res => {
            if (res.data.data.mchStore.length == 0) {
                that.$message.error('您还没有门店，请先添加门店！')
                return
                }
                that.shopOptions = res.data.data.mchStore.map(item => {
                    return {
                        value: item.id,
                        label: `${item.mch_name}(${item.store_name})`
                    }
                })
                that.shop_value = res.data.data.mchStore[0].id
                that.getMchEmployeeList()
                that.searchFun()
            })
            .catch(error => {
            this.$message.error('调用接口失败！')
            })
        },
        searchFun(){
            console.log(this.shop_value, this.date_value)
            getAttendanceList({mch_store_id:this.shop_value, user_id:this.user_value, s_date:this.date_value[0], e_date:this.date_value[1]})
            .then(res => {
                this.mchStoreList = res.data.data
                console.log(this.mchStoreList)
            })
            .catch(error => {
            this.$message.error(res.data.message)
            })
        },
    }
}
</script>

<style scoped>
.clockInTable{
    padding: 20px;
}
.clock_content{
    padding: 20px 0;
}
.clock_table_item{
    display: flex;
    flex-direction: column;
}
.clock_table_item_section{
    border: 1px solid #ddd;
    display: flex;
}
.c_s_head{
    border-bottom: 1px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background: #f3f3f3;
}
.c_tr{
    flex: 1;     
}
.c_s_box{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.c_s_time{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    width: 50px;
}
.c_start{
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ddd;
    justify-content: center;
}
.c_end{
    height: 40px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.c_section{
    display: flex;
    flex-direction: column;
}
.c_s_total{
    border-left: 1px solid #ddd;
    height: 30px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>