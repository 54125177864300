import Vue from 'vue'
import DemoApp from './DemoApp.vue'
import './index.css'
import axios from 'axios'
import SocketIO from "socket.io-client";
import VueSocketIO from 'vue-socket.io'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import store from './store';
import scrollView from '../components/scroll-view/index.vue'
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.component('scroll-view',scrollView)
// vue每次版本更新强刷页面
const version = require('../package.json').version
const versionStorage = localStorage.getItem('version')
console.log('最新版本', 'v' + version)
console.log('缓存版本', 'v' + versionStorage)

if (version != versionStorage) {
  localStorage.clear()
  localStorage.setItem('version', version)
  console.log('版本不一致，清除缓存中')
  //location.reload() 方法用来刷新当前页面。该方法只有一个参数，当值为 true 时，将强制浏览器从服务器加载页面资源，
  //当值为 false 或者未传参时，浏览器则可能从缓存中读取页面。
  setTimeout(() => {
    window.location.reload(true)
  }, 1000)
}

new Vue({
  render: h => h(DemoApp),
  store,
}).$mount(
  document.body.appendChild(document.createElement('div'))
)
