var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "demo-app", attrs: { id: "fc_div" } }, [
    _c(
      "div",
      { staticStyle: { display: "flex" } },
      [
        _c(
          "div",
          { staticStyle: { padding: "20px 0", display: "flex" } },
          [
            _c(
              "el-menu",
              {
                staticClass: "el-menu-vertical-demo",
                attrs: { collapse: _vm.isCollapse },
                on: { open: _vm.handleOpen, close: _vm.handleClose },
              },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      display: "flex",
                      "align-items": "center",
                      "justify-content": "center",
                      "margin-bottom": "20px",
                    },
                  },
                  [
                    _c("img", {
                      staticStyle: {
                        height: "30px",
                        width: "30px",
                        "border-radius": "10px",
                      },
                      attrs: { src: require("../src/static/image/logo.png") },
                    }),
                    _vm._v(" "),
                    _c("img", {
                      class: _vm.isCollapse ? "actMenu" : "",
                      staticStyle: {
                        background: "#ffffff",
                        "z-index": "10",
                        height: "16px",
                        width: "16px",
                        "border-radius": "20px",
                        position: "absolute",
                        top: "5px",
                        right: "-8px",
                      },
                      attrs: { src: require("./static/image/back.png") },
                      on: { click: _vm.tapmenu },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "el-menu-item",
                  {
                    attrs: { index: "1" },
                    on: {
                      click: function ($event) {
                        return _vm.tapMenuItem(1)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "el-icon-s-home" }),
                    _vm._v(" "),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v("预约管理"),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "2" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(2)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-menu" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("训练营管理")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "3" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(3)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-document" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("频道管理")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "4" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(4)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-setting" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("课程管理")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "5" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(5)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-printer" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("客户导入")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "6" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(6)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-collection" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("工作指南")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "7" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(7)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-date" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("排班设置")]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.is_boss == 1 ||
                _vm.is_boss == 2 ||
                _vm.is_boss == 4 ||
                _vm.is_boss == 5
                  ? _c(
                      "el-menu-item",
                      {
                        attrs: { index: "8" },
                        on: {
                          click: function ($event) {
                            return _vm.tapMenuItem(8)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "el-icon-files" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          { attrs: { slot: "title" }, slot: "title" },
                          [_vm._v("考勤查询")]
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.tabIndex == 1
          ? _c("taskCalendar", {
              on: {
                changeDataFun: _vm.changeDataFun,
                changeUrl: _vm.changeUrl,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 7 ? _c("Scheduling") : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 4 ? _c("Course") : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 3 ? _c("SchoolVue") : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 2 ? _c("specialTrain") : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 6 ? _c("instructions") : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 8 ? _c("clockInTable") : _vm._e(),
        _vm._v(" "),
        _vm.tabIndex == 5
          ? _c("div", { staticStyle: { height: "700px", width: "100%" } }, [
              _c("iframe", {
                staticStyle: { height: "100%", width: "100%" },
                attrs: { src: _vm.htmlUrl },
              }),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }