<template>
  <el-dialog
    @opened="editorInit()"
    @closed="editorClear()"
    :lock-scroll="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    class="specialTrainEdit"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-row :gutter="20">
        <el-col v-if="!form.hasOwnProperty('id') || form.id == 0" :sm="12" :xs="24">
          <el-form-item label="选择商家">
            <el-select v-model="form.mch_id" placeholder="请选择商家" filterable style="width: 100%">
              <el-option
                v-for="(item, index) in mch_list"
                :key="index"
                :label="item.name"
                :value="item.id">

              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24" >
          <el-form-item label="训练营权限类型">
            <!-- 简化下逻辑，该值编辑的时候不允许修改 -->
            <el-select v-model="form.rule_type" clearable >
              <el-option v-for="item in rule_list" :key ="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="开营名称" prop="title">
            <el-input v-model="form.title" placeholder="请填写特训营名称" />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="开始时间" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              align="left"
              type="datetime"
              placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="结束时间" prop="url">
            <el-date-picker
              v-model="form.end_time"
              align="left"
              type="datetime"
              placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="任务模式" prop="task_mode">
            <el-select
              v-model="form.task_mode"
              filterable
              placeholder="请选择任务模式"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in taskModeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :sm="12" :xs="24">
          <el-form-item label="评论模式" prop="task_mode">
            <el-select
              v-model="form.comment_mode"
              filterable
              placeholder="请选择评论模式"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in commentModeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              filterable
              placeholder="请选择状态"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="频道插图" prop="picture">
<!--            <el-upload-->
<!--              ref="imageUpload"-->
<!--              :action="crmFileSaveOSSUrl"-->
<!--              :headers="httpHeader"-->
<!--              :data="{type: 'img'}"-->
<!--              :before-remove="beforeRemove"-->
<!--              :on-success="PhotoUploadSuccess"-->
<!--              :before-upload="beforeStorePhotoUpload"-->
<!--              :show-file-list="showFileList"-->
<!--              name="file"-->
<!--              accept="image/*">-->
<!--              <el-button style="float:left;">上传</el-button>-->
<!--              <span class="tips">请上传jpg/png文件，图片尺寸：375px*196px</span>-->
<!--            </el-upload>-->
            <el-upload
              class="avatar-uploader"
              action="#"
              :http-request="httpRequest1"
              :data="{type: 'img'}"
              :show-file-list="false"
              :before-upload="beforeStorePhotoUpload"
            >
              <el-button style="float:left;">上传</el-button>
              <span class="tips">请上传jpg/png文件，图片尺寸：375px*196px</span>
            </el-upload>
            <el-image
              v-if="form.cate_picture"
              class="bannerImg"
              :src="form.cate_picture"
              :z-index="9999"
              :preview-src-list="form.cate_picture_list">
            </el-image>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="简介" prop="sort">
            <el-input v-model="form.description" />
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="详情" prop="sort">
            <div ref="editor" id="editor"></div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import axios from "axios";
import E from 'wangeditor'
import {upload,specialTrainingSave} from "../../src/utils/API/api";

export default {
  name: 'specialTrainEdit',
  components: {
  },
  data() {
    return {
      showFileList:false,
      form: {
        mch_id: 0,
        tags:{},
        cate_picture:'',
        price:"0.00",
        scope: {
          setting: [],
          input_tips: '默认全公司',
          value: {
            users: [],
            strucs: []
          }
        }
      },
      editor: {},
      pricetypeList:[
        {id:0,title:'一次性收费'},
        {id:1,title:'按年收费'},
      ],
      sectionList:[
        {value:1,label:'内部'},
        {value:2,label:'外部'},
      ],
      statusList:[
        {label:"有效", value:1},
        {label:"失效", value:0},
      ],
      commentModeList:[
        {label:"评论进阶", value:1},
        {label:"无需评论", value:2},
      ],
      taskModeList:[
        {label:"自由", value:1},
        {label:"解锁", value:2},
      ],
      userstatusList:[
        {label:"激活", value:1},
        {label:"试用期", value:3},
      ],
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入名称' }],
      },
      title: '',
      dialogFormVisible: false,
      upload_parmas: {
        authKey: '123',
      },
      BASE_URL:'',
      crmFileSaveOSSUrl:null,
      mch_list: [],
      rule_list: [],
    }
  },
  created() {
    this.crmFileSaveOSSUrl = window.BASE_URL + 'admin/AliOss/upload'

    // // 获取商家列表
    // getInitData({}).then(response => {
    //   let res = response.data
    //   if (res.code === 0) {
    //     this.mch_list = res.data.mch_list
    //     this.rule_list = res.data.rule_list
    //   }
    // })
  },
  computed: {
    crmFileSaveUrl() {
      return crmFileSaveUrl
    },
    httpHeader() {
      return {
        'authKey': axios.defaults.headers['authKey'],
        'sessionId': axios.defaults.headers['sessionId']
      }
    },
  },
  mounted() {

  },
  methods: {
    httpRequest1(obj) {
      let formdata = new FormData();
      formdata.append("file", obj.file);
      formdata.append("name", obj.file.name);
      let loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      upload(formdata)
        .then(res => {
          this.form.cate_picture = res.data.url
          console.log(res,1111111)
          this.form.cate_picture_list = [res.data.url]
          this.showFileList = false
        }).finally(() => {
          loading.close()
      })
    },
    showEdit(row) {
      if (!row.id) {
        this.title = '添加特训营'
        this.redirectUrlList = [];
      } else {
        this.title = '编辑特训营'
        this.form = Object.assign({}, row)
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      var that = this;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          await specialTrainingSave( this.form).then(response => {
            let res = response.data
            if (res.code !== 0) {
              this.$message.error(res.message)
            } else {
              this.$message.success(res.data)
              that.$emit('fetch-data')
              that.close()
            }
          }).catch(() => {
          })
        } else {
          return false
        }
      })
    },
    scopeChange(data){
      this.form.scope = data
    },
    PhotoUploadSuccess(res, file) {
      this.form.cate_picture = res.data.url
      this.form.cate_picture_list = [res.data.url]
      this.showFileList = false
    },
    beforeStorePhotoUpload(file) {
      this.showFileList = true;
    },
    beforeRemove(file, fileList) {

    },
    editorInit(){
      var that = this;
      that.editor = new E(that.$refs.editor)
      // let OSS = require('ali-oss');
      // let client = new OSS({
      //   // // region以杭州为例（oss-cn-hangzhou），其他region按实际情况填写。
      //   region: 'oss-cn-hangzhou',
      //   // // 阿里云主账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM账号进行API访问或日常运维，请登录RAM控制台创建RAM账号。
      //   accessKeyId: '0sZZNlk3zGv3CMj0',
      //   accessKeySecret: 'vqMNl6yq3vR9ICjgz9gJtjokvnFbzx',
      //   bucket: 'meiya',
      // });

      that.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        // resultFiles 是 input 中选中的文件列表
        // insertImgFn 是获取图片 url 后，插入到编辑器的方法
        var formdata=new FormData()
        formdata.append ("file" , resultFiles[0]);
        let loading = this.$loading({
          lock: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        upload(formdata).then(function (res) {
          // 上传图片，返回结果，将图片插入到编辑器中
          console.log(res.data)
          insertImgFn(res.data.data.url)
        }).catch(function (err) {
          // console.log(err)
        }).finally(() => {
          loading.close()
        })
        // }
      }
      that.editor.config.onchange = (html) => {
        that.form.showdetail = html
      }
      that.editor.create()
      that.editor.txt.html(that.form.showdetail)
    },
    editorClear(){
      this.$refs.editor.innerHTML = ''
      this.editor.destroy()
      this.editor = null
    },
    /**
     * 返回选中的标签数组
     */
    lableChange(event) {
      this.form.tags = event
      this.$forceUpdate()
    },
  },
}
</script>

<style lang="scss" scoped>
.specialTrainEdit {
  ::v-deep {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }
    .bannerImg{
      max-height: 120px;
      max-width: 100%;
      border-radius: 3px;
      height: 196px;
      width: 375px;
      overflow: hidden;
    }
    .avatar-uploader-icon{
      min-width: 80px;
      min-height: 80px;
      border: 1px solid #efefef;
      line-height: 80px;
      border-radius: 3px;
    }
    .el-tag {
      margin-right: 10px;
    }
    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-right: 10px;
      vertical-align: bottom;
    }
    .tips{
      margin-left: 20px;
      color: #928e8e;
    }
  }
}
</style>
