var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { my_scroll_container: true },
      style:
        (_vm.scrollX === "true" || _vm.scrollX === true
          ? "overflow-x:scroll;overflow-y:hidden"
          : "") +
        ";" +
        (_vm.scrollY === "true" || _vm.scrollY === true
          ? "overflow-x:hidden;overflow-y:scroll"
          : "") +
        ";",
      on: { scroll: _vm.getScroll },
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }