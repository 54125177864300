var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content_manage" },
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "white" } },
            [
              _c(
                "div",
                { staticClass: "search" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-select",
                            {
                              attrs: {
                                placeholder: "请选择类型",
                                filterable: "",
                                clearable: "",
                              },
                              model: {
                                value: _vm.searchForm.goodtype,
                                callback: function ($$v) {
                                  _vm.$set(_vm.searchForm, "goodtype", $$v)
                                },
                                expression: "searchForm.goodtype",
                              },
                            },
                            _vm._l(_vm.typeList, function (item, index) {
                              return _c("el-option", {
                                key: index,
                                attrs: { label: item.title, value: item.id },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.searchForm.query_mch_id === 0
                        ? _c(
                            "el-form-item",
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.searchForm.rule_type,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.searchForm, "rule_type", $$v)
                                    },
                                    expression: "searchForm.rule_type",
                                  },
                                },
                                _vm._l(_vm.rule_list, function (item) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入课程标题",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "search", $$v)
                              },
                              expression: "searchForm.search",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            { staticClass: "btn1", on: { click: _vm.getList } },
                            [_vm._v("查找")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { float: "right" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.Edit({}, 1)
                                },
                              },
                            },
                            [_vm._v("新增图文")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "warning" },
                              on: {
                                click: function ($event) {
                                  return _vm.Edit({}, 3)
                                },
                              },
                            },
                            [_vm._v("新增视频")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "element-loading-text": "加载中...",
                    "element-loading-spinner": "el-icon-loading",
                    data: _vm.list,
                    "row-style": { height: "81px" },
                    "cell-style": { textAlign: "center" },
                    "header-cell-style": {
                      color: "#333",
                      fontSize: "14px",
                      backgroundColor: "#F6F7FB",
                      fontWeight: "normal",
                      textAlign: "center",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "mch_name", label: "店铺名" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "rule_type_txt", label: "课程权限类型" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "课程名称",
                      "min-width": "300",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "goodtype_t",
                      label: "类型",
                      align: "center",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "views",
                      label: "点阅数",
                      align: "center",
                      "min-width": "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", { staticClass: "operation" }, [
                              _vm._v(
                                "\n             " +
                                  _vm._s(
                                    scope.row.views > 9999
                                      ? Math.floor(scope.row.views / 1000) /
                                          10 +
                                          "w"
                                      : scope.row.views
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "is_comment_t",
                      label: "评论",
                      align: "center",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "is_recommend_t",
                      label: "推荐",
                      align: "center",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      align: "center",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status_t",
                      label: "状态",
                      align: "center",
                      "min-width": "80",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "name",
                      width: "120",
                      label: "操作",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "operation" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text_btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Edit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text_btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Delete(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.searchForm.page,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.searchForm.limit,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleLimitChange,
                      "current-change": _vm.handlePageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-dialog",
            {
              staticClass: "studyedit",
              attrs: {
                "lock-scroll": false,
                title: _vm.title,
                visible: _vm.advEditVisible,
                "close-on-click-modal": false,
              },
              on: {
                opened: function ($event) {
                  return _vm.editorInit()
                },
                closed: function ($event) {
                  return _vm.editorClear()
                },
                "update:visible": function ($event) {
                  _vm.advEditVisible = $event
                },
                close: function ($event) {
                  return _vm.closeDialog("courseEditForm")
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "courseEditForm",
                  attrs: { model: _vm.courseEditForm, "label-width": "120px" },
                },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程权限类型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { clearable: "" },
                                  model: {
                                    value: _vm.courseEditForm.rule_type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.courseEditForm,
                                        "rule_type",
                                        $$v
                                      )
                                    },
                                    expression: "courseEditForm.rule_type",
                                  },
                                },
                                [
                                  _c("el-option", {
                                    key: "1",
                                    attrs: { label: "全员", value: 1 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "2",
                                    attrs: { label: "老板", value: 2 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "3",
                                    attrs: { label: "干部", value: 3 },
                                  }),
                                  _vm._v(" "),
                                  _c("el-option", {
                                    key: "4",
                                    attrs: { label: "指定人员", value: 4 },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.courseEditForm.rule_type == 4
                        ? _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "选择成员" } },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { type: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.addUser = true
                                        },
                                      },
                                    },
                                    [_vm._v("+添加成员")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "btnBox" },
                                    _vm._l(_vm.showUserArr, function (i, j) {
                                      return _c(
                                        "div",
                                        { key: j, staticClass: "btns" },
                                        [
                                          _c("img", {
                                            staticStyle: {
                                              top: "-5px",
                                              right: "-5px",
                                              position: "absolute",
                                            },
                                            attrs: {
                                              src: require("../../src/static/image/del.png"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delUser(i)
                                              },
                                            },
                                          }),
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(i.label) +
                                              "\n                    "
                                          ),
                                        ]
                                      )
                                    }),
                                    0
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-dialog",
                        {
                          attrs: {
                            title: "选择成员",
                            "append-to-body": "",
                            visible: _vm.addUser,
                          },
                          on: {
                            "update:visible": function ($event) {
                              _vm.addUser = $event
                            },
                          },
                        },
                        [
                          _vm.addUser
                            ? _c("el-tree", {
                                ref: "tree",
                                attrs: {
                                  props: _vm.defaultProps,
                                  load: _vm.loadNode,
                                  lazy: "",
                                  "show-checkbox": "",
                                  "check-strictly": true,
                                  "default-expanded-keys": [1],
                                  "node-key": "value",
                                  "default-checked-keys":
                                    _vm.courseEditForm.user_ids,
                                },
                                on: { "check-change": _vm.handleCheckChange },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "dialog-footer",
                              attrs: { slot: "footer" },
                              slot: "footer",
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  on: {
                                    click: function ($event) {
                                      _vm.addUser = false
                                    },
                                  },
                                },
                                [_vm._v("取 消")]
                              ),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: { click: _vm.confirmAdd },
                                },
                                [_vm._v("确 定")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.courseEditForm.rule_type == 2
                        ? _c(
                            "el-col",
                            [
                              _c(
                                "el-form-item",
                                { attrs: { label: "是否允许试看" } },
                                [
                                  _c(
                                    "el-radio-group",
                                    {
                                      model: {
                                        value:
                                          _vm.courseEditForm.is_try_and_see,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.courseEditForm,
                                            "is_try_and_see",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "courseEditForm.is_try_and_see",
                                      },
                                    },
                                    [
                                      _c("el-radio", { attrs: { label: 0 } }, [
                                        _vm._v("关"),
                                      ]),
                                      _vm._v(" "),
                                      _c("el-radio", { attrs: { label: 1 } }, [
                                        _vm._v("开"),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.courseEditForm.goodtype != 1 &&
                                  _vm.courseEditForm.is_try_and_see == 1
                                    ? _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "30px",
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v("音频/视频试看时长："),
                                          ]),
                                          _vm._v(" "),
                                          _c("el-input", {
                                            staticStyle: { width: "150px" },
                                            attrs: {
                                              placeholder:
                                                "请输入音频/视频试看时长",
                                            },
                                            model: {
                                              value:
                                                _vm.courseEditForm
                                                  .try_and_see_time,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.courseEditForm,
                                                  "try_and_see_time",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "courseEditForm.try_and_see_time",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程名称", prop: "title" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写课程名称" },
                                model: {
                                  value: _vm.courseEditForm.title,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.courseEditForm, "title", $$v)
                                  },
                                  expression: "courseEditForm.title",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, xs: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "点阅数", prop: "views" } },
                            [
                              _c("el-input", {
                                attrs: { placeholder: "请填写点阅数" },
                                model: {
                                  value: _vm.courseEditForm.views,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.courseEditForm, "views", $$v)
                                  },
                                  expression: "courseEditForm.views",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, xs: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "评论开关", prop: "is_comment" },
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择是否允许评论",
                                  },
                                  model: {
                                    value: _vm.courseEditForm.is_comment,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.courseEditForm,
                                        "is_comment",
                                        $$v
                                      )
                                    },
                                    expression: "courseEditForm.is_comment",
                                  },
                                },
                                _vm._l(_vm.commentList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { sm: 24, xs: 48 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "状态", prop: "status" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    filterable: "",
                                    placeholder: "请选择状态",
                                  },
                                  model: {
                                    value: _vm.courseEditForm.status,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.courseEditForm,
                                        "status",
                                        $$v
                                      )
                                    },
                                    expression: "courseEditForm.status",
                                  },
                                },
                                _vm._l(_vm.statusList, function (item, index) {
                                  return _c("el-option", {
                                    key: index,
                                    attrs: {
                                      label: item.label,
                                      value: item.value,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.courseEditForm.goodtype == 1
                        ? _c(
                            "el-col",
                            { attrs: { sm: 24, xs: 48 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "附件上传",
                                    prop: "allow_download",
                                  },
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload-demo",
                                      attrs: {
                                        action: "#",
                                        "http-request": _vm.httpRequest2,
                                        data: _vm.uploadData5,
                                        name: "file",
                                        "on-success": _vm.reqUploadSuccess6,
                                        "on-remove": _vm.handleRemove,
                                        "show-file-list": false,
                                        "before-upload": _vm.beforeFileUpload,
                                      },
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary",
                                          },
                                        },
                                        [_vm._v("点击上传")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.form7.file_id
                                    ? _c("div", { staticClass: "upfile_box" }, [
                                        _c("div", { staticClass: "file_box" }, [
                                          _c("img", {
                                            staticClass: "img",
                                            attrs: {
                                              src: require("../../src/static/image/other.png"),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("img", {
                                            staticClass: "del",
                                            attrs: {
                                              src: require("../../src/static/image/del.png"),
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.delFile()
                                              },
                                            },
                                          }),
                                        ]),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.courseEditForm.goodtype == 1
                        ? _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "附件下载",
                                    prop: "allow_download",
                                  },
                                },
                                [
                                  _c("el-switch", {
                                    attrs: {
                                      "active-value": 1,
                                      "inactive-value": 0,
                                      "active-text": "允许",
                                      "inactive-text": "不允许",
                                    },
                                    model: {
                                      value: _vm.courseEditForm.allow_download,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.courseEditForm,
                                          "allow_download",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "courseEditForm.allow_download",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { sm: 12, xs: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "推荐", prop: "is_recommend" } },
                            [
                              _c("el-switch", {
                                attrs: {
                                  "active-value": 1,
                                  "inactive-value": 0,
                                  "active-text": "是",
                                  "inactive-text": "否",
                                },
                                model: {
                                  value: _vm.courseEditForm.is_recommend,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.courseEditForm,
                                      "is_recommend",
                                      $$v
                                    )
                                  },
                                  expression: "courseEditForm.is_recommend",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.courseEditForm.goodtype != 1
                        ? _c(
                            "el-col",
                            { attrs: { sm: 12, xs: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: { label: "时长", prop: "mediaminute" },
                                },
                                [
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "45%" },
                                      attrs: {
                                        placeholder: "分钟数",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.courseEditForm.mediaminute,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.courseEditForm,
                                            "mediaminute",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "courseEditForm.mediaminute",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("分"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "45%" },
                                      attrs: {
                                        placeholder: "秒数",
                                        size: "small",
                                      },
                                      model: {
                                        value: _vm.courseEditForm.mediasecond,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.courseEditForm,
                                            "mediasecond",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "courseEditForm.mediasecond",
                                      },
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _vm._v("秒"),
                                      ]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "课程插图", prop: "pictures" } },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: _vm.hideUpload
                                    ? "hide avatar-uploader"
                                    : "avatar-uploader",
                                  attrs: {
                                    action: "#",
                                    "http-request": _vm.httpRequest1,
                                    data: _vm.uploadData,
                                    "show-file-list": false,
                                    "on-change": _vm.handleUploadChange,
                                    "on-remove": _vm.handleUploadRemove,
                                  },
                                },
                                [
                                  _vm.courseEditForm.pictures
                                    ? _c("img", {
                                        staticClass: "avatar",
                                        attrs: {
                                          src: _vm.courseEditForm.pictures,
                                        },
                                      })
                                    : _c("i", {
                                        staticClass:
                                          "el-icon-plus avatar-uploader-icon",
                                      }),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip",
                                    },
                                    [
                                      _vm._v(
                                        "（图片大小不超过2M，支持JPG、JPEG及PNG格式）"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.courseEditForm.goods_type_name,
                                prop: "url",
                              },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: _vm.hideUpload
                                    ? "hide avatar-uploader"
                                    : "avatar-uploader",
                                  attrs: {
                                    action: "#",
                                    "http-request": _vm.httpRequest11,
                                    data: _vm.uploadData,
                                    "show-file-list": false,
                                    "on-change": _vm.officehandleUploadChange,
                                    "on-remove": _vm.handleUploadRemove,
                                  },
                                },
                                [
                                  _c(
                                    "el-button",
                                    { staticStyle: { float: "left" } },
                                    [_vm._v("上传office/pdf")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.courseEditForm.url,
                                    target: "_blank",
                                  },
                                },
                                [_vm._v(_vm._s(_vm.courseEditForm.url))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "简介", prop: "description" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.courseEditForm.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.courseEditForm,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "courseEditForm.description",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "详情", prop: "showdetail" } },
                            [
                              _c("div", {
                                ref: "editor",
                                attrs: { id: "editor" },
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.closeDialog("courseEditForm")
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.Save } },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }