<template>
    <div class="demo-app" id="fc_div">
      <div class="qcodeDiv" v-if="codeImage">
      <div class="pop"></div>
      <div class="imgDiv">
        <div class="codeView">请使用"掌管店"APP扫码登录</div>
        <el-image class="codeImg" :src="codeImage"></el-image>
      </div>
    </div>
      <div style="display: flex;">
        <div class="demo-app-main" v-if="tabIndex==1">
          <!-- 切换门店 -->
          <el-dialog :visible.sync="showSelectShop" append-to-body width="40%" title="切换门店">
            <div style="display:flex;align-items:center;justify-content:center; height:150px;margin-bottom:40px">
              <el-select v-model="shopValue" placeholder="请选择" @change="optionChange">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  >
                </el-option>
              </el-select>
            </div>
          </el-dialog>
          <!--  -->
          <el-dialog :visible.sync="showAddPop" width="40%" title="预约信息">
            <!-- 编辑服务弹框 -->
            <el-dialog append-to-body :visible.sync="showEditTagPop" width="40%" title="编辑服务">
              <el-dialog :visible.sync="showSelectServicePop"  append-to-body width="40%" >
                    <div class="edit_title_box">新建服务</div>
                    <div class="inputBox" style="padding: 20px 30px 30px 30px;display: flex;justify-content: center;">
                        <input class="input_txt"
                            style="padding: 0 10px; height: 40px; border: 1px solid #cbcbcb; border-radius: 4px;"
                            v-model="serviceTxt" placeholder="请输入服务名称"/>
                    </div>
                    <div class="input_confirm" style="display: flex; align-items: center; justify-content: center;">
                        <div class="btn"
                            style="height: 45px; width: 100px; background-color: var(--main-color); color: #fff;border-radius: 8px; display: flex; justify-content: center;align-items: center;"
                            @click="confirmServiceTag">确认</div>
                    </div>
            </el-dialog>
            <div class="edit_service" >
              <div class="edit_tag" style="margin: 10px;">已选服务</div>
              <div class="service_input" style="min-height: 50px;">
                <div class="service_item" style="background-color: var(--main-color); color: #ffffff;"
                  v-for="(a, b) in serviceData">
                  {{a.product_name}}
                  <img src="../../src/static/image/close.png" @click="closeService(a)"/>
                </div>
              </div>
              <div class="edit_time_data"
                style="justify-content: flex-start; display: flex;flex-direction: column;margin-bottom: 20px;font-size: 18px;font-weight: 600;">
                <div class="edit_tag" style="margin: 10px;font-size: 14px;">全部服务</div>
                <div class="edit_service_tag" style="margin-bottom: 20px;"
                  v-if="this.serviceList && this.serviceList.length>0">
                  <div class="service_item" :class="x.status?'act':''" v-for="(x, y) in this.serviceList"
                    @click="tapServiceTag(x)">
                    {{x.product_name}}
                    <!-- <image src="../../../static/img/close-bold.png" @click="closeService(x)"></image> -->
                  </div>
                </div>
                <div class="addService" @click="selectService">+<span style="margin-left: 5px;">新建服务</span>
                </div>
              </div>
            </div>
            <div class="input_confirm" style="display: flex; align-items: center; justify-content: center;">
              <div class="btn"
                style="height: 45px; width: 100px; background-color: var(--main-color); color: #fff;border-radius: 8px; display: flex; justify-content: center;align-items: center;"
                @click="confirmEditTag">确认</div>
            </div>
          </el-dialog>
          <!--  编辑服务弹框 end -->
          <!-- 搜索客户 -->
          <el-dialog :visible.sync="showSearch" append-to-body width="40%" title="预约信息">
            <div class="user_list" id="scroll_list">
              <div class="user_search_input">
                <input v-model="search_value" placeholder="请输入顾客名" @confirm="searchFun"/>
                <div class="searchBtn" @click="searchFun">搜索</div>
              </div>
              <div class="user_list_item" v-for="(item, index) in customer_list" :key="index" @click="selectConsumer(item)">
                <div class="name">姓名：{{item.user_name}}</div>
                <div class="phone" v-if="item.mobile">手机：{{item.mobile.substr(0,4)+"****"+item.mobile.substr(8,11)}}</div>
              </div>
            </div>
          </el-dialog>
          <!--  -->
          <el-dialog :visible.sync="userPop" append-to-body width="40%" title="系统检测到相似客户">
            <div class="card_cont">
              <div class="tip" style="font-size: 15px;">
                <div class="txt">客户名字：{{cu_name}}</div>
                <div class="txt">手机：{{user_phone}}</div>
                <div class="txt" style="margin-top: 20px; font-weight: 600; font-size: 15px;">是否要启用该档案?</div>
              </div>
              <div class="card_foot">
                <div class="card_cancle foot_btn" style="font-weight: 600; color: #999;"
                  @click="userPop = false">取消</div>
                <div class="card_confirm foot_btn" style="font-weight: 600;" @click="submitCard(newData)">确认
                </div>
              </div>
            </div>
          </el-dialog>
          <!--  -->
          <!--  -->
            <div class="editData" @click="closeCusPop">
              <div class="edit_section"
                style="margin-bottom: 10px; display: flex; align-items: center;justify-content: space-between;flex-direction: row;">
                <div class="head_time">日期：
                  <el-date-picker
                    v-model="popDate"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                    value-format="yyyy-MM-dd"
                    @change="changeTime">
                  </el-date-picker>
                </div>
              </div>
              <!-- <view class="user_cont_view"> -->
              <div class="edit_section" style="align-items: center;">
                <div class="edit_title"
                  style="display: flex; align-items: center; justify-content: space-between;">
                  <span style="font-weight: 600;">技师</span><span style="color: var(--main-color);font-weight: 600;"
                    @click="addUser">+添加技师</span>
                </div>
                <div class="edit_time_data" style="justify-content: flex-start; flex-wrap: wrap;">
                  <div class="edit_user_data"
                    style="border: 1px solid var(--main-color); border-radius: 6px; padding: 20px;margin-bottom: 10px;"
                    v-for="(item, index) in service_arr"
                    v-if="cardData.service_arr && cardData.service_arr.length>0" v-bind:key="index">
                    <div class="edit_time">
                      <div class="edit_time_data" style="flex: 1;">
                        <el-time-select
                        v-model="item.s_time"
                        class="stime"
                        :picker-options="{
                          start: '08:00',
                          step: '00:15',
                          end: '22:00'
                        }"
                        @change='dateConfirm($event, item, index, 1)'
                        placeholder="选择时间">
                      </el-time-select>
                        <div class="line">-</div>
                        <el-time-select
                        v-model="item.e_time"
                        class="etime"
                        :picker-options="{
                          start: '08:00',
                          step: '00:15',
                          end: '22:00'
                        }"
                        @change='dateConfirm($event, item, index, 2)'
                        placeholder="选择时间">
                      </el-time-select>
                      </div>
                      <el-select class="userview" style="border: 0px;" v-model="item.name" value-key="id" @change="userConfirm($event, item, index)">
                        <el-option
                          v-for="item in user_list"
                          :key="item.id"
                          :label="item.title"
                          :value="item.id">
                        </el-option>
                      </el-select>
                    </div>
                    <div class="edit_service">
                      <div class="edit_tag" style="font-weight: 600;">已加服务</div>
                      <div class="edit_time_data" style="justify-content: flex-start;">
                        <div class="edit_service_tag"
                          v-if="item.product_arr && item.product_arr.length>0">
                          <div class="service_item" :class="x.status?'act':''"
                            v-for="(x, y) in item.product_arr" :key="y">
                            {{x.product_name}}
                            <img src="../../src/static/image/close-bold.png" @click="closeService(x)"></img>
                          </div>
                        </div>
                        <div class="addService" @click="editService(index, item)">+<span
                            style="margin-left: 5px;font-weight: 600;">编辑服务</span>
                        </div>
                      </div>
                      <!-- 			<view class="service_input" v-if="item.product_arr&& item.product_arr.length>0">
                          <view class="service_item"  style="background-color: var(--main-color); color: #ffffff;" v-for="(a, b) in item.product_arr">
                            {{a.product_name}}
  
                          </view>
                        </view> -->
                    </div>
                    <img src="../../src/static/image/close1.png" @click.stop="delFun(item)"/>
                  </div>
                </div>
                <!-- <view class="addUser">+<span style="font-size: 14px; font-weight: 400; margin-left: 10px;">添加技师</span></view> -->
              </div>
              <!-- </view> -->
              <!-- <view class="user_cont_view"> -->
              <div class="edit_section">
                <div class="edit_title">客户</div>
                <div class="edit_time_data" style="justify-content: flex-start;">
                  <div class="search_user" @click="toSearch">
                    搜索
                  </div>
                </div>
              </div>
              <div class="edit_section">
                <div class="edit_title">姓名</div>
                <div class="edit_time_data" style="justify-content: flex-start; align-items: center;">
                  <input class="userview" v-model="userName" @input="inputIng" :disabled="selectConsumerStatus"
                    :class="selectConsumerStatus?'active':''" />
                  <div class="seachUserListPop" v-if="customer_arr && customer_arr.length>0 && userName && showCusPop">
                    <div style="height: 135px;">
                      <div>
                        <div class="useritem" @click="tapUserData(n)" v-for="(n,m) in customer_arr" v-bind:key="m">
                          {{n.user_name}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <img v-if="selectConsumerStatus" @click="clearInput(0)"
                    src="../../src/static/image/close1.png" style="top: 12px; right: 10px;"/>
                </div>
              </div>
              <div class="edit_section">
                <div class="edit_title">电话</div>
                <div class="edit_time_data" style="justify-content: flex-start;">
                  <div
                    style="display: flex; align-items: center;justify-content: center;position: relative;flex: 1;">
                    <input class="userview" :disabled="selectConsumerStatus"
                      :class="selectConsumerStatus?'active':''" v-model="user_phone" />
                    <img v-if="selectConsumerStatus" @click="clearInput(1)"
                      src="../../src/static/image/close1.png" style="top: 12px;right: 10px;"/>
                  </div>
                    <el-checkbox style="margin-left: 15px;" v-if="!selectConsumerStatus" v-model="checked">该客户不建档</el-checkbox>
                </div>
              </div>
              <!-- <div style="margin: 30px 0;">
                <surmount :detail_id="userConsumerId" v-if="selectConsumerStatus"></surmount>
              </div> -->
              <!-- <div style="padding: 0px 20px 10px 20px; display: flex; justify-content: space-between;" v-if="selectConsumerStatus">
                  <div class="cus_type" @click="tapType(1)" :class="isType == 1?'cus_act':''">A客</div>
                  <div class="cus_type" @click="tapType(2)" :class="isType == 2?'cus_act':''">B客</div>
                  <div class="cus_type" @click="tapType(3)" :class="isType == 3?'cus_act':''">C客</div>
                  <div class="cus_type" @click="tapType(4)" :class="isType == 4?'cus_act':''">D客</div>
              </div>
              <tagList :detail_id="userConsumerId" v-if="selectConsumerStatus"></tagList> -->
              <!-- </view> -->
              <div class="edit_section" style="margin-bottom: 100px;">
                <div class="edit_title">备注</div>
                <div class="edit_time_data">
                  <textarea class="edit_textarea" v-model="remark" maxlength="200"></textarea>
                </div>
              </div>
            </div>
            <div class="pop_foot" v-if="cardData &&(cardData.confirmation != 2 && cardData.confirmation != 3)">
              <div class="btns" style="background-color: #9d9d9d;" @click="delUser">
                删除
              </div>
              <div class="btns" style="background-color: var(--main-color);" @click="savePopData">
                保存
              </div>
            </div>
          </el-dialog>
          <FullCalendar ref='fullCalendar'  v-if="show_calendar" class="demo-app-calendar" :options="calendarOptions">
            <template v-slot:eventContent="arg">
              <b>{{ arg.timeText }}</b>
              <i>{{ arg.event.title }}</i>
              <i v-if="arg.event.extendedProps && arg.event.extendedProps.tagStr">{{ arg.event.extendedProps.tagStr }}</i>
            </template>
          </FullCalendar>
        </div>
      </div>
    </div>
  </template>
  <script>
  import axios from 'axios'
  import { v4 as uuidv4 } from 'uuid'
  import FullCalendar from '@fullcalendar/vue'
  import dayGridPlugin from '@fullcalendar/daygrid'
  import timeGridPlugin from '@fullcalendar/timegrid'
  import interactionPlugin from '@fullcalendar/interaction'
  import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
  import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
  import { INITIAL_EVENTS, createEventId } from '../../src/event-utils'
  import { getData,instroSave, getQrCode,searchCustomers,getDayReservationFun,moveAppointmentCard,getServiceProductList,
    delAppointmentInfo,checkMchUserMobile,editAppointmentInfo, getMonthReservation, myCustomerHomepage, addServiceProductTag} from '../../src/utils/API/api'
  import store from '../../src/store';
  export default {
    name: "App",
    components: {
      FullCalendar, // make the <FullCalendar> tag available
    },
  
    data: function() {
      return {
        tabIndex:1,
        showSelectShop:false,
        shopValue:null,
        options1:[],
        adtableData: [],
        show_calendar:true,
        htmlUrl:'',
        checked:null,
        user_list:null,
        search_value:null,
        service_arr:null,
        service_user_id: null,
        userName: null,
        consumerId: null,
        userClickIndex:0,
        user_phone: null,
        userConsumerId: null,
        consumerData:null,
        serviceData:[],
        cardData: null,
        editId: null,
        remark:null,
        list_data: [],
        popMonth:'',
        popDay:'',
        popYear:'',
        popDate:'',
        isType:false,
        visible: false,
        cMonth: 0,
        cDay: null,
        cDate: null,
        calendarOptions: {
          plugins: [
            dayGridPlugin,
            timeGridPlugin,
            resourceTimeGridPlugin,
            resourceTimelinePlugin,
            interactionPlugin // needed for dateClick
          ],
          buttonText: {
            today: '今天',
            month: '月视图',
            day: '日视图'
          },
          locale: 'zh-cn',
          customButtons: { addButton: { text: "切换门店", click: this.prevYearCustomClick },outBtn: { text: "退出账号", click: this.outUser }, },
          headerToolbar: {
            left: 'prev,next today addButton outBtn',
            center: 'title',
            right: 'dayGridMonth,resourceTimeGridDay'
          },
          views: {
            resourceTimeGridDay: {
              slotDuration: '00:15:00', //占 15分钟
              slotMinTime: '08:00:00',
              slotMaxTime: '22:00:00'
            }
          },
          nowIndicator: true,
          allDayDefault: false,
          editable: true,
          dragScroll:true,
          slotLabelFormat: {
            hour12: false, // 14:00  true = 2pm
            hour: '2-digit', //'06:xx'
            minute: '2-digit' // 'xx:10'
          },
          eventTimeFormat: {
            // 在每个事件上显示的时间的格式
            hour12: false, // 14:00  true = 2pm
            hour: '2-digit', //'06:xx'
            minute: '2-digit' // 'xx:10'
          },
          // headerToolbar: true,
          allDaySlot: false,
          resources: [],
          initialView: 'resourceTimeGridDay',
          draggable: true,
          events: [],
          // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
          selectable: true,
          selectMirror: true,
          slotEventOverlap:true,
          dayMaxEvents: true,
          datesSet: this.handleDatesSet,
          weekends: true,
          selectMinDistance:20,
          select: this.handleDateSelect,
          eventClick: this.handleEventClick,
          eventsSet: this.handleEvents,
          eventDrop:this.eventDrop,
          eventResize:this.eventResize
          /* you can update a remote database when these fire:
          eventAdd:
          eventChange:
          eventRemove:
          */
        },
        currentEvents: [],
        newId: null,
        codeImage: null,
        showEditTagPop:false,
        serviceTxt:'',
        ws:null,
        showAddPop:false,
        showSelectServicePop: false,
        editTagIndex:null,
        date_list: null,
              selectType: null,
        nowChangeStatus:null,
        isCollapse: true,
              selectItem: null,
        selectConsumerStatus:null,
        serviceList:[],
              dateShow: false,
        clickIndex:0,
        showCusPop:false,
        customer_arr:[],
        value1:null,
        customer_list:[],
        showSearch:false,
        pageNo:1,
        showFun:false,
        cu_name:null,
        cu_id:null,
        userPop:null,
        is_boss:1,
        tooltipText:''
      }
    },
    mounted() {
      let date = new Date();
      let currentMonth = date.getMonth() + 1;
      let currentday = date.getDate()
      if (currentMonth.toString().length == 1) {
        currentMonth = '0' + currentMonth
      }
      if (currentday.toString().length == 1) {
        currentday = '0' + currentday
      }
      let currentyear = date.getFullYear();
      this.cYear = currentyear
      this.cMonth = currentMonth
      this.cDay = currentday
      let days = date.getDay()
      const hour = date.getHours(); // 获取小时
      const minute = date.getMinutes(); // 获取分钟
      const second = date.getSeconds()
      const nowDate = this.padZero(hour)+':'+this.padZero(minute)+':'+this.padZero(second)
              days == 0 ? this.cDate = '日' : days == 1 ? this.cDate = '一' : days == 2 ? this.cDate = '二' : days == 3 ? this
                  .cDate = '三' : days == 4 ? this.cDate = '四' : days == 5 ? this.cDate = '五' : this.cDate = '六'
      this.initWebsocket()
      this.getnowDate(nowDate)
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      tapmenu(){
        this.isCollapse = !this.isCollapse
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      showTooltip(text) {
        console.log(text)
        this.tooltipText = text;
      },
      hideTooltip() {
        this.tooltipText = '';
      },
      prevYearCustomClick(){
        this.showSelectShop = true
      },
      outUser(){
        localStorage.setItem('timeStr', 0)
        this.codeImage = null
        this.calendarOptions.resources = []
        this.loading()
        console.log('测试1')
        this.initWebsocket()
      },
      optionChange(e){
        console.log(11223344)
        this.value1 = e
        this.getDayReservation(this.cYear, this.cMonth, this.cDay)
        this.getDataList()
        this.$message({
          message: '切换成功！',
          type: 'success'
        });
        this.showSelectShop = false
      },
      getnowDate(date){
        const calendarApis = this.$refs.fullCalendar.getApi()
        calendarApis.scrollToTime(date)
      },
      initWebsocket(){
        var loading = this.$loading({
            lock: true,
            // spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.2)"
        });
        const dateTime = localStorage.getItem('timeStr');
        let times = new Date().getTime()
        this.newId = uuidv4()
        let that = this
        console.log(dateTime, 'dateTime-------')
        if(times - dateTime < 43200000){
          console.log(122222)
          loading.close()
          const wsInfo = localStorage.getItem('info');
          let po = JSON.parse(wsInfo)
          store.state.token = po.info.access_id
          store.state.mch_id = po.info.mch_id
          that.htmlUrl = po.info.import_url
          that.$emit('changeUrl', po.info.import_url)
          console.log(that.htmlUrl, 'that.htmlUrl')
          store.state.store_id = po.info.store_id
          this.is_boss = po.info.is_boss
          store.state.is_boss = po.info.is_boss
          that.$emit('changeDataFun', po.info)
          that.myCustomerHomepageFun()
        } else {
          this.codeImage = null
          if(this.ws){
            this.ws.close()
          }
          this.ws = new WebSocket('ws://8.136.1.133:1818')
          // this.ws = new WebSocket('ws://192.168.110.247:1819')
          let str = JSON.stringify({ uid: that.newId, type: 'login', data: '' })
          this.ws.onopen = function() {
            loading.close()
            console.log('连接成功')
            that.getLoginQrCode()
            that.ws.send(str)
          }
          this.ws.onmessage = function(e) {
            console.log('收到服务端信息：' + JSON.parse(e.data))
            let aa = JSON.parse(e.data)
            console.log(aa, 'aa-------------------')
            if (aa.code == 102) {
              loading.close()
              localStorage.setItem('timeStr', times)
              localStorage.setItem('info', e.data)
              store.state.token = aa.info.access_id
              store.state.store_id = aa.info.store_id
              that.is_boss = aa.info.is_boss
              store.state.is_boss = aa.info.is_boss
              that.$emit('changeDataFun', aa.info)
              that.htmlUrl = aa.info.import_url
              that.$emit('changeUrl', aa.info.import_url)
              console.log(that.htmlUrl, 'that.htmlUrl')
              store.state.mch_id = aa.info.mch_id
              that.myCustomerHomepageFun()
              setTimeout(() => {
                if(that.ws){
                  that.ws.close()
                }
              }, 1000)
            }
          }
        }
      },
      closeCusPop(){
                  this.showCusPop = false
              },
        changeTime(e){
          this.popYear = e.substr(0,4)
          this.popMonth = e.substr(5,2)
          this.popDay = e.substr(8,2)
          this.popDate = e
        },
      tapUserData(data){
                  this.userName = data.user_name
                  this.user_phone = data.mobile
                  this.userConsumerId = data.id
                  this.isType = data.consumerType
                  this.selectConsumerStatus = true
                  this.customer_list = []
              },
      // 获取数据
      getDataList() {
        let that = this
        let dates = this.cYear+'-'+this.cMonth+'-'+this.cDay
        getData({ date: dates, shop_id: this.value1})
          .then(response => {
            if(response.data.code == 401){
              console.log('测试2')
              localStorage.setItem('timeStr', 0)
              this.initWebsocket()
              return
            }
            this.codeImage = null
            let arr = response.data.data.employeeList.map((x)=>{
              if(x.class){
                x.name = x.name+`(${x.class||''})`
              }
              return {
                id:x.user_id,
                title:x.name,
                businessHours: {
                  startTime: '08:00',
                  endTime: '22:00'
                }
              }
            })
            this.calendarOptions.resources = arr.reverse()
            window.setInterval(() => {
                setTimeout(() => {
                  console.log(33445566)
                  that.getDayReservation(this.cYear, this.cMonth, this.cDay)
                }, 0)
            }, 60000)
            if(this.ws){
              this.ws.close()
            }
          })
      },
      handleWeekendsToggle() {
        this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
      },
      dateConfirm(e,data,index,type) {
                  if (type == 1) {
                      this.cardData.service_arr[index].s_time = e
                  } else {
                      if (!this.cardData.service_arr[index].s_time) {
              this.$message({
                message: '请先选择开始时间',
                type: 'warning'
              });
                          return
                      }
                      if (this.changeStrToMinutes(e) <= this.changeStrToMinutes(this.cardData.service_arr[index].s_time)) {
              this.$message({
                message: '结束时间不能小于等于开始时间',
                type: 'warning'
              });
                          return
                      }
                      this.cardData.service_arr[index].e_time = e
                  }
                  console.log(this.cardData);
          },
      // 小时转分钟
              changeStrToMinutes(str) {
                  var arrminutes = str.split(":");
                  if (arrminutes.length == 2) {
                      var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1]);
                      return minutes;
                  } else {
                      return 0;
                  }
              },
        clearInput(num) {
                  this.userName = ''
                  this.user_phone = ''
                  this.selectConsumerStatus = false
              },
        inputIng(e){
                  let that = this
                  clearTimeout(that.inputTimer);
  
                  // 设置新的定时器
                  that.inputTimer = setTimeout(() => {
                    // 在定时器结束时执行函数逻辑
                    let param = {
                        name: this.userName,
                        shop_id: '',
                        page: 1,
                        limit: 15
                    };
            searchCustomers(param)
            .then(res => {
              if (res.data.code == 0) {
                            that.customer_arr = res.data.data.data
                    that.showCusPop = true
                    if(!this.userName){
                      that.customer_arr = []
                    }
                        }
            })
            .catch(error => {
              this.$message.error('调用接口失败！')
            })
                  }, 300);
              },
      toSearch(){
        this.inputSearch()
              this.showSearch = true
        this.$nextTick(() => {
  　　　　　　　　　　　　// 进入nexTick
              var bady = document.getElementById("scroll_list");   // 获取滚动条的dom
              // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
              bady.onscroll = () => {
  　　　　　　　　　　　　　　 // 获取距离顶部的距离
                  var scrollTop = bady.scrollTop;
                  // 获取可视区的高度
                  var windowHeight = bady.clientHeight;
                  // 获取滚动条的总高度
                  var scrollHeight = bady.scrollHeight;
                  if(scrollTop+windowHeight>=scrollHeight-100){
                      // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
                      this.inputSearch()
                  }
              }
          });
      },
      tapMenuItem(type){
        this.tabIndex = type
      },
      selectConsumer(data) {
                  this.consumerData = data
                  this.userName = data.user_name
                  this.userConsumerId = data.id
                  this.user_phone = data.mobile
          this.isType = data.consumerType
                  this.showSearch = false
                  this.selectConsumerStatus = true
              },
        searchFun(){
          this.pageNo = 1
          this.customer_list= []
          this.inputSearch()
        },
      inputSearch() {
        if(this.showFun){
          return
        }
        setTimeout(() => {
          this.showFun = false
        }, 500);
        let that = this
        this.showFun = true
        let param = {
          name: this.search_value,
          shop_id: this.value1,
          page:this.pageNo,
          limit:15
        }
        searchCustomers(param)
          .then(res => {
            if(that.pageNo == 1){
              that.customer_list = res.data.data.data
            } else{
              that.customer_list = that.customer_list.concat(res.data.data.data)
            }
            that.pageNo++
          })
          .catch(error => {
            this.$message.error('调用接口失败！')
          })
      },
      // 封装的日期时间格式化函数
      formatDateTime (dateTimeString) {
          const inputDate = new Date(dateTimeString);
          const chinaTime = new Date(inputDate.getTime());
          return `${this.padZero(chinaTime.getHours())}:${this.padZero(chinaTime.getMinutes())}`;
      },
      //补零函数，用于确保单个数字格式为两位数
      padZero(num) {
          return num.toString().padStart(2, '0');
      },
      selectService() {
                  this.serviceTxt = ''
                  this.showSelectServicePop = true
              },
        tapServiceTag(item) {
                  if (item.status) {
                      this.serviceData.splice(this.serviceData
                          .findIndex(x => x.product_id == item.product_id), 1)
                      this.serviceList.map((x) => {
                          if (x.product_id == item.product_id) {
                              x.status = false
                          }
                      })
                  } else {
                      this.serviceData ? '' : this.serviceData = []
                      this.serviceData.push(item)
                      this.serviceList.map((x) => {
                          if (x.product_id == item.product_id) {
                              x.status = true
                          }
                      })
                  }
              },
      delUser(){
        let that = this
        that.$confirm('确定要删除该数据？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let param = {
                appoint_id: that.editId
              }
              console.log(11111)
              delAppointmentInfo(param)
            .then(res => {
              console.log(666)
              that.$message.success('删除成功')
              that.calendarOptions.events.splice(that.calendarOptions.events.findIndex(h=>h.appoint_id == that.editId), 1)
              that.getDayReservation(that.cYear, that.cMonth, that.cDay)
              that.showAddPop = false
              that.showEditTagPop = false
            })
            .catch(error => {
              that.$message.error('调用接口失败！')
            })
          }).catch(() => {
            that.$message({
              type: 'info',
              message: '已取消'
            });
          })
      },
      savePopData() {
                  if (this.user_phone && !this.selectConsumerStatus) {
                      this.searchPhone()
                  } else {
                      this.submit()
                  }
              },
        searchPhone() {
                  let that = this
                  let param = {
                      mobile: this.user_phone,
                  };
          checkMchUserMobile(param)
          .then(res => {
            if (res.data.data) {
                              that.cu_name = res.data.data.user_name
                              that.cu_id = res.data.data.user_id
                              that.userPop = true
                          } else {
                              that.submit()
                          }
          })
          .catch(error => {
            this.$message.error('调用接口失败！')
          })
              },
        submitCard() {
                  this.userName = this.cu_name
                  this.userConsumerId = this.cu_id
                  this.submit()
              },
              submit() {
                  let that = this
                  let param = {
                      color: "#000",
                      startDay: this.popDay,
                      endDay: this.popDay,
                      year: this.popYear,
                      month: this.popMonth,
                      date: this.popDate,
                      service_arr: this.service_arr,
                      is_new: !this.checked ? 1 : 0,
                      service_user_id: this.service_arr[0].user_id,
                      consumerName: this.userName,
                      mobile: this.user_phone || null,
                      shop_id: this.value1,
                      consumerId: this.userConsumerId,
                      remark: this.remark,
                      appoint_id: this.editId,
                      del_service_ids: this.cardData.del_service_ids || null
                  };
          editAppointmentInfo(param)
          .then(res => {
            this.$message.success('保存成功！')
                          that.userPop = false
                          that.getDayReservation(that.cYear, that.cMonth, that.cDay)
              that.showEditTagPop = false
              that.showAddPop = false
          })
          .catch(error => {
            this.$message.error(res.data.message)
          })
              },
      delFun(item) {
                  let that = this
          this.$confirm('确定要删除该数据？', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (item.id !== -1) {
                                  that.cardData.del_service_ids ? '' : that.cardData.del_service_ids = []
                                  that.cardData.del_service_ids.push(item.service_id)
                              }
                              console.log(item)
                              that.cardData.service_arr.splice(that.cardData.service_arr.findIndex(x => x
                                  .service_id == item.service_id), 1)
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });
              },
      editService(index, item) {
                  this.editTagIndex = index
                  this.serviceData = item.product_arr
                  this.serviceList.map((x) => {
                      item.product_arr.map((a) => {
                          if (x.product_id == a.product_id) {
                              x.status = true
                          }
                      })
                  })
                  this.showEditTagPop = true
              },
        confirmServiceTag() {
          let obj={
            mch_id:store.state.mch_id,
            product_name: this.serviceTxt
          }
          addServiceProductTag(obj)
          .then(res => {
            this.showSelectServicePop = false
            this.serviceList.push(res.data.data)
            this.$message.success('添加成功')
          })
          .catch(error => {
            this.$message.error('调用接口失败！')
          })
              },
      closeService(item) {
                  this.serviceData.splice(this.serviceData
                      .findIndex(x => x.product_id == item.product_id), 1)
                  this.serviceList.map((x) => {
                      console.log(x, item)
                      if (x.product_id == item.product_id) {
                          x.status = false
                      }
                  })
              },
        userConfirm(e, data, index) {
                  this.cardData.service_arr[index].name = e.name
                  this.cardData.service_arr[index].service_user_id = e
              },
      addUser() {
                  console.log(this.cardData.service_arr)
                  if (!this.cardData.service_arr) {
                      this.cardData.service_arr = []
                  }
                  this.cardData.service_arr.push({
                      e_time: null,
                      s_time: null,
                      id: -1,
                      service_user_i: -1,
                      service_id: -1,
                      product_arr: []
                  })
              },
        eventResize(event){
          console.log(event)
          let that = this
          let newData
          newData = JSON.parse(JSON.stringify(event.event.extendedProps))
          let sT = that.formatDateTime(event.event.startStr)
          let eT = that.formatDateTime(event.event.endStr)
          newData.s_time = sT
          newData.e_time = eT
          newData.date = this.cYear + '-' + this.cMonth + '-' + this.cDay
          newData.resourceId = newData.service_user_id
          console.log(newData)
          this.changeData(newData)
        },
        eventDrop(event){
          console.log(event)
          if(event.event.extendedProps.confirmation == 2 || event.event.extendedProps.confirmation == 3 ){
            return
          }
          let that = this
          let newData
          newData = JSON.parse(JSON.stringify(event.event.extendedProps))
            let sT = that.formatDateTime(event.event.startStr)
            let eT = that.formatDateTime(event.event.endStr)
            newData.s_time = sT
            newData.e_time = eT
            newData.date = this.cYear + '-' + this.cMonth + '-' + this.cDay
          if(!event.newResource){
            newData.resourceId = newData.service_user_id
          } else {
            newData.resourceId = event.newResource.id
            newData.service_user_id = event.newResource.id
          }
          console.log(newData)
          this.changeData(newData)
        },
      // 选择结束
      handleDateSelect(selectInfo) {
        console.log(selectInfo)
        let that = this
        // 日视图
        if (selectInfo.view.type == 'resourceTimeGridDay') {
          this.calendarOptions.selectMinDistance = 20
          let calendarApi = selectInfo.view.calendar
          let obj = {
            appoint_id:-1,
              id:createEventId(),
              resourceId: selectInfo.resource._resource.id,
              start: selectInfo.startStr,
              end: selectInfo.endStr,
              startDay:that.cDay,
              endDay:that.cDay,
              s_time:that.formatDateTime(selectInfo.startStr),
              e_time:that.formatDateTime(selectInfo.endStr),
              consumerName: '游客',
              username: '',
              title:'游客',
              constraint: {
                startTime: '08:00',
                endTime: '22:00'
              },
              mch_id:store.state.mch_id,
              service_user_id:selectInfo.resource._resource.id,
              mobile: '',
              year: that.cYear,
              month: that.cMonth,
              date: that.cYear + '-' + that.cMonth + '-' + that.cDay,
              product: [],
              service_arr: [{
                user_id: '',
                s_time: '09:00',
                e_time: '10:00'
              }],
              day: that.cDay,
              cDate: that.cDate,
              time_diff: (new Date(selectInfo.endStr).getTime() - new Date(selectInfo.startStr).getTime())/60000,
              appoint_id: -1,
              x: 105,
              y: 0
          }
          calendarApi.unselect()
          obj.shop_id = this.value1
          this.calendarOptions.events.push(obj)
          this.changeData(obj)
        } else {
          this.calendarOptions.selectMinDistance = 0
          // 月视图
          this.nowChangeStatus = true
          setTimeout(() => {
            this.nowChangeStatus = false
          }, 1000);
          const calendarApis = that.$refs.fullCalendar.getApi()
          calendarApis.changeView('resourceTimeGridDay')
          this.formatTime(selectInfo.start)
          console.log(776688)
          const dateTime = localStorage.getItem('timeStr');
          if(dateTime) this.getDayReservation(this.cYear, this.cMonth, this.cDay)
          this.getDataList()
          calendarApis.gotoDate(selectInfo.startStr)
        }
      },
      changeData(obj){
        this.loading()
        moveAppointmentCard(obj)
        .then(res => {
          this.getDayReservation(this.cYear, this.cMonth, this.cDay)
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
      },
      loading: function() {
            var loading = this.$loading({
                lock: true,
                // spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.2)"
            });
            setTimeout(() => {
                loading.close();
            }, 2000);
        },
      getDayReservation(year, month, day) {
                  let that = this
                  let param = {
                      user_id: that.user_id,
                      date: year + '-' + month + '-' + day,
                      shop_id: this.value1
                  };
          getDayReservationFun(param)
          .then(res => {
            that.calendarOptions.events.splice(that.calendarOptions.events.findIndex(h=>h.appoint_id == -1), 1)
            const result = Object.values([...that.calendarOptions.events, ...res.data.data].reduce((result, {
                              service_id,
                              ...rest
                          }) => {
                              result[service_id] = {
                                  ...(result[service_id] || {}),
                                  service_id,
                                  ...rest
                              };
                              return result;
                          }, {}))
              result.map((n)=>{
                n.constraint ={
                  startTime: '08:00',
                  endTime: '22:00'
                }
                n.editable = n.confirmation ==2 || n.confirmation ==3?false:true
                n.backgroundColor = n.confirmation ==2 || n.confirmation ==3?'#cccccc':'#2195f3'
                n.borderColor = n.confirmation ==2 || n.confirmation ==3?'#cccccc':'#2195f3'
                n.tagStr = ''
                n.service_arr.map((c)=>{
                  c.tagstr = ''
                  c.product_arr.map((v)=>{
                    v.amount?c.tagstr += v.product_name+'('+v.amount+')'+'+': c.tagstr += v.product_name+'+'
                  })
                  c.tagstr = c.tagstr.substring(0, c.tagstr.length-1)
                })
                n.service_arr.map((c)=>{
                  n.tagStr += c.tagstr + '+'
                })
                n.tagStr = n.tagStr.substring(0, n.tagStr.length-1)
                console.log(n.tagStr)
                n.resourceId = n.service_user_id
                n.start = n.date+'T'+n.s_time+':00'+'+'+'08:00'
                n.end = n.date+'T'+n.e_time+':00'+'+'+'08:00'
                n.title = n.consumerName
              })
              this.calendarOptions.events = result
          })
          .catch(error => {
            this.outUser()
          })
              },
        myCustomerHomepageFun() {
          let that = this
          myCustomerHomepage(this.is_boss)
          .then(res => {
            if (res.data.data.mchStore.length == 0) {
              that.$message.error('您还没有门店，请先添加门店！')
              return
              } else {
                  let a = res.data.data.mchStore[0].mch_name
                  let b = res.data.data.mchStore[0].store_name
                  that.shopTxt = `${a}(${b})`
                  that.value1 = res.data.data.mchStore[0].id
              }
              that.options1 = res.data.data.mchStore.map(item => {
                  return {
                      value: item.id,
                      label: `${item.mch_name}(${item.store_name})`
                  }
              })
              this.getDayReservation(this.cYear, this.cMonth, this.cDay)
              this.getDataList()
          })
          .catch(error => {
            this.$message.error('调用接口失败！')
          })
        },
      handleEventClick(clickInfo) {
        console.log(clickInfo, '22222')
        this.formatTime(clickInfo.event.start)
        if(clickInfo.event.extendedProps.consumerId){
            this.selectConsumerStatus = true
          }
        // 双击 弹出修改编辑
        this.service_arr = clickInfo.event.extendedProps.service_arr
        this.service_user_id = clickInfo.event.extendedProps.service_user_id
        this.userName = clickInfo.event.extendedProps.consumerName
        this.consumerId = clickInfo.event.extendedProps.consumerId
        this.user_phone = clickInfo.event.extendedProps.mobile
        this.userConsumerId = clickInfo.event.extendedProps.consumerId
        this.isType = clickInfo.event.extendedProps.consumerType
        this.remark = clickInfo.event.extendedProps.remark
        this.cardData = clickInfo.event.extendedProps
        this.editId = clickInfo.event.extendedProps.appoint_id
        this.user_list = this.calendarOptions.resources
        this.showAddPop = true
        this.getServiceList()
      },
      getServiceList() {
                  let param = {
                      mch_id: store.state.mch_id
                  };
          getServiceProductList(param)
          .then(res => {
            this.serviceList = res.data.data.data
                          this.serviceList.map((x) => {
                              x.status = false
                          })
          })
          .catch(error => {
            this.$message.error('获取失败！')
          })
              },
        confirmEditTag() {
                  this.cardData.service_arr[this.editTagIndex].product_arr = JSON.parse(JSON.stringify(this.serviceData))
                  this.serviceList.map((x) => {
                      x.status = false
                  })
                  this.showEditTagPop = false
              },
        tapType(data) {
                  let that = this
                  let param = {
                      id: this.userConsumerId,
                      consumerType: data
                  };
            instroSave(param)
            .then(res => {
                      if (res.data.code == 0) {
                          that.isType = data
                          // that.getData()
                      }
            })
            .catch(error => {
              this.$message.error('获取失败！')
            })
              },
      getLoginQrCode() {
        let that = this
        getQrCode({ key: this.newId })
          .then(response => {
            console.log(response.data)
            this.codeImage = response.data.data.path
            console.log(this.codeImage)
          })
          .catch(error => {
            this.$message.error('获取失败！')
          })
      },
      handleDatesSet(e) {
        let that = this
        if(this.nowChangeStatus){
          return
        }
        const dateTime = localStorage.getItem('timeStr');
        if(!dateTime || dateTime == 0){
          return
        }
        this.calendarOptions.events = []
        if (e.view.type == 'resourceTimeGridDay') {
          this.calendarOptions.selectMinDistance = 0
          this.formatTime(e.start)
          // this.calendarOptions = JSON.parse(JSON.stringify(this.calendarOptions))
          console.log(this.calendarOptions.selectMinDistance)
          this.getDayReservation(this.cYear, this.cMonth, this.cDay)
        } else {
          this.calendarOptions.selectMinDistance = 20
          // this.calendarOptions = JSON.parse(JSON.stringify(this.calendarOptions))
          console.log(this.calendarOptions.selectMinDistance)
          this.getMonthReservationFun(this.cYear, this.cMonth)
        }
      },
      getMonthReservationFun(year, month) {
                  let param = {
                      user_id: this.user_id,
                      query_month: month,
                      query_year: year,
                      date: year + '-' + month,
                      shop_id: this.value1
                  };
          getMonthReservation(param)
          .then(res => {
            let result = res.data.data
            result.map((n)=>{
                n.constraint ={
                  startTime: '08:00',
                  endTime: '22:00'
                }
                n.resourceId = n.service_user_id
                n.start = n.date+'T'+n.s_time+':00'+'+'+'08:00'
                n.end = n.date+'T'+n.e_time+':00'+'+'+'08:00'
                n.title = n.consumerName
              })
              console.log(result)
              this.calendarOptions.events = result
          })
          .catch(error => {
            this.$message.error('获取失败！')
          })
              },
      formatTime(date){
        const year = date.getFullYear();
        const month = date.getMonth() + 1;
        const day = date.getDate()
        this.cYear = year
        this.popMonth = this.padZero(month)
        this.popDate = year+'-'+this.padZero(month)+'-'+this.padZero(day)
        this.cMonth = this.padZero(month)
        this.cDay = this.padZero(day)
        let days = date.getDay()
              days == 0 ? this.cDate = '日' : days == 1 ? this.cDate = '一' : days == 2 ? this.cDate = '二' : days == 3 ? this
                  .cDate = '三' : days == 4 ? this.cDate = '四' : days == 5 ? this.cDate = '五' : this.cDate = '六'
      },
      handleEvents(events) {
        this.currentEvents = events
      }
    }
  }
  </script>
  
  <style lang='scss' scoped>
  .qcodeDiv {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
  /deep/ .fc-h-event .fc-event-title{
    width: 13px;
  }
  .seachUserListPop{
          position: absolute;
          height: 135px;
      overflow: hidden;
          left: 0;
          right: 0;
          top: 40px;
          background-color: #ffffff;
          z-index: 100;
          border-radius: 0 0 6px 6px;
          box-shadow: 2px 4px 15px 4px #d0d0d0;
          .useritem{
              padding: 14px 10px;
              font-size: 13px;
              display: flex;
              align-items: center;
              border-bottom: 1px solid #f6f6f6;
          }
      }
      .service_item {
          height: 32px;
          padding: 5px 10px;
          background-color: #f5f5f5;
          color: #333;
          border-radius: 4px;
          margin: 8px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          image {
              height: 15px;
              width: 15px;
              margin-left: 5px;
          }
      }
  .pop {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: #000000;
    opacity: 0.8;
    z-index: 100;
  }
  .addService {
          min-width: 88px;
          max-width: 88px;
          height: 30px;
          display: flex;
          font-size: 12px;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          border-radius: 34px;
          border: 1px solid #ddd;
          color: #222;
      }
    .service_item {
          height: 20px;
          padding: 5px 15px;
          background-color: #f5f5f5;
          color: #333;
          border-radius: 4px;
          margin: 8px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
      position: relative;
          img {
              height: 10px !important;
              width: 10px !important;
              margin-left: 5px !important;
        top: 3px !important;
        right: 3px !important;
          }
      }
  .imgDiv {
    height: 300px;
    z-index: 100;
    width: 260px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f5f5f5;
    flex-direction: column;
  }
  /deep/ .fc-scrollgrid{
    --fc-today-bg-color:#ffffff
  }
  /deep/ .fc-today-button{
    right: 170px !important;
  }
  .cus_type {
          height: 30px;
          width: 70px;
          border: 1px solid #333;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 10rpx;
      }
  
      .cus_act {
          background-color: #95631e;
          border: 1px solid #95631e;
          color: #ffffff;
      }
  .codeImg {
    margin-bottom: 20px;
  }
  .codeView {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h2 {
    margin: 0;
    font-size: 16px;
  }
  
  
  b {
    /* used for event dates/times */
    margin-right: 3px;
  }
  /deep/ .fc-timegrid-slots tr {
    height: 30px;
  }
  .demo-app {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
    font-size: 14px;
  }
  .tooltip{
    height: 49px;
    width: 30px;
  }
  .service_input {
          display: flex;
          background-color: #f5f5f5;
          border-radius: 6px;
          width: 100%;
      }
  
      .act {
          background-color: var(--main-color) !important;
          color: #fff !important;
          opacity: .7;
      }
  
  .demo-app-sidebar {
    width: 300px;
    line-height: 1.5;
    background: #eaf9ff;
    border-right: 1px solid #d3e2e8;
  }
  
  .demo-app-sidebar-section {
    padding: 2em;
  }
  /deep/ .fc-scrollgrid-sync-inner {
    height: 40px !important;
    line-height: 40px !important;
  }
  .demo-app-main {
    flex-grow: 1;
    padding: 3em;
  }
  .card_cont {
          display: flex;
          flex-direction: column;
          align-items: center;
  
          .card_foot {
              display: flex;
              width: 100%;
              margin-top: 10px;
              border-top: 1px solid #ddd;
  
              .foot_btn {
                  height: 46px;
                  flex: 1;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--main-color);
              }
          }
  
          .tip {
        margin-top: 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-bottom: 20px;
          }
  
          .card_title {
              font-size: 17px;
              font-weight: 600;
              margin-top: 20px;
          }
  
          .card_cancle {
              border-right: 1px solid #ddd;
          }
  
          .card_head {
              margin-bottom: 20px;
          }
  
          .txt {}
      }
  .fc {
     margin: 0 auto;
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 150px;
    min-height: 400px;
  }
  #fc_div /deep/ .fc-license-message {
    display: none !important;
  }
  #fc_div .fc-license-message {
    display: none !important;
  }
  /deep/ .fc-timegrid-col-events{
    margin: 0 !important;
  }
  /deep/ .el-dialog__body{
    padding: 0 20px 20px 20px;
  }
  /deep/ .fc-v-event{
    opacity: .8;
  }
  /deep/ .fc-event-main {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .edit_service_tag {
          flex-wrap: wrap;
          display: flex;
          border-radius: 8px;
      }
  .pop_foot {
          position: absolute;
          bottom: 0;
          height: 50px;
          width: 100%;
          background-color: #f5f5f5;
          display: flex;
          color: #ffffff;
          justify-content: center;
      left: 0;
          align-items: center;
  
          .btns {
              flex: 1;
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              flex-direction: column;
              font-weight: 600;
  
              img {
                  height: 30px;
                  width: 30px;
                  margin: 0 0 5px 0;
              }
          }
      }
    .user_list {
          padding: 20px;
      overflow: hidden;
      overflow-y: auto;
      height: 400px;
          .user_search_input {
              padding: 20px;
              margin-bottom: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
  
              .searchBtn {
                  width: 80px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 7px;
                  background-color: var(--main-color);
                  border: 1px solid var(--main-color);
                  color: #fff;
                  margin-left: 10px;
              }
  
              input {
                  border-radius: 6px;
                  border: 1px solid #ddd;
                  height: 20px;
                  font-size: 13px;
                  padding: 10px;
              }
          }
      .edit_service_tag {
          flex-wrap: wrap;
          display: flex;
          border-radius: 8px;
      }
  
      .edit_title_box {
          height: 60px;
          display: flex;
          font-size: 16px;
          font-weight: 600;
          align-items: center;
          justify-content: center;
      }
  
      .service_input {
          display: flex;
          background-color: #f5f5f5;
          border-radius: 6px;
          width: 100%;
      }
  
      .act {
          background-color: var(--main-color) !important;
          color: #fff !important;
          opacity: .7;
      }
  
      .service_item {
          height: 32px;
          padding: 5px 10px;
          background-color: #f5f5f5;
          color: #333;
          border-radius: 4px;
          margin: 8px;
          font-size: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
  
          image {
              height: 15px;
              width: 15px;
              margin-left: 5px;
          }
      }
          .user_list_item {
              padding: 15px;
              box-shadow: 0 0 3px 0px var(--main-color);
              border-radius: 8px;
              margin-bottom: 20px;
  
              .name {
                  font-weight: 600;
                  margin-bottom: 10px;
              }
  
              .phone {
                  font-weight: 600;
              }
          }
      }
    .actMenu{
      transform: rotate(180deg)
    }
  .editData {
          .user_cont_view {
              padding: 10px 5px 10px 5px;
              border-radius: 8px;
              border: 2px solid var(--main-color);
              margin-bottom: 20px;
          }
  
          .edit_section {
              display: flex;
              font-size: 13px;
              flex-direction: column;
  
              .head_time {
                  font-size: 17px;
                  font-weight: 600;
                  font-weight: 600;
              }
  
              .edit_title {
                  font-size: 13px;
          margin: 10px 0;
                  width: 100%;
                  font-weight: 600;
                  display: flex;
                  align-items: center;
              }
  
              .edit_service {
                  display: flex;
                  flex-direction: column;
                  align-items: flex-start;
                  justify-content: center;
                  flex: 1;
                  .edit_tag {
                      margin: 5px;
                  }
              }
  
              .addUser {
                  width: 100%;
                  height: 40px;
                  display: flex;
                  font-size: 15px;
                  font-weight: 600;
                  align-items: center;
                  justify-content: center;
                  border-radius: 4px;
                  border: 1px solid var(--main-color);
                  color: var(--main-color);
              }
              .edit_time_data {
                  display: flex;
                  width: 100%;
                  justify-content: flex-start;
                  align-items: center;
                  font-size: 12px;
                  position: relative;
  
                  input {
                      font-weight: 600 !important;
                  }
  
                  .edit_time {
                      display: flex;
                  }
  
                  .edit_user_data {
                      width: 100%;
                      position: relative;
                      flex: 1;
                  }
  
                  .edit_textarea {
                      border: 1px solid var(--main-color);
                      width: 260px;
                      border-radius: 8px;
                      height: 100px;
                      padding: 10px;
                  }
  
                  .stime {
                      width: 100px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                  }
  
                  .search_user {
                      width: 70px;
                      height: 35px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                      background-color: var(--main-color);
                      border: 1px solid var(--main-color);
                      color: #fff;
  
                      img {
                          height: 20px;
                          width: 20px;
                          margin-right: 6px;
                      }
                  }
  
                  .userview {
                      flex: 1;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      position: relative;
                      justify-content: center;
                      border-radius: 4px;
                      padding: 0 10px;
                      font-size: 13px;
            border: 1px solid var(--main-color);
                  }
  
                  .active {
                      background-color: #f5f5f5;
                  }
  
                  img {
                      height: 15px;
                      width: 15px;
                      position: absolute;
                      right: 5px;
                      top: 8px;
                  }
  
                  .etime {
                      width: 100px;
                      height: 40px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      border-radius: 4px;
                  }
  
                  .line {
                      margin: 0 5px;
                  }
              }
          }
      }
  </style>
  