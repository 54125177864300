var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "specialTrainChapter",
      attrs: {
        "lock-scroll": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        { ref: "form", attrs: { model: _vm.form, "label-width": "auto" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { staticClass: "addnewcate" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.addChapter()
                        },
                      },
                    },
                    [_vm._v("新增阶段")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { staticClass: "switchBox" },
                    [
                      _c("span", [_vm._v("激活天数限制")]),
                      _vm._v(" "),
                      _c("el-switch", {
                        attrs: {
                          "active-value": 1,
                          "inactive-value": 0,
                          "active-text": "是",
                          "inactive-text": "否",
                        },
                        model: {
                          value: _vm.form.switchStatus,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "switchStatus", $$v)
                          },
                          expression: "form.switchStatus",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { staticStyle: { "padding-top": "15px" } },
                [
                  _c(
                    "draggable",
                    {
                      attrs: { group: "title" },
                      model: {
                        value: _vm.form.chapter,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "chapter", $$v)
                        },
                        expression: "form.chapter",
                      },
                    },
                    [
                      _c(
                        "transition-group",
                        _vm._l(_vm.form.chapter, function (item, index) {
                          return _c(
                            "el-card",
                            {
                              key: index,
                              staticClass: "box-card",
                              attrs: { shadow: "never" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "clearfix chapterCateHeader",
                                  attrs: { slot: "header" },
                                  slot: "header",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(item.title) +
                                      "\n              "
                                  ),
                                  _c("i", {
                                    staticClass: "el-icon-edit-outline",
                                    attrs: { size: "15" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.addChapter(item)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: { width: "700", trigger: "click" },
                                      model: {
                                        value: _vm.isExaminationShow,
                                        callback: function ($$v) {
                                          _vm.isExaminationShow = $$v
                                        },
                                        expression: "isExaminationShow",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择类型",
                                                        filterable: "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchForm
                                                            .goodtype,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.searchForm,
                                                            "goodtype",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "searchForm.goodtype",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.typeList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入课程名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.searchForm.title,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.searchForm,
                                                          "title",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "searchForm.title",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.getmallGoodsList,
                                                      },
                                                    },
                                                    [_vm._v("搜索")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "min-height": "300px",
                                          },
                                          attrs: {
                                            data: _vm.mallGoodsList,
                                            "max-height": "300",
                                          },
                                          on: {
                                            "selection-change":
                                              _vm.selectionchange,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "40",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "section_t",
                                              label: "版块",
                                              width: "60",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "goodtype_t",
                                              label: "类型",
                                              width: "60",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "title",
                                              label: "名称",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            [
                                              _c("el-pagination", {
                                                staticStyle: {
                                                  padding: "10px 0px 0px 0px",
                                                  overflow: "hidden",
                                                },
                                                attrs: {
                                                  "current-page":
                                                    _vm.searchForm.page,
                                                  layout: _vm.layout,
                                                  "page-size":
                                                    _vm.searchForm.limit,
                                                  total: _vm.total,
                                                  background: "",
                                                },
                                                on: {
                                                  "size-change":
                                                    _vm.handleSizeChange,
                                                  "current-change":
                                                    _vm.handleCurrentChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "el-dialog__footer",
                                          staticStyle: {
                                            margin: "10px -10px -10px -10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { float: "right" },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.syncSelectedList()
                                                  _vm.isExaminationShow = false
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: { float: "right" },
                                          attrs: {
                                            slot: "reference",
                                            type: "success",
                                          },
                                          on: {
                                            click: function ($event) {
                                              var this$1 = this
                                              _vm.getmallGoodsList(index)
                                              !function () {
                                                this$1.isExaminationShow = true
                                              }
                                            },
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v("新增考试任务")]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: { width: "700", trigger: "click" },
                                      model: {
                                        value: _vm.isClassShow,
                                        callback: function ($$v) {
                                          _vm.isClassShow = $$v
                                        },
                                        expression: "isClassShow",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择类型",
                                                        filterable: "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchForm
                                                            .goodtype,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.searchForm,
                                                            "goodtype",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "searchForm.goodtype",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.typeList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入课程名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.searchForm.search,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.searchForm,
                                                          "search",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "searchForm.search",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.searchMallGoodsList,
                                                      },
                                                    },
                                                    [_vm._v("搜索")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "min-height": "300px",
                                          },
                                          attrs: {
                                            data: _vm.mallGoodsList,
                                            "max-height": "300",
                                          },
                                          on: {
                                            "selection-change":
                                              _vm.selectionchange,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "60",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "goodtype_t",
                                              label: "类型",
                                              width: "80",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "title",
                                              label: "名称",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            [
                                              _c("el-pagination", {
                                                staticStyle: {
                                                  padding: "10px 0px 0px 0px",
                                                  overflow: "hidden",
                                                },
                                                attrs: {
                                                  "current-page":
                                                    _vm.searchForm.pageNo,
                                                  layout: _vm.layout,
                                                  "page-size":
                                                    _vm.searchForm.pageSize,
                                                  total: _vm.total,
                                                  background: "",
                                                },
                                                on: {
                                                  "size-change":
                                                    _vm.handleSizeChange,
                                                  "current-change":
                                                    _vm.handleCurrentChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "el-dialog__footer",
                                          staticStyle: {
                                            margin: "10px -10px -10px -10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { float: "right" },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.syncSelectedList()
                                                  item.isExaminationShow = false
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-popover",
                                    {
                                      attrs: { width: "800", trigger: "click" },
                                      model: {
                                        value: item.isClassShow,
                                        callback: function ($$v) {
                                          _vm.$set(item, "isClassShow", $$v)
                                        },
                                        expression: "item.isClassShow",
                                      },
                                    },
                                    [
                                      _c(
                                        "el-row",
                                        { attrs: { gutter: 20 } },
                                        [
                                          _c(
                                            "el-col",
                                            {
                                              staticClass: "mallGoodsPopover",
                                              attrs: { span: 7 },
                                            },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择版块",
                                                        filterable: "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchForm
                                                            .section,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.searchForm,
                                                            "section",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "searchForm.section",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.sectionList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-select",
                                                    {
                                                      staticStyle: {
                                                        width: "100%",
                                                      },
                                                      attrs: {
                                                        placeholder:
                                                          "请选择类型",
                                                        filterable: "",
                                                        clearable: "",
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.searchForm
                                                            .goodtype,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.searchForm,
                                                            "goodtype",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "searchForm.goodtype",
                                                      },
                                                    },
                                                    _vm._l(
                                                      _vm.typeList,
                                                      function (item, index) {
                                                        return _c("el-option", {
                                                          key: index,
                                                          attrs: {
                                                            label: item.title,
                                                            value: item.id,
                                                          },
                                                        })
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 7 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c("el-input", {
                                                    attrs: {
                                                      clearable: "",
                                                      placeholder:
                                                        "请输入课程名称",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.searchForm.search,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.searchForm,
                                                          "search",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "searchForm.search",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-col",
                                            { attrs: { span: 3 } },
                                            [
                                              _c(
                                                "el-form-item",
                                                {
                                                  staticStyle: {
                                                    "margin-bottom": "10px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "el-button",
                                                    {
                                                      attrs: {
                                                        type: "primary",
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.getmallGoodsList,
                                                      },
                                                    },
                                                    [_vm._v("搜索")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-table",
                                        {
                                          staticStyle: {
                                            width: "100%",
                                            "min-height": "300px",
                                          },
                                          attrs: {
                                            data: _vm.mallGoodsList,
                                            "max-height": "300",
                                          },
                                          on: {
                                            "selection-change":
                                              _vm.selectionchange,
                                          },
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              type: "selection",
                                              width: "40",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "section_t",
                                              label: "版块",
                                              width: "60",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "goodtype_t",
                                              label: "类型",
                                              width: "60",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              property: "title",
                                              label: "名称",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        [
                                          _c(
                                            "el-col",
                                            [
                                              _c("el-pagination", {
                                                staticStyle: {
                                                  padding: "10px 0px 0px 0px",
                                                  overflow: "hidden",
                                                },
                                                attrs: {
                                                  "current-page":
                                                    _vm.searchForm.pageNo,
                                                  layout: _vm.layout,
                                                  "page-size":
                                                    _vm.searchForm.pageSize,
                                                  total: _vm.total,
                                                  background: "",
                                                },
                                                on: {
                                                  "size-change":
                                                    _vm.handleSizeChange,
                                                  "current-change":
                                                    _vm.handleCurrentChange,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-row",
                                        {
                                          staticClass: "el-dialog__footer",
                                          staticStyle: {
                                            margin: "10px -10px -10px -10px",
                                          },
                                        },
                                        [
                                          _c(
                                            "el-button",
                                            {
                                              staticStyle: { float: "right" },
                                              attrs: { type: "primary" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.syncSelectedList(index)
                                                  _vm.isClassShow = false
                                                },
                                              },
                                            },
                                            [_vm._v("保存")]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-button",
                                        {
                                          staticStyle: {
                                            float: "right",
                                            "margin-right": "10px",
                                          },
                                          attrs: {
                                            slot: "reference",
                                            type: "primary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              var this$1 = this
                                              _vm.getmallGoodsList(index)
                                              !function () {
                                                this$1.isClassShow = true
                                              }
                                            },
                                          },
                                          slot: "reference",
                                        },
                                        [_vm._v("新增课程任务")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "goods_list",
                                  staticStyle: { margin: "-20px" },
                                },
                                [
                                  _c(
                                    "draggable",
                                    {
                                      attrs: { group: "goodslist" + item.id },
                                      model: {
                                        value: item.list,
                                        callback: function ($$v) {
                                          _vm.$set(item, "list", $$v)
                                        },
                                        expression: "item.list",
                                      },
                                    },
                                    [
                                      _c(
                                        "transition-group",
                                        _vm._l(
                                          item.list,
                                          function (iitem, iindex) {
                                            return _c(
                                              "el-row",
                                              { key: iindex },
                                              [
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticClass: "tdc",
                                                    attrs: { span: 2 },
                                                  },
                                                  [_vm._v(_vm._s(iindex + 1))]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  { attrs: { span: 12 } },
                                                  [_vm._v(_vm._s(iitem.title))]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticClass: "tdc",
                                                    attrs: { span: 6 },
                                                  },
                                                  [_vm._v("拖动排序")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "el-col",
                                                  {
                                                    staticClass: "tdc",
                                                    attrs: { span: 4 },
                                                  },
                                                  [
                                                    _c(
                                                      "el-popover",
                                                      {
                                                        attrs: {
                                                          placement: "top",
                                                          width: "160",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.showVisible,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.showVisible =
                                                              $$v
                                                          },
                                                          expression:
                                                            "showVisible",
                                                        },
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            "是否确认移除此课程？"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "text-align":
                                                                "right",
                                                              margin: "0",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  size: "mini",
                                                                  type: "text",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.showVisible = false
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("取消")]
                                                            ),
                                                            _vm._v(" "),
                                                            _c(
                                                              "el-button",
                                                              {
                                                                attrs: {
                                                                  type: "primary",
                                                                  size: "mini",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.removeChapterItem(
                                                                        index,
                                                                        iindex
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [_vm._v("确定")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "el-button",
                                                          {
                                                            attrs: {
                                                              slot: "reference",
                                                            },
                                                            slot: "reference",
                                                          },
                                                          [_vm._v("删除")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            width: "30%",
            title: _vm.chapterDialogTitle,
            visible: _vm.chapterDialog,
            "close-on-click-modal": false,
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.chapterDialog = $event
            },
          },
        },
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.form, "label-width": "auto" } },
            [
              _c(
                "el-row",
                { attrs: { gutter: 20 } },
                [
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "阶段名称", prop: "" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请填写阶段名称" },
                            model: {
                              value: _vm.chapterform.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.chapterform, "title", $$v)
                              },
                              expression: "chapterform.title",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "限定启动时间", prop: "" } },
                        [
                          _c("el-switch", {
                            attrs: {
                              "active-value": 1,
                              "inactive-value": 0,
                              "active-text": "是",
                              "inactive-text": "否",
                            },
                            model: {
                              value: _vm.chapterform.time_limit,
                              callback: function ($$v) {
                                _vm.$set(_vm.chapterform, "time_limit", $$v)
                              },
                              expression: "chapterform.time_limit",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.chapterform.time_limit == 1
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "训练开始时间", prop: "" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  align: "left",
                                  type: "datetime",
                                  placeholder: "开始时间",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  editable: false,
                                },
                                model: {
                                  value: _vm.chapterform.start_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.chapterform, "start_time", $$v)
                                  },
                                  expression: "chapterform.start_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.chapterform.time_limit == 1
                    ? _c(
                        "el-col",
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "训练结束时间", prop: "" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  align: "left",
                                  type: "datetime",
                                  placeholder: "结束时间",
                                  "value-format": "yyyy-MM-dd HH:mm:ss",
                                  editable: false,
                                },
                                model: {
                                  value: _vm.chapterform.end_time,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.chapterform, "end_time", $$v)
                                  },
                                  expression: "chapterform.end_time",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _vm.chapterform.index != -1
                ? _c(
                    "el-button",
                    {
                      staticStyle: { float: "left" },
                      attrs: { type: "danger" },
                      on: { click: _vm.delChapter },
                    },
                    [_vm._v("删除")]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.chapterSave } },
                [_vm._v("保存")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }