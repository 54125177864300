var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticStyle: { background: "white" } },
            [
              _c(
                "div",
                { staticClass: "search" },
                [
                  _c(
                    "el-form",
                    {
                      staticClass: "demo-form-inline",
                      attrs: { inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入频道标题",
                              clearable: "",
                            },
                            model: {
                              value: _vm.searchForm.title,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchForm, "title", $$v)
                              },
                              expression: "searchForm.title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            { staticClass: "btn1", on: { click: _vm.getList } },
                            [_vm._v("查找")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          staticStyle: { float: "right" },
                          attrs: { label: "" },
                        },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.Edit({})
                                },
                              },
                            },
                            [_vm._v("添加")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading",
                    },
                  ],
                  ref: "table",
                  staticStyle: { width: "100%" },
                  attrs: {
                    "element-loading-text": "加载中...",
                    "element-loading-spinner": "el-icon-loading",
                    data: _vm.list,
                    "row-style": { height: "81px" },
                    "cell-style": { textAlign: "center" },
                    "header-cell-style": {
                      color: "#333",
                      fontSize: "14px",
                      backgroundColor: "#F6F7FB",
                      fontWeight: "normal",
                      textAlign: "center",
                    },
                  },
                },
                [
                  _c("el-table-column", {
                    attrs: { prop: "mch_name", label: "店铺名" },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: { prop: "rule_type", label: "频道权限类型" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  scope.row.rule_type == 1
                                    ? "全员"
                                    : scope.row.rule_type == 2
                                    ? "老板"
                                    : "干部"
                                )
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "title",
                      label: "频道名称",
                      align: "center",
                      "min-width": "120",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "subscribe_num",
                      label: "收藏量",
                      align: "center",
                      "min-width": "100",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "collection_num",
                      label: "订阅量",
                      align: "center",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "start_time",
                      label: "开始时间",
                      align: "center",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "end_time",
                      label: "失效时间",
                      align: "center",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "sort",
                      label: "排序",
                      align: "center",
                      "min-width": "50",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      label: "课程内容",
                      align: "center",
                      "min-width": "150",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-link",
                              {
                                attrs: { type: "primary" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chapterEdit(scope.row)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "章节管理 ( " +
                                    _vm._s(scope.row.chapter_count) +
                                    " )"
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "create_time",
                      label: "创建时间",
                      align: "center",
                      "min-width": "150",
                    },
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      prop: "status",
                      label: "状态",
                      align: "center",
                      "min-width": "80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    scope.row.status == 0 ? "失效" : "有效"
                                  ) +
                                  "\n          "
                              ),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      label: "操作",
                      "min-width": "120",
                      fixed: "right",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "div",
                              { staticClass: "operation" },
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text_btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Edit(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("编辑")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "text_btn",
                                    attrs: { type: "text" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Delete(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.searchForm.page,
                      "page-sizes": [10, 20, 50, 100],
                      "page-size": _vm.searchForm.limit,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.total,
                    },
                    on: {
                      "size-change": _vm.handleLimitChange,
                      "current-change": _vm.handlePageChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("StudyCreate", { ref: "edit", on: { "fetch-data": _vm.getList } }),
          _vm._v(" "),
          _c("StudyChapter", {
            ref: "chapteredit",
            on: { "fetch-data": _vm.getList },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }