<template>
  <div class="demo-app" id="fc_div">
    <div style="display: flex;">
      <div style="padding: 20px 0;display: flex">
        <el-menu class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
        <div style="display: flex; align-items: center;justify-content: center;margin-bottom: 20px;">
          <img style="height: 30px; width: 30px;border-radius: 10px;" src="../src/static/image/logo.png">
          <img @click="tapmenu" :class="isCollapse?'actMenu':''" style="background: #ffffff;z-index: 10;height: 16px;width: 16px;border-radius: 20px;position: absolute;top: 5px;right: -8px;" src="./static/image/back.png"/>
        </div>
        <el-menu-item index="1" @click="tapMenuItem(1)">
          <i class="el-icon-s-home"></i>
          <span slot="title">预约管理</span>
        </el-menu-item>
        <el-menu-item index="2" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(2)">
          <i class="el-icon-menu"></i>
          <span slot="title">训练营管理</span>
        </el-menu-item>
        <el-menu-item index="3" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(3)">
            <i class="el-icon-document"></i>
            <span slot="title">频道管理</span>
        </el-menu-item>
        <el-menu-item index="4" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(4)">
          <i class="el-icon-setting"></i>
          <span slot="title">课程管理</span>
        </el-menu-item>
        <el-menu-item index="5" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(5)">
          <i class="el-icon-printer"></i>
          <span slot="title">客户导入</span>
        </el-menu-item>
        <el-menu-item index="6" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(6)">
          <i class="el-icon-collection"></i>
          <span slot="title">工作指南</span>
        </el-menu-item>
        <el-menu-item index="7" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(7)">
          <i class="el-icon-date"></i>
          <span slot="title">排班设置</span>
        </el-menu-item>
        <el-menu-item index="8" v-if="is_boss == 1 || is_boss == 2 || is_boss == 4 || is_boss == 5" @click="tapMenuItem(8)">
          <i class="el-icon-files"></i>
          <span slot="title">考勤查询</span>
        </el-menu-item>
      </el-menu>
      </div>
      <taskCalendar @changeDataFun="changeDataFun" @changeUrl="changeUrl" v-if="tabIndex==1"></taskCalendar>
      <Scheduling v-if="tabIndex==7"></Scheduling>
      <Course v-if="tabIndex==4"></Course>
      <SchoolVue v-if="tabIndex==3"></SchoolVue>
      <specialTrain v-if="tabIndex==2"></specialTrain>
      <instructions v-if="tabIndex==6"></instructions>
      <clockInTable v-if="tabIndex==8"></clockInTable>
      <div style="height: 700px; width: 100%" v-if="tabIndex == 5">
        <iframe style="height: 100%; width: 100%" :src="htmlUrl"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid'
import clockInTable from '../components/clockInTable/clockInTable.vue'
import tagList from "../components/tag-list/tag-list.vue"
import taskCalendar from "../components/task_calendar/calendar.vue"
import surmount from "../components/surmount/surmount.vue"
import specialTrain from "../components/specialTrain/specialTrain.vue"
import instructions from "../components/instructions/instructions.vue"
import { INITIAL_EVENTS, createEventId } from './event-utils'
import { getData,instroSave,searchCustomers,getDayReservationFun,moveAppointmentCard,getServiceProductList,
  delAppointmentInfo,checkMchUserMobile,editAppointmentInfo, getMonthReservation, myCustomerHomepage, addServiceProductTag} from './utils/API/api'
import store from './store';
import SchoolVue from '../components/schoolVue/schoolVue.vue'
import Course from '../components/course/course.vue'
import Scheduling from '../components/Scheduling/scheduling.vue'
export default {
  name: "App",
  components: {
    tagList,
    taskCalendar,
    surmount,
    specialTrain,
    SchoolVue,
    clockInTable,
    Scheduling,
    Course,
    instructions
  },

  data: function() {
    return {
      tabIndex:1,
      showSelectShop:false,
      shopValue:null,
      options1:[],
      adtableData: [],
      show_calendar:true,
      htmlUrl:'',
      checked:null,
      user_list:null,
      search_value:null,
      service_arr:null,
      service_user_id: null,
      userName: null,
      consumerId: null,
      userClickIndex:0,
      user_phone: null,
      userConsumerId: null,
      consumerData:null,
      serviceData:[],
      cardData: null,
      editId: null,
      remark:null,
      list_data: [],
      popMonth:'',
      popDay:'',
      popYear:'',
      popDate:'',
      isType:false,
      visible: false,
      cMonth: 0,
      cDay: null,
      cDate: null,
      currentEvents: [],
      newId: null,
      codeImage: null,
      showEditTagPop:false,
      serviceTxt:'',
      ws:null,
      showAddPop:false,
      showSelectServicePop: false,
      editTagIndex:null,
      date_list: null,
			selectType: null,
      nowChangeStatus:null,
      isCollapse: true,
			selectItem: null,
      selectConsumerStatus:null,
      serviceList:[],
			dateShow: false,
      clickIndex:0,
      showCusPop:false,
      customer_arr:[],
      value1:null,
      customer_list:[],
      showSearch:false,
      pageNo:1,
      showFun:false,
      cu_name:null,
      cu_id:null,
      userPop:null,
      is_boss:1,
      tooltipText:''
    }
  },
  mounted() {
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    tapmenu(){
      this.isCollapse = !this.isCollapse
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    showTooltip(text) {
      console.log(text)
      this.tooltipText = text;
    },
    hideTooltip() {
      this.tooltipText = '';
    },

    closeCusPop(){
				this.showCusPop = false
			},
      changeTime(e){
        this.popYear = e.substr(0,4)
        this.popMonth = e.substr(5,2)
        this.popDay = e.substr(8,2)
        this.popDate = e
      },
    tapUserData(data){
				this.userName = data.user_name
				this.user_phone = data.mobile
				this.userConsumerId = data.id
				this.isType = data.consumerType
				this.selectConsumerStatus = true
				this.customer_list = []
			},
    // 获取数据
    getDataList() {
      let that = this
      let dates = this.cYear+'-'+this.cMonth+'-'+this.cDay
      getData({ date: dates, shop_id: this.value1})
        .then(response => {
          if(response.data.code == 401){
            return
          }
          this.codeImage = null
          let arr = response.data.data.employeeList.map((x)=>{
            if(x.class){
              x.name = x.name+`(${x.class||''})`
            }
            return {
              id:x.user_id,
              title:x.name,
              businessHours: {
                startTime: '08:00',
                endTime: '22:00'
              }
            }
          })
          this.calendarOptions.resources = arr.reverse()
          if(this.ws){
            this.ws.close()
          }
        })
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    dateConfirm(e,data,index,type) {
				if (type == 1) {
					this.cardData.service_arr[index].s_time = e
				} else {
					if (!this.cardData.service_arr[index].s_time) {
            this.$message({
              message: '请先选择开始时间',
              type: 'warning'
            });
						return
					}
					if (this.changeStrToMinutes(e) <= this.changeStrToMinutes(this.cardData.service_arr[index].s_time)) {
            this.$message({
              message: '结束时间不能小于等于开始时间',
              type: 'warning'
            });
						return
					}
					this.cardData.service_arr[index].e_time = e
				}
				console.log(this.cardData);
		},
    // 小时转分钟
			changeStrToMinutes(str) {
				var arrminutes = str.split(":");
				if (arrminutes.length == 2) {
					var minutes = parseInt(arrminutes[0]) * 60 + parseInt(arrminutes[1]);
					return minutes;
				} else {
					return 0;
				}
			},
      clearInput(num) {
				this.userName = ''
				this.user_phone = ''
				this.selectConsumerStatus = false
			},
      inputIng(e){
				let that = this
				clearTimeout(that.inputTimer);

				// 设置新的定时器
				that.inputTimer = setTimeout(() => {
				  // 在定时器结束时执行函数逻辑
				  let param = {
				      name: this.userName,
				      shop_id: '',
				      page: 1,
				      limit: 15
				  };
          searchCustomers(param)
          .then(res => {
            if (res.data.code == 0) {
				          that.customer_arr = res.data.data.data
                  that.showCusPop = true
                  if(!this.userName){
                    that.customer_arr = []
                  }
				      }
          })
          .catch(error => {
            this.$message.error('调用接口失败！')
          })
				}, 300);
			},
      changeDataFun(e){
        this.is_boss = e.is_boss
      },
      changeUrl(e){
        this.htmlUrl = e
      },
    toSearch(){
      this.inputSearch()
			this.showSearch = true
      this.$nextTick(() => {
　　　　　　　　　　　　// 进入nexTick
            var bady = document.getElementById("scroll_list");   // 获取滚动条的dom
            // console.log("距顶部"+scrollTop+"可视区高度"+windowHeight+"滚动条总高度"+scrollHeight);
            bady.onscroll = () => {
　　　　　　　　　　　　　　 // 获取距离顶部的距离
                var scrollTop = bady.scrollTop;
                // 获取可视区的高度
                var windowHeight = bady.clientHeight;
                // 获取滚动条的总高度
                var scrollHeight = bady.scrollHeight;
                if(scrollTop+windowHeight>=scrollHeight-100){
                    // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
                    this.inputSearch()
                }
            }
        });
    },
    tapMenuItem(type){
      this.tabIndex = type
    },
    selectConsumer(data) {
				this.consumerData = data
				this.userName = data.user_name
				this.userConsumerId = data.id
				this.user_phone = data.mobile
        this.isType = data.consumerType
				this.showSearch = false
				this.selectConsumerStatus = true
			},
      searchFun(){
        this.pageNo = 1
        this.customer_list= []
        this.inputSearch()
      },
    inputSearch() {
      if(this.showFun){
        return
      }
      setTimeout(() => {
        this.showFun = false
      }, 500);
      let that = this
      this.showFun = true
      let param = {
        name: this.search_value,
        shop_id: this.value1,
        page:this.pageNo,
        limit:15
      }
      searchCustomers(param)
        .then(res => {
          if(that.pageNo == 1){
            that.customer_list = res.data.data.data
          } else{
            that.customer_list = that.customer_list.concat(res.data.data.data)
          }
          that.pageNo++
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
    },
    // 封装的日期时间格式化函数
    formatDateTime (dateTimeString) {
        const inputDate = new Date(dateTimeString);
        const chinaTime = new Date(inputDate.getTime());
        return `${this.padZero(chinaTime.getHours())}:${this.padZero(chinaTime.getMinutes())}`;
    },
    //补零函数，用于确保单个数字格式为两位数
    padZero(num) {
        return num.toString().padStart(2, '0');
    },
    selectService() {
				this.serviceTxt = ''
				this.showSelectServicePop = true
			},
      tapServiceTag(item) {
				if (item.status) {
					this.serviceData.splice(this.serviceData
						.findIndex(x => x.product_id == item.product_id), 1)
					this.serviceList.map((x) => {
						if (x.product_id == item.product_id) {
							x.status = false
						}
					})
				} else {
					this.serviceData ? '' : this.serviceData = []
					this.serviceData.push(item)
					this.serviceList.map((x) => {
						if (x.product_id == item.product_id) {
							x.status = true
						}
					})
				}
			},
    delUser(){
      let that = this
      that.$confirm('确定要删除该数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          let param = {
              appoint_id: that.editId
            }
            console.log(11111)
            delAppointmentInfo(param)
          .then(res => {
            console.log(666)
            that.$message.success('删除成功')
            that.calendarOptions.events.splice(that.calendarOptions.events.findIndex(h=>h.appoint_id == that.editId), 1)
            that.getDayReservation(that.cYear, that.cMonth, that.cDay)
            that.showAddPop = false
            that.showEditTagPop = false
          })
          .catch(error => {
            that.$message.error('调用接口失败！')
          })
        }).catch(() => {
          that.$message({
            type: 'info',
            message: '已取消'
          });
        })
    },
    savePopData() {
				if (this.user_phone && !this.selectConsumerStatus) {
					this.searchPhone()
				} else {
					this.submit()
				}
			},
      searchPhone() {
				let that = this
				let param = {
					mobile: this.user_phone,
				};
        checkMchUserMobile(param)
        .then(res => {
          if (res.data.data) {
							that.cu_name = res.data.data.user_name
							that.cu_id = res.data.data.user_id
							that.userPop = true
						} else {
							that.submit()
						}
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
			},
      submitCard() {
				this.userName = this.cu_name
				this.userConsumerId = this.cu_id
				this.submit()
			},
			submit() {
				let that = this
				let param = {
					color: "#000",
					startDay: this.popDay,
					endDay: this.popDay,
					year: this.popYear,
					month: this.popMonth,
					date: this.popDate,
					service_arr: this.service_arr,
					is_new: !this.checked ? 1 : 0,
					service_user_id: this.service_arr[0].user_id,
					consumerName: this.userName,
					mobile: this.user_phone || null,
					shop_id: this.value1,
					consumerId: this.userConsumerId,
					remark: this.remark,
					appoint_id: this.editId,
					del_service_ids: this.cardData.del_service_ids || null
				};
        editAppointmentInfo(param)
        .then(res => {
          this.$message.success('保存成功！')
						that.userPop = false
						that.getDayReservation(that.cYear, that.cMonth, that.cDay)
            that.showEditTagPop = false
            that.showAddPop = false
        })
        .catch(error => {
          this.$message.error(res.data.message)
        })
			},
    delFun(item) {
				let that = this
        this.$confirm('确定要删除该数据？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          if (item.id !== -1) {
								that.cardData.del_service_ids ? '' : that.cardData.del_service_ids = []
								that.cardData.del_service_ids.push(item.service_id)
							}
							console.log(item)
							that.cardData.service_arr.splice(that.cardData.service_arr.findIndex(x => x
								.service_id == item.service_id), 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });
        });
			},
    editService(index, item) {
				this.editTagIndex = index
				this.serviceData = item.product_arr
				this.serviceList.map((x) => {
					item.product_arr.map((a) => {
						if (x.product_id == a.product_id) {
							x.status = true
						}
					})
				})
				this.showEditTagPop = true
			},
      confirmServiceTag() {
        let obj={
          mch_id:store.state.mch_id,
          product_name: this.serviceTxt
        }
        addServiceProductTag(obj)
        .then(res => {
          this.showSelectServicePop = false
          this.serviceList.push(res.data.data)
          this.$message.success('添加成功')
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
            },
    closeService(item) {
				this.serviceData.splice(this.serviceData
					.findIndex(x => x.product_id == item.product_id), 1)
				this.serviceList.map((x) => {
					console.log(x, item)
					if (x.product_id == item.product_id) {
						x.status = false
					}
				})
			},
      userConfirm(e, data, index) {
				this.cardData.service_arr[index].name = e.name
				this.cardData.service_arr[index].service_user_id = e
			},
    addUser() {
				console.log(this.cardData.service_arr)
				if (!this.cardData.service_arr) {
					this.cardData.service_arr = []
				}
				this.cardData.service_arr.push({
					e_time: null,
					s_time: null,
					id: -1,
					service_user_i: -1,
					service_id: -1,
					product_arr: []
				})
			},
      eventResize(event){
        console.log(event)
        let that = this
        let newData
        newData = JSON.parse(JSON.stringify(event.event.extendedProps))
        let sT = that.formatDateTime(event.event.startStr)
        let eT = that.formatDateTime(event.event.endStr)
        newData.s_time = sT
        newData.e_time = eT
        newData.date = this.cYear + '-' + this.cMonth + '-' + this.cDay
        newData.resourceId = newData.service_user_id
        console.log(newData)
        this.changeData(newData)
      },
      eventDrop(event){
        console.log(event)
        if(event.event.extendedProps.confirmation == 2 || event.event.extendedProps.confirmation == 3 ){
          return
        }
        let that = this
        let newData
        newData = JSON.parse(JSON.stringify(event.event.extendedProps))
          let sT = that.formatDateTime(event.event.startStr)
          let eT = that.formatDateTime(event.event.endStr)
          newData.s_time = sT
          newData.e_time = eT
          newData.date = this.cYear + '-' + this.cMonth + '-' + this.cDay
        if(!event.newResource){
          newData.resourceId = newData.service_user_id
        } else {
          newData.resourceId = event.newResource.id
          newData.service_user_id = event.newResource.id
        }
        console.log(newData)
        this.changeData(newData)
      },
    changeData(obj){
      this.loading()
      moveAppointmentCard(obj)
      .then(res => {
        this.getDayReservation(this.cYear, this.cMonth, this.cDay)
      })
      .catch(error => {
        this.$message.error('调用接口失败！')
      })
    },
    loading: function() {
          var loading = this.$loading({
              lock: true,
              // spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.2)"
          });
          setTimeout(() => {
              loading.close();
          }, 2000);
      },
    getDayReservation(year, month, day) {
				let that = this
				let param = {
					user_id: that.user_id,
					date: year + '-' + month + '-' + day,
					shop_id: this.value1
				};
        getDayReservationFun(param)
        .then(res => {
          that.calendarOptions.events.splice(that.calendarOptions.events.findIndex(h=>h.appoint_id == -1), 1)
          const result = Object.values([...that.calendarOptions.events, ...res.data.data].reduce((result, {
							service_id,
							...rest
						}) => {
							result[service_id] = {
								...(result[service_id] || {}),
								service_id,
								...rest
							};
							return result;
						}, {}))
            result.map((n)=>{
              n.constraint ={
                startTime: '08:00',
                endTime: '22:00'
              }
              n.editable = n.confirmation ==2 || n.confirmation ==3?false:true
              n.backgroundColor = n.confirmation ==2 || n.confirmation ==3?'#cccccc':'#2195f3'
              n.borderColor = n.confirmation ==2 || n.confirmation ==3?'#cccccc':'#2195f3'
              n.tagStr = ''
              n.service_arr.map((c)=>{
                c.tagstr = ''
                c.product_arr.map((v)=>{
                  v.amount?c.tagstr += v.product_name+'('+v.amount+')'+'+': c.tagstr += v.product_name+'+'
                })
                c.tagstr = c.tagstr.substring(0, c.tagstr.length-1)
              })
              n.service_arr.map((c)=>{
                n.tagStr += c.tagstr + '+'
              })
              n.tagStr = n.tagStr.substring(0, n.tagStr.length-1)
              console.log(n.tagStr)
              n.resourceId = n.service_user_id
              n.start = n.date+'T'+n.s_time+':00'+'+'+'08:00'
              n.end = n.date+'T'+n.e_time+':00'+'+'+'08:00'
              n.title = n.consumerName
            })
            this.calendarOptions.events = result
        })
        .catch(error => {

        })
			},
      myCustomerHomepageFun() {
        let that = this
        myCustomerHomepage(this.is_boss)
        .then(res => {
          if (res.data.data.mchStore.length == 0) {
            that.$message.error('您还没有门店，请先添加门店！')
            return
						} else {
							let a = res.data.data.mchStore[0].mch_name
							let b = res.data.data.mchStore[0].store_name
							that.shopTxt = `${a}(${b})`
							that.value1 = res.data.data.mchStore[0].id
							// this.reservEmployeeList()
              console.log(that.value1, '0oooooooooo')
						}
						that.options1 = res.data.data.mchStore.map(item => {
							return {
								value: item.id,
								label: `${item.mch_name}(${item.store_name})`
							}
						})
						// this.getStaffClassList()
            console.log(that.options1)
						this.getDayReservation(this.cYear, this.cMonth, this.cDay)
            this.getDataList()
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
			},
    handleEventClick(clickInfo) {
      console.log(clickInfo, '22222')
      this.formatTime(clickInfo.event.start)
      if(clickInfo.event.extendedProps.consumerId){
          this.selectConsumerStatus = true
        }
      // 双击 弹出修改编辑
      this.service_arr = clickInfo.event.extendedProps.service_arr
      this.service_user_id = clickInfo.event.extendedProps.service_user_id
      this.userName = clickInfo.event.extendedProps.consumerName
      this.consumerId = clickInfo.event.extendedProps.consumerId
      this.user_phone = clickInfo.event.extendedProps.mobile
      this.userConsumerId = clickInfo.event.extendedProps.consumerId
      this.isType = clickInfo.event.extendedProps.consumerType
      this.remark = clickInfo.event.extendedProps.remark
      this.cardData = clickInfo.event.extendedProps
      this.editId = clickInfo.event.extendedProps.appoint_id
      this.user_list = this.calendarOptions.resources
      this.showAddPop = true
      this.getServiceList()
    },
    getServiceList() {
				let param = {
					mch_id: store.state.mch_id
				};
        getServiceProductList(param)
        .then(res => {
          this.serviceList = res.data.data.data
						this.serviceList.map((x) => {
							x.status = false
						})
        })
        .catch(error => {
          this.$message.error('获取失败！')
        })
			},
      confirmEditTag() {
				this.cardData.service_arr[this.editTagIndex].product_arr = JSON.parse(JSON.stringify(this.serviceData))
				this.serviceList.map((x) => {
					x.status = false
				})
				this.showEditTagPop = false
			},
      tapType(data) {
			    let that = this
			    let param = {
			        id: this.userConsumerId,
			        consumerType: data
			    };
          instroSave(param)
          .then(res => {
			        if (res.data.code == 0) {
			            that.isType = data
			            // that.getData()
			        }
          })
          .catch(error => {
            this.$message.error('获取失败！')
          })
			},
    getMonthReservationFun(year, month) {
				let param = {
					user_id: this.user_id,
					query_month: month,
					query_year: year,
					date: year + '-' + month,
					shop_id: this.value1
				};
        getMonthReservation(param)
        .then(res => {
          let result = res.data.data
          result.map((n)=>{
              n.constraint ={
                startTime: '08:00',
                endTime: '22:00'
              }
              n.resourceId = n.service_user_id
              n.start = n.date+'T'+n.s_time+':00'+'+'+'08:00'
              n.end = n.date+'T'+n.e_time+':00'+'+'+'08:00'
              n.title = n.consumerName
            })
            console.log(result)
            this.calendarOptions.events = result
        })
        .catch(error => {
          this.$message.error('获取失败！')
        })
			},
    formatTime(date){
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate()
      this.cYear = year
      this.popMonth = this.padZero(month)
      this.popDate = year+'-'+this.padZero(month)+'-'+this.padZero(day)
      this.cMonth = this.padZero(month)
      this.cDay = this.padZero(day)
      let days = date.getDay()
			days == 0 ? this.cDate = '日' : days == 1 ? this.cDate = '一' : days == 2 ? this.cDate = '二' : days == 3 ? this
				.cDate = '三' : days == 4 ? this.cDate = '四' : days == 5 ? this.cDate = '五' : this.cDate = '六'
    },
    handleEvents(events) {
      this.currentEvents = events
    }
  }
}
</script>

<style lang='scss' scoped>
.qcodeDiv {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}
.seachUserListPop{
		position: absolute;
		height: 135px;
    overflow: hidden;
		left: 0;
		right: 0;
		top: 40px;
		background-color: #ffffff;
		z-index: 100;
		border-radius: 0 0 6px 6px;
		box-shadow: 2px 4px 15px 4px #d0d0d0;
		.useritem{
			padding: 14px 10px;
			font-size: 13px;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #f6f6f6;
		}
	}
    .service_item {
        height: 32px;
        padding: 5px 10px;
        background-color: #f5f5f5;
        color: #333;
        border-radius: 4px;
        margin: 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        image {
            height: 15px;
            width: 15px;
            margin-left: 5px;
        }
    }
.pop {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.8;
  z-index: 100;
}
.addService {
		min-width: 88px;
		max-width: 88px;
		height: 30px;
		display: flex;
		font-size: 12px;
		align-items: center;
		justify-content: center;
		margin-left: 10px;
		border-radius: 34px;
		border: 1px solid #ddd;
		color: #222;
	}
  .service_item {
		height: 20px;
		padding: 5px 15px;
		background-color: #f5f5f5;
		color: #333;
		border-radius: 4px;
		margin: 8px;
		font-size: 12px;
		display: flex;
		align-items: center;
		justify-content: center;
    position: relative;
		img {
			height: 10px !important;
			width: 10px !important;
			margin-left: 5px !important;
      top: 3px !important;
      right: 3px !important;
		}
	}
.imgDiv {
  height: 300px;
  z-index: 100;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  flex-direction: column;
}
/deep/ .fc-scrollgrid{
  --fc-today-bg-color:#ffffff
}
/deep/ .fc-today-button{
  right: 170px !important;
}
.cus_type {
	    height: 30px;
	    width: 70px;
	    border: 1px solid #333;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    border-radius: 10rpx;
	}

	.cus_act {
	    background-color: #95631e;
	    border: 1px solid #95631e;
	    color: #ffffff;
	}
.codeImg {
  margin-bottom: 20px;
}
.codeView {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
h2 {
  margin: 0;
  font-size: 16px;
}


b {
  /* used for event dates/times */
  margin-right: 3px;
}
/deep/ .fc-timegrid-slots tr {
  height: 30px;
}
.demo-app {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}
.tooltip{
  height: 49px;
  width: 30px;
}
.service_input {
		display: flex;
		background-color: #f5f5f5;
		border-radius: 6px;
		width: 100%;
	}

	.act {
		background-color: var(--main-color) !important;
		color: #fff !important;
		opacity: .7;
	}

.demo-app-sidebar {
  width: 300px;
  line-height: 1.5;
  background: #eaf9ff;
  border-right: 1px solid #d3e2e8;
}

.demo-app-sidebar-section {
  padding: 2em;
}
.demo-app-main {
  flex-grow: 1;
  padding: 0.7em;
}
.card_cont {
		display: flex;
		flex-direction: column;
		align-items: center;

		.card_foot {
			display: flex;
			width: 100%;
			margin-top: 10px;
			border-top: 1px solid #ddd;

			.foot_btn {
				height: 46px;
				flex: 1;
				display: flex;
				align-items: center;
				justify-content: center;
				color: var(--main-color);
			}
		}

		.tip {
      margin-top: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-bottom: 20px;
		}

		.card_title {
			font-size: 17px;
			font-weight: 600;
			margin-top: 20px;
		}

		.card_cancle {
			border-right: 1px solid #ddd;
		}

		.card_head {
			margin-bottom: 20px;
		}

		.txt {}
	}
.fc {
   margin: 0 auto;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 150px;
  min-height: 400px;
}
#fc_div /deep/ .fc-license-message {
  display: none !important;
}
#fc_div .fc-license-message {
  display: none !important;
}
/deep/ .fc-timegrid-col-events{
  margin: 0 !important;
}
/deep/ .el-dialog__body{
  padding: 0 20px 20px 20px;
}
/deep/ .fc-v-event{
  opacity: .8;
}
/deep/ .fc-event-main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.edit_service_tag {
		flex-wrap: wrap;
		display: flex;
		border-radius: 8px;
	}
.pop_foot {
		position: absolute;
		bottom: 0;
		height: 50px;
		width: 100%;
		background-color: #f5f5f5;
		display: flex;
		color: #ffffff;
		justify-content: center;
    left: 0;
		align-items: center;

		.btns {
			flex: 1;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-weight: 600;

			img {
				height: 30px;
				width: 30px;
				margin: 0 0 5px 0;
			}
		}
	}
  .user_list {
		padding: 20px;
    overflow: hidden;
    overflow-y: auto;
    height: 400px;
		.user_search_input {
			padding: 20px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			justify-content: center;

			.searchBtn {
				width: 80px;
				height: 40px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: 7px;
				background-color: var(--main-color);
				border: 1px solid var(--main-color);
				color: #fff;
				margin-left: 10px;
			}

			input {
				border-radius: 6px;
				border: 1px solid #ddd;
				height: 20px;
				font-size: 13px;
				padding: 10px;
			}
		}
    .edit_service_tag {
        flex-wrap: wrap;
        display: flex;
        border-radius: 8px;
    }

    .edit_title_box {
        height: 60px;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        align-items: center;
        justify-content: center;
    }

    .service_input {
        display: flex;
        background-color: #f5f5f5;
        border-radius: 6px;
        width: 100%;
    }

    .act {
        background-color: var(--main-color) !important;
        color: #fff !important;
        opacity: .7;
    }

    .service_item {
        height: 32px;
        padding: 5px 10px;
        background-color: #f5f5f5;
        color: #333;
        border-radius: 4px;
        margin: 8px;
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;

        image {
            height: 15px;
            width: 15px;
            margin-left: 5px;
        }
    }
		.user_list_item {
			padding: 15px;
			box-shadow: 0 0 3px 0px var(--main-color);
			border-radius: 8px;
			margin-bottom: 20px;

			.name {
				font-weight: 600;
				margin-bottom: 10px;
			}

			.phone {
				font-weight: 600;
			}
		}
	}
  .actMenu{
    transform: rotate(180deg)
  }
.editData {
		.user_cont_view {
			padding: 10px 5px 10px 5px;
			border-radius: 8px;
			border: 2px solid var(--main-color);
			margin-bottom: 20px;
		}

		.edit_section {
			display: flex;
			font-size: 13px;
			flex-direction: column;

			.head_time {
				font-size: 17px;
				font-weight: 600;
				font-weight: 600;
			}

			.edit_title {
				font-size: 13px;
        margin: 10px 0;
				width: 100%;
				font-weight: 600;
				display: flex;
				align-items: center;
			}

			.edit_service {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: center;
				flex: 1;
				.edit_tag {
					margin: 5px;
				}
			}

			.addUser {
				width: 100%;
				height: 40px;
				display: flex;
				font-size: 15px;
				font-weight: 600;
				align-items: center;
				justify-content: center;
				border-radius: 4px;
				border: 1px solid var(--main-color);
				color: var(--main-color);
			}
			.edit_time_data {
				display: flex;
				width: 100%;
				justify-content: flex-start;
				align-items: center;
				font-size: 12px;
				position: relative;

				input {
					font-weight: 600 !important;
				}

				.edit_time {
					display: flex;
				}

				.edit_user_data {
					width: 100%;
					position: relative;
					flex: 1;
				}

				.edit_textarea {
					border: 1px solid var(--main-color);
					width: 260px;
					border-radius: 8px;
					height: 100px;
					padding: 10px;
				}

				.stime {
					width: 100px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
				}

				.search_user {
					width: 70px;
					height: 35px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
					background-color: var(--main-color);
					border: 1px solid var(--main-color);
					color: #fff;

					img {
						height: 20px;
						width: 20px;
						margin-right: 6px;
					}
				}

				.userview {
					flex: 1;
					height: 40px;
					display: flex;
					align-items: center;
					position: relative;
					justify-content: center;
					border-radius: 4px;
					padding: 0 10px;
					font-size: 13px;
          border: 1px solid var(--main-color);
				}

				.active {
					background-color: #f5f5f5;
				}

				img {
					height: 15px;
					width: 15px;
					position: absolute;
					right: 5px;
					top: 8px;
				}

				.etime {
					width: 100px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 4px;
				}

				.line {
					margin: 0 5px;
				}
			}
		}
	}
</style>
