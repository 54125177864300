<template>
  <el-dialog
    @opened="editorInit()"
    @closed="editorClear()"
    :lock-scroll="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    class="studyedit"
    @close="close"
  >
    <el-form ref="form" :model="form" :rules="rules" label-width="auto">
      <el-row :gutter="20">
        <!-- <el-col :sm="12" :xs="24" >
          <el-form-item label="请选择商家" prop="title">
            <el-select v-model="form.mch_id" placeholder="请选择商家" filterable style="width: 100%;">
              <el-option
                v-for="(item, index) in mch_list"
                :key="index"
                :label="item.name"
                :value="item.id">

              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <el-col :sm="12" :xs="24" >
          <el-form-item label="频道权限类型">
            <!-- 简化下逻辑，该值编辑的时候不允许修改 -->
            <el-select v-model="form.rule_type" @change="selectChange" clearable style="width: 100%">
              <!-- <el-option v-for="item in rule_list" :key ="item.id" :label="item.name" :value="item.id"></el-option> -->
              <el-option  key ="1" label="全员" :value="1"  ></el-option>
              <el-option  key ="2" label="老板" :value="2"></el-option>
              <el-option  key ="3" label="干部" :value="3"></el-option>
              <el-option  key ="4" label="指定人员" :value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
          <el-col v-if="form.rule_type == 4">
            <el-form-item label="选择成员">
                <el-button type="primary" @click="addUser = true">+添加成员</el-button>
                <div class="btnBox">
                    <div class="btns" v-for="(i,j) in showUserArr" :key="j">
                        <img style="top: -5px;right: -5px;position: absolute;" @click="delUser(i)" src="../../src/static/image/del.png"/>
                        {{ i.label }}
                    </div>
                </div>
            </el-form-item>
          </el-col>
          <el-dialog title="选择成员" append-to-body :visible.sync="addUser">
            <el-tree
                v-if="addUser"
                :props="defaultProps"
                :load="loadNode"
                lazy
                show-checkbox
                ref="tree"
                :check-strictly="true"
                :default-expanded-keys="[1]"
                node-key="value"
                :default-checked-keys="form.user_ids"
                @check-change="handleCheckChange">
                </el-tree>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="addUser = false">取 消{{ form.user_ids }}</el-button>
                    <el-button type="primary" @click="confirmAdd">确 定</el-button>
                </span>
          </el-dialog>
        <el-col :sm="12" :xs="24">
          <el-form-item label="频道名称" prop="title">
            <el-input v-model="form.title" placeholder="请填写频道名称"/>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="开始时间" prop="start_time">
            <el-date-picker
              v-model="form.start_time"
              align="left"
              type="datetime"
              placeholder="开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="结束时间" prop="url">
            <el-date-picker
              v-model="form.end_time"
              align="left"
              type="datetime"
              placeholder="结束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              :editable="false"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="订阅量基数" prop="price">
            <el-input
              v-model="form.subscribe_num"
              placeholder="请填写订阅量基数"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="收藏量基数" prop="price">
            <el-input
              v-model="form.collection_num"
              placeholder="请填写收藏量基数"
            />
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="是否首页显示" prop="status">
            <el-select
              v-model="form.frontIndex_show"
              filterable
              placeholder="请选择是否首页显示"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in frontIndex_showList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="频道类型" prop="status">
            <el-select
              v-model="form.channel_type"
              filterable
              placeholder="请选择频道类型"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in channel_typeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :sm="12" :xs="24">
          <el-form-item label="是否可见" prop="status">
            <el-select
              v-model="form.showstatus"
              filterable
              placeholder="请选择是否可见"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in showstatusList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="状态" prop="status">
            <el-select
              v-model="form.status"
              filterable
              placeholder="请选择状态"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in statusList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :sm="12" :xs="24">
          <el-form-item label="排序" prop="sort">
            <el-input v-model="form.sort"/>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="频道插图" prop="picture">
            <el-upload
              class="avatar-uploader"
              action="#"
              :http-request="httpRequest1"
              :data="uploadData"
              :show-file-list="false"
              :before-upload="beforeAvatarUpload"
            >
              <img
                v-if="form.cate_picture"
                :src="form.cate_picture"
                class="avatar bannerImg"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <div slot="tip" class="el-upload__tip">
                （图片大小不超过200M，支持JPG、JPEG及PNG格式）
              </div>
            </el-upload>

          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="简介" prop="sort">
            <el-input v-model="form.description"/>
          </el-form-item>
        </el-col>
        <el-col>
          <el-form-item label="详情" prop="sort">
            <div ref="editor" id="editor"></div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import {courseCateSave, myCustomerHomepage,getMchEmployeeList} from "../../src/utils/API/api";
import {upload} from "../../src/utils/API/api";
import axios from "axios";
import E from "wangeditor";
import store from '../../src/store';
export default {
  name: "StudyEdit",
  components: {},
  data() {
    return {
      showFileList: false,
      form: {
        tags: {},
        cate_picture: "",
        price: "0",
        subscribe_num: 0,
        sort: 100,
        scope: {
          setting: [],
          input_tips: "默认全公司",
          value: {
            users: [],
            strucs: [],
          },
        },
     //   rule_typea: "",
        rule_type: ""
      },
      editor: {},
      addtagVisible: false,
      newTagValue: "",
      pricetypeList: [
        {id: 0, title: "一次性收费"},
        {id: 1, title: "按年收费"},
      ],
      sectionList: [
        {value: 1, label: "内部"},
        {value: 2, label: "外部"},
      ],
      statusList: [
        {label: "有效", value: 1},
        {label: "失效", value: 0},
      ],
      showstatusList: [
        {label: "显示", value: 1},
        {label: "隐藏", value: 0},
      ],
      frontIndex_showList: [
        {label: "显示", value: 1},
        {label: "隐藏", value: 0},
      ],
      channel_typeList: [
        {label: "课程类", value: 1},
        {label: "红黑榜", value: 2},
        {label: "总结心得分享", value: 3},
        {label: "短视频", value: 4},
        {label: "企业内刊", value: 5},
        {label: "语音收听", value: 6},
        {label: "品相赋能", value: 7},
        {label: "文档工具类", value: 8},
      ],
      userstatusList: [
        {label: "激活", value: 1},
        {label: "试用期", value: 3},
      ],
      rules: {
        title: [{required: true, trigger: "blur", message: "请输入名称"}],
      },
      title: "",
      addUser:false,
      defaultProps: {
            label: 'label',
            value: 'value',
            children: "children",
            disabled: function (data, node) {//带子级的节点不能选中
            if (node.level == 1) {
                return true
            } else {
                return false
            }
            }
        },
        options1:[],
        is_boss:1,
      dialogFormVisible: false,
      showUserArr:[],
      upload_parmas: {
        authKey: "123",
      },
      BASE_URL: "",
      uploadForm: {
        uploadFile: "",
      },
      uploadData: {
        type: '2'
      },
      mch_list: [],
      rule_list: [
        {label: "老板", value: 1},
        {label: "全员", value: 0},
      ],
    };
  },
  created() {
    this.crmFileSaveOSSUrl = window.BASE_URL + "admin/AliOss/upload";
    this.myCustomerHomepageFun()
    this.is_boss = localStorage.getItem('info').is_boss
  },
  computed: {
    crmFileSaveUrl() {
      return crmFileSaveUrl;
    },
    httpHeader() {
      return {
        authKey: axios.defaults.headers["authKey"],
        sessionId: axios.defaults.headers["sessionId"],
      };
    },
  },
  mounted() {
  },
  methods: {
    myCustomerHomepageFun() {
        let that = this
        myCustomerHomepage(this.is_boss)
        .then(res => {
          if (res.data.data.mchStore.length == 0) {
            that.$message.error('您还没有门店，请先添加门店！')
            return
			}
            that.options1 = res.data.data.mchStore.map(item => {
                return {
                    value: item.id,
                    label: `${item.mch_name}(${item.store_name})`
                }
            })
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
	},
  confirmAdd(){
        let arr = this.$refs.tree.getCheckedNodes().map((x)=>{
            return x.value
        })
        this.showUserArr = this.$refs.tree.getCheckedNodes()
        this.addUser = false
        console.log(arr, 'eweww')
        this.form.user_ids = arr
      },
      delUser(data){
        this.form.user_ids.splice(this.form.user_ids.findIndex(x=>x.value == data.value), 1)
        this.showUserArr.splice(this.showUserArr.findIndex(x=>x.value == data.value), 1)
      },
    showEdit(row) {
      if (!row.id) {
        this.title = "添加频道";
        this.redirectUrlList = [];
      } else {
        this.title = "编辑频道";
        this.form = Object.assign({}, row);
        this.form.cate_pictures = [row.cate_picture];
      }
      this.form.mch_id = store.state.mch_id
      this.dialogFormVisible = true;
    },
    close() {
      this.$refs["form"].resetFields();
      this.form = this.$options.data().form;
      this.dialogFormVisible = false;
    },
    handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate);
      },
      loadNode(node, resolve){
        let that = this
        if (node.level === 0) {
            return resolve(this.options1)
        }
        console.log(node, resolve)
        if (node.level === 2) {
            return resolve([])
        }
        if (node.level === 1) {
            getMchEmployeeList({mch_store_id:node.data.value})
            .then(res => {
                let arr = res.data.data.map(item => {
                    return {
                        value: item.user_id,
                        label: item.user_name
                    }
                })
                resolve(arr)
            })
            .catch(error => {
            this.$message.error('调用接口失败！')
            })
        }
      },
    httpRequest1(obj) {
      var that = this;
      var formdata = new FormData();
      formdata.append("file", obj.file);
      formdata.append("name", obj.file.name);
      let loading = this.$loading({
        lock: true,
        text: '上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      upload(formdata)
        .then(function (res) {
          that.form.cate_picture = res.data.url;
          // that.uplSucInfo = res.data.url
          // that.form3.cover=res.data.url
        })
        .catch(function (err) {
          // console.log(err)
        }).finally(() => {
          loading.close()
      })
    },
    selectChange(e){
      console.log(e)
    },
    beforeAvatarUpload(file) {
      let types = ["image/jpeg", "image/jpg", "image/png"];
      const isImage = types.includes(file.type);
      const isLtSize = file.size / 1024 / 1024 < 200;
      if (!isImage) {
        this.$message.error("上传图片只能是JPG、JPEG及PNG格式!");
        return false;
      }
      if (!isLtSize) {
        this.$message.error("上传图片大小不能超过 200MB!");
        return false;
      }
      return isImage && isLtSize;
    },
    save() {
      var that = this;
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          await courseCateSave(this.form)
            .then((res) => {
              if (res.data.code == 0) {
                this.$message({
                  showClose: true,
                  message: res.data.data,
                  type: "success",
                });
                that.$emit("fetch-data");
                that.close();
              } else {
                this.$message({
                  showClose: true,
                  message: res.data.message,
                  type: "error",
                });
              }
            })
            .catch(() => {
            });
        } else {
          return false;
        }
      });
    },
    scopeChange(data) {
      this.form.scope = data;
    },
    PhotoUploadSuccess(res, file) {
      this.form.cate_picture = res.data.url;
      this.form.cate_pictures = [res.data.url];
      this.showFileList = false;
    },
    beforeStorePhotoUpload(file) {
      this.showFileList = true;
    },
    beforeRemove(file, fileList) {
    },
    editorInit() {
      var that = this;
      that.editor = new E(that.$refs.editor);

      that.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
        var formdata = new FormData();
        formdata.append("file", resultFiles[0]);
        let loading = this.$loading({
          lock: true,
          text: '上传中',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        upload(formdata)
          .then(function (res) {
            console.log(res.data, '233')
            insertImgFn(res.data.data.url);
          })
          .catch(function (err) {
          }).finally(() => {
            loading.close()
        })
      };
      that.editor.config.onchange = (html) => {
        that.form.showdetail = html;
      };
      that.editor.create();
      that.editor.txt.html(that.form.showdetail);
    },
    editorClear() {
      this.$refs.editor.innerHTML = "";
      this.editor.destroy();
      this.editor = null;
    },
    /**
     * 返回选中的标签数组
     */
    lableChange(event) {
      this.form.tags = event;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped>
.btnBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.btns{
    display: flex;
    position: relative;
    padding: 3px 10px;
    width: 60px;
    height: 25px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    justify-content: center;
    margin: 10px;
}
.studyedit {
  ::v-deep {
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: 100%;
    }

    .bannerImg {
      /*max-height: 120px;*/
      max-width: 100%;
      border-radius: 3px;
    }

    .avatar-uploader-icon {
      min-width: 80px;
      min-height: 80px;
      border: 1px solid #efefef;
      line-height: 80px;
      border-radius: 3px;
    }

    .el-tag {
      margin-right: 10px;
    }

    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }

    .input-new-tag {
      width: 90px;
      margin-right: 10px;
      vertical-align: bottom;
    }

    .tips {
      margin-left: 20px;
      color: #928e8e;
    }
  }
}
</style>
