var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "studyedit",
      attrs: {
        "lock-scroll": false,
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        "close-on-click-modal": false,
      },
      on: {
        opened: function ($event) {
          return _vm.editorInit()
        },
        closed: function ($event) {
          return _vm.editorClear()
        },
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { model: _vm.form, rules: _vm.rules, "label-width": "auto" },
        },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "频道权限类型" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { clearable: "" },
                          on: { change: _vm.selectChange },
                          model: {
                            value: _vm.form.rule_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "rule_type", $$v)
                            },
                            expression: "form.rule_type",
                          },
                        },
                        [
                          _c("el-option", {
                            key: "1",
                            attrs: { label: "全员", value: 1 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "2",
                            attrs: { label: "老板", value: 2 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "3",
                            attrs: { label: "干部", value: 3 },
                          }),
                          _vm._v(" "),
                          _c("el-option", {
                            key: "4",
                            attrs: { label: "指定人员", value: 4 },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.form.rule_type == 4
                ? _c(
                    "el-col",
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "选择成员" } },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: {
                                click: function ($event) {
                                  _vm.addUser = true
                                },
                              },
                            },
                            [_vm._v("+添加成员")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "btnBox" },
                            _vm._l(_vm.showUserArr, function (i, j) {
                              return _c(
                                "div",
                                { key: j, staticClass: "btns" },
                                [
                                  _c("img", {
                                    staticStyle: {
                                      top: "-5px",
                                      right: "-5px",
                                      position: "absolute",
                                    },
                                    attrs: {
                                      src: require("../../src/static/image/del.png"),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.delUser(i)
                                      },
                                    },
                                  }),
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(i.label) +
                                      "\n                  "
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "el-dialog",
                {
                  attrs: {
                    title: "选择成员",
                    "append-to-body": "",
                    visible: _vm.addUser,
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.addUser = $event
                    },
                  },
                },
                [
                  _vm.addUser
                    ? _c("el-tree", {
                        ref: "tree",
                        attrs: {
                          props: _vm.defaultProps,
                          load: _vm.loadNode,
                          lazy: "",
                          "show-checkbox": "",
                          "check-strictly": true,
                          "default-expanded-keys": [1],
                          "node-key": "value",
                          "default-checked-keys": _vm.form.user_ids,
                        },
                        on: { "check-change": _vm.handleCheckChange },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "dialog-footer",
                      attrs: { slot: "footer" },
                      slot: "footer",
                    },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function ($event) {
                              _vm.addUser = false
                            },
                          },
                        },
                        [_vm._v("取 消" + _vm._s(_vm.form.user_ids))]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.confirmAdd },
                        },
                        [_vm._v("确 定")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "频道名称", prop: "title" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请填写频道名称" },
                        model: {
                          value: _vm.form.title,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "title", $$v)
                          },
                          expression: "form.title",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "开始时间", prop: "start_time" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "left",
                          type: "datetime",
                          placeholder: "开始时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          editable: false,
                        },
                        model: {
                          value: _vm.form.start_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "start_time", $$v)
                          },
                          expression: "form.start_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "结束时间", prop: "url" } },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          align: "left",
                          type: "datetime",
                          placeholder: "结束时间",
                          "value-format": "yyyy-MM-dd HH:mm:ss",
                          editable: false,
                        },
                        model: {
                          value: _vm.form.end_time,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "end_time", $$v)
                          },
                          expression: "form.end_time",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "订阅量基数", prop: "price" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请填写订阅量基数" },
                        model: {
                          value: _vm.form.subscribe_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "subscribe_num", $$v)
                          },
                          expression: "form.subscribe_num",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "收藏量基数", prop: "price" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请填写收藏量基数" },
                        model: {
                          value: _vm.form.collection_num,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "collection_num", $$v)
                          },
                          expression: "form.collection_num",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否首页显示", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择是否首页显示",
                          },
                          model: {
                            value: _vm.form.frontIndex_show,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "frontIndex_show", $$v)
                            },
                            expression: "form.frontIndex_show",
                          },
                        },
                        _vm._l(_vm.frontIndex_showList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "频道类型", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择频道类型",
                          },
                          model: {
                            value: _vm.form.channel_type,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "channel_type", $$v)
                            },
                            expression: "form.channel_type",
                          },
                        },
                        _vm._l(_vm.channel_typeList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "是否可见", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择是否可见",
                          },
                          model: {
                            value: _vm.form.showstatus,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "showstatus", $$v)
                            },
                            expression: "form.showstatus",
                          },
                        },
                        _vm._l(_vm.showstatusList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "状态", prop: "status" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { filterable: "", placeholder: "请选择状态" },
                          model: {
                            value: _vm.form.status,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "status", $$v)
                            },
                            expression: "form.status",
                          },
                        },
                        _vm._l(_vm.statusList, function (item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { sm: 12, xs: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "排序", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.sort,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "sort", $$v)
                          },
                          expression: "form.sort",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "频道插图", prop: "picture" } },
                    [
                      _c(
                        "el-upload",
                        {
                          staticClass: "avatar-uploader",
                          attrs: {
                            action: "#",
                            "http-request": _vm.httpRequest1,
                            data: _vm.uploadData,
                            "show-file-list": false,
                            "before-upload": _vm.beforeAvatarUpload,
                          },
                        },
                        [
                          _vm.form.cate_picture
                            ? _c("img", {
                                staticClass: "avatar bannerImg",
                                attrs: { src: _vm.form.cate_picture },
                              })
                            : _c("i", {
                                staticClass:
                                  "el-icon-plus avatar-uploader-icon",
                              }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "el-upload__tip",
                              attrs: { slot: "tip" },
                              slot: "tip",
                            },
                            [
                              _vm._v(
                                "\n              （图片大小不超过200M，支持JPG、JPEG及PNG格式）\n            "
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "简介", prop: "sort" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "详情", prop: "sort" } },
                    [_c("div", { ref: "editor", attrs: { id: "editor" } })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
          _vm._v(" "),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.save } },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }