<template>
  <el-dialog
    :lock-scroll="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    class="specialTrainChapter"
    @close="close"
  >
    <el-form ref="form" :model="form" label-width="auto">
      <el-row :gutter="20">
        <el-col class="addnewcate">
          <el-button @click="addChapter()" type="primary">新增阶段</el-button>
          <el-col class="switchBox">
            <span>激活天数限制</span>
              <el-switch
                v-model="form.switchStatus"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否">
              </el-switch>
          </el-col>
        </el-col>
        <el-col style="padding-top: 15px;">
          <draggable v-model="form.chapter" group="title">
            <transition-group>
          <el-card class="box-card" shadow="never" v-for="(item, index) in form.chapter" :key="index">
            <div slot="header" class="clearfix chapterCateHeader">
              {{item.title}}
              <i class="el-icon-edit-outline" size="15" @click="addChapter(item)"></i>

              <el-popover
                v-model="isExaminationShow"
              width="700"
              trigger="click">
              <el-row :gutter="20">
                <el-col :span="7">
                  <el-form-item style="margin-bottom: 10px;">
                    <el-select
                      v-model="searchForm.goodtype"
                      placeholder="请选择类型"
                      filterable
                      clearable
                      style="width: 100%"
                    >
                      <el-option
                        v-for="(item, index) in typeList"
                        :key="index"
                        :label="item.title"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="7">
                  <el-form-item style="margin-bottom: 10px;">
                    <el-input v-model="searchForm.title" clearable placeholder="请输入课程名称"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="3">
                  <el-form-item style="margin-bottom: 10px;">
                    <el-button type="primary" @click="getmallGoodsList">搜索</el-button>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-table :data="mallGoodsList" @selection-change="selectionchange" style="width: 100%; min-height: 300px;" max-height="300">
                <el-table-column type="selection" width="40"></el-table-column>
                <el-table-column property="section_t" label="版块" width="60"></el-table-column>
                <el-table-column property="goodtype_t" label="类型" width="60"></el-table-column>
                <el-table-column property="title" label="名称"></el-table-column>
              </el-table>

              <el-row>
                <el-col>
                  <el-pagination
                    :current-page="searchForm.page"
                    :layout="layout"
                    :page-size="searchForm.limit"
                    :total="total"
                    background
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    style="padding: 10px 0px 0px 0px;overflow: hidden;"
                  />
                </el-col>
              </el-row>

              <el-row class="el-dialog__footer" style="margin: 10px -10px -10px -10px;">
                <el-button type="primary" style="float: right;" @click="syncSelectedList(); isExaminationShow=false">保存</el-button>
              </el-row>

              <el-button slot="reference" style="float: right;" type="success" @click="getmallGoodsList(index); () => {this.isExaminationShow=true}">新增考试任务</el-button>
            </el-popover>

              <el-popover
                v-model="isClassShow"
                width="700"
                trigger="click">
                <el-row :gutter="20">
                  <el-col :span="7">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-select
                        v-model="searchForm.goodtype"
                        placeholder="请选择类型"
                        filterable
                        clearable
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in typeList"
                          :key="index"
                          :label="item.title"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-input v-model="searchForm.search" clearable placeholder="请输入课程名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-button type="primary" @click="searchMallGoodsList">搜索</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-table :data="mallGoodsList" @selection-change="selectionchange" style="width: 100%; min-height: 300px;" max-height="300">
                  <el-table-column type="selection" width="60"></el-table-column>
                  <el-table-column property="goodtype_t" label="类型" width="80"></el-table-column>
                  <el-table-column property="title" label="名称"></el-table-column>
                </el-table>

                <el-row>
                  <el-col>
                    <el-pagination
                      :current-page="searchForm.pageNo"
                      :layout="layout"
                      :page-size="searchForm.pageSize"
                      :total="total"
                      background
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      style="padding: 10px 0px 0px 0px;overflow: hidden;"
                    />
                  </el-col>
                </el-row>

                <el-row class="el-dialog__footer" style="margin: 10px -10px -10px -10px;">
                  <el-button type="primary" style="float: right;" @click="syncSelectedList(); item.isExaminationShow=false">保存</el-button>
                </el-row>

<!--                <el-button slot="reference" style="float: right;" type="success" @click="getmallGoodsList(index); item.isExaminationShow=true">新增考试任务</el-button>-->
              </el-popover>

              <el-popover
                v-model="item.isClassShow"
                width="800"
                trigger="click">
                <el-row :gutter="20">
                  <el-col :span="7" class="mallGoodsPopover">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-select
                        v-model="searchForm.section"
                        placeholder="请选择版块"
                        filterable
                        clearable
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in sectionList"
                          :key="index"
                          :label="item.title"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-select
                        v-model="searchForm.goodtype"
                        placeholder="请选择类型"
                        filterable
                        clearable
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in typeList"
                          :key="index"
                          :label="item.title"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-input v-model="searchForm.search" clearable placeholder="请输入课程名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-button type="primary" @click="getmallGoodsList">搜索</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-table :data="mallGoodsList" @selection-change="selectionchange" style="width: 100%; min-height: 300px;" max-height="300">
                  <el-table-column type="selection" width="40"></el-table-column>
                  <el-table-column property="section_t" label="版块" width="60"></el-table-column>
                  <el-table-column property="goodtype_t" label="类型" width="60"></el-table-column>
                  <el-table-column property="title" label="名称"></el-table-column>
                </el-table>

                <el-row>
                  <el-col>
                    <el-pagination
                      :current-page="searchForm.pageNo"
                      :layout="layout"
                      :page-size="searchForm.pageSize"
                      :total="total"
                      background
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      style="padding: 10px 0px 0px 0px;overflow: hidden;"
                    />
                  </el-col>
                </el-row>
                <el-row class="el-dialog__footer" style="margin: 10px -10px -10px -10px;">
                  <el-button type="primary" style="float: right;" @click="syncSelectedList(index);isClassShow=false">保存</el-button>
                </el-row>

                <el-button slot="reference" style="float: right; margin-right: 10px;" type="primary" @click="getmallGoodsList(index);() => { this.isClassShow=true };">新增课程任务</el-button>
              </el-popover>

            </div>
            <div style="margin: -20px" class="goods_list">
              <draggable v-model="item.list" :group="'goodslist'+item.id">
                <transition-group>
                  <el-row v-for="(iitem, iindex) in item.list" :key="iindex">
                    <el-col :span="2" class="tdc">{{iindex+1}}</el-col>
                    <el-col :span="12">{{iitem.title}}</el-col>
                    <el-col :span="6" class="tdc">拖动排序</el-col>
                    <el-col :span="4" class="tdc">
                      <!-- <el-popconfirm title="是否确认移除此课程？" @onConfirm="removeChapterItem(index,iindex)">
                        <el-button slot="reference">移除</el-button>
                      </el-popconfirm> -->
                      <el-popover
                        placement="top"
                        width="160"
                        v-model="showVisible">
                        <p>是否确认移除此课程？</p>
                        <div style="text-align: right; margin: 0">
                          <el-button size="mini" type="text" @click="showVisible = false">取消</el-button>
                          <el-button type="primary" size="mini" @click="removeChapterItem(index,iindex)">确定</el-button>
                        </div>
                        <el-button slot="reference">删除</el-button>
                      </el-popover>
                    </el-col>
                  </el-row>
                </transition-group>
              </draggable>
            </div>
          </el-card>
          </transition-group>
          </draggable>
        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>

    <el-dialog
      width="30%"
      :title="chapterDialogTitle"
      :visible.sync="chapterDialog"
      :close-on-click-modal="false"
      append-to-body>
      <el-form ref="form" :model="form" label-width="auto">
        <el-row :gutter="20">
          <el-col>
            <el-form-item label="阶段名称" prop="">
              <el-input v-model="chapterform.title" placeholder="请填写阶段名称" />
            </el-form-item>
          </el-col>
          <el-col>
            <el-form-item label="限定启动时间" prop="">
              <el-switch
                v-model="chapterform.time_limit"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否">
              </el-switch>
            </el-form-item>
          </el-col>
          <el-col v-if="chapterform.time_limit==1">
            <el-form-item label="训练开始时间" prop="">
              <el-date-picker
                v-model="chapterform.start_time"
                align="left"
                type="datetime"
                placeholder="开始时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :editable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col v-if="chapterform.time_limit==1">
            <el-form-item label="训练结束时间" prop="">
              <el-date-picker
                v-model="chapterform.end_time"
                align="left"
                type="datetime"
                placeholder="结束时间"
                value-format="yyyy-MM-dd HH:mm:ss"
                :editable="false"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button v-if="chapterform.index!=-1" type="danger" @click="delChapter" style="float: left;">删除</el-button>
        <el-button type="primary" @click="chapterSave">保存</el-button>
      </div>
    </el-dialog>

  </el-dialog>
</template>

<script>
import draggable from 'vuedraggable'
import axios from "axios";
import {specialTrainingChapterSave, courseList} from "../../src/utils/API/api";
export default {
  name: 'specialTrainChapter',
  components: {
    draggable,
  },
  data() {
    return {
      dialogFormVisible: false,
      chapterDialog: false,
      title:'',
      showVisible:false,
      chapterDialogTitle:'',
      form: {
        id:1,
        chapter:[
        ],
        switchStatus:0
      },
      chapterform:{
        index:-1,
        title:'',
        time_limit:0,
        start_time:'',
        end_time:'',
        ids:[],
        list:[],
      },
      isClassShow:false,
      isExaminationShow: false,
      searchForm:{
        page:1,
        limit:10,
        search:''
      },
      layout: 'total, sizes, prev, pager, next',
      total:0,
      listLoading: true,
      sectionList:[
        {id:0,title:'全部'},
        {id:1,title:'内部'},
        {id:2,title:'外部'},
      ],
      typeList:[
        {id:0,title:'全部'},
        {id:1,title:'图文'},
        {id:2,title:'视频'},
        {id:3,title:'音频'},
      ],
      mallGoodsList: [],
      processIndex:0,
      selectedList:[],
      mch_id: -1,
      rule_type: 0,
    }
  },
  created() {
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    showEdit(row) {
      console.log(row)
      this.title = '阶段管理'
      this.form.id = row.id
      this.mch_id = row.mch_id
      this.rule_type = row.rule_type
      this.form.switchStatus = row.switchStatus
      this.form.chapter = row.chapterList.length>0 ? row.chapterList : []
      if(row.chapterCount<=0){
        //this.chapterDialog = true;
        //this.chapterDialogTitle = "新增阶段";
      }
      //this.form = Object.assign({}, row)
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      var that = this;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          await specialTrainingChapterSave(this.form).then(response => {
            let res = response.data
            that.$emit('fetch-data')
            that.close()
          }).catch(() => {
          })
        } else {
          return false
        }
      })
    },
    //阶段保存
    chapterSave(){
      console.log(this.chapterform.index,'阶段保存index')
      if(this.chapterform.index==-1){
        this.chapterform.index = this.form.chapter.length
        var chapterform = JSON.parse(JSON.stringify(this.chapterform));
        this.form.chapter.push(chapterform);
      }else{
        var chapterform = JSON.parse(JSON.stringify(this.chapterform));
        this.form.chapter[this.chapterform.index] = chapterform
      }
      console.log(this.form.chapter,'阶段保存chap')
      this.chapterDialog = false;
    },
    //删除阶段
    delChapter(){
      var that = this
      this.$confirm('您确定要删除此阶段?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          that.form.chapter.splice(that.chapterform.index, 1);
          this.chapterDialog = false;
          var i = 0
          that.form.chapter.forEach(function (item,index){
            that.form.chapter[i].index = i
            i++
          })
          console.log(that.form.chapter)

        })
        .catch(() => {})

    },
    handleSizeChange(val) {
      this.searchForm.limit = val
      this.getmallGoodsList()
    },
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.getmallGoodsList()
    },
    //新增章节
    addChapter(item){
      var that = this;
      if(item){
        that.chapterform = Object.assign({}, item)
        that.chapterDialogTitle = "编辑阶段"
      }else{
        this.chapterform.id = 0;
        this.chapterform.index = -1;
        this.chapterform.title = '';
        this.chapterform.time_limit = 0;
        this.chapterform.start_time = '';
        this.chapterform.end_time = '';
        this.chapterform.ids = [];
        this.chapterform.list = [];
        this.chapterDialogTitle = "新增阶段"
      }
      that.chapterDialog = true;
    },
    searchMallGoodsList() {
      this.searchForm.page = 1
      this.getmallGoodsList()
    },
    //获取课程列表
    getmallGoodsList(index=-1){
      var that = this;
      if(index!=-1 && typeof index =='number'){
        that.processIndex = index
      }
      that.listLoading = true
      that.searchForm.query_mch_id = this.mch_id
      that.searchForm.rule_type = this.rule_type
      courseList(that.searchForm).then(response => {
        let res = response.data
        that.total = res.data.dataCount;
        that.mallGoodsList = res.data.list;
        that.listLoading = false

      }).catch(() => {
        that.listLoading = false
      })
    },
    //移除商品列表
    removeChapterItem(index,iindex){
      console.log(index,iindex)
      this.form.chapter[index].ids.splice(iindex, 1);
      this.form.chapter[index].list.splice(iindex, 1);
    },
    //更改选择项
    selectionchange(data){
      var that = this;
      var processIndex = that.processIndex;
      var lists = [];
      that.selectedList = [];

      data.forEach(function(item, index) {
        var list = {list_id:item.id, title:item.title}
        lists.push(list);
      })
      that.selectedList = lists;
    },
    //保存选择的列表项
    syncSelectedList(index){
      // console.log('syncSelectedList', this.form.chapter[index])
      this.form.chapter[index].isClassShow=false
      var that = this;
      var processIndex = that.processIndex;
      var lists = that.selectedList;
      var ids = that.form.chapter[processIndex].ids;
      lists.forEach(function(item, index) {
        if(ids.indexOf(item.list_id) === -1){
          that.form.chapter[processIndex].ids.push(item.list_id);
          that.form.chapter[processIndex].list.push(item);
        }
      })

      that.selectedList = []
    }
  },
}
</script>

<style lang="scss" scoped>
.specialTrainChapter {
  ::v-deep {
    .el-dialog__body{
      padding: 10px 0px;
    }
    .el-card{
      border-bottom: 0px;
      .el-card__header{
        padding: 10px 20px;
      }
    }

    .el-icon-edit-outline{
      cursor: pointer;
    }

    .addnewcate{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 20px;
    }
    .switchBox{
      display: flex;
      font-weight: 600;
      font-size: 16px;
      width: 210px;
      justify-content: space-between;
      align-items: center;
      margin-right: 30px;
    }
    .chapterCate{

      .el-card__header {
        padding: 10px 20px;
        line-height: 21px;
      }
    }
    .goods_list{
      .tdc{
        text-align: center;
      }
      .el-row{
        padding: 10px 0px;
      }
    }
  }
}
</style>
