<template>
    <div class="content_manage">
      <el-container>

        <el-main style="background: white">
          <div class="search">
            <el-form :inline="true" class="demo-form-inline">
              <el-form-item>
                <el-select v-model="searchForm.goodtype" placeholder="请选择类型" filterable clearable>
                  <el-option
                    v-for="(item, index) in typeList"
                    :key="index"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="searchForm.query_mch_id === 0">
                <el-select v-model="searchForm.rule_type" clearable>
                  <el-option
                    v-for="item in rule_list"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label>
                <el-input placeholder="请输入课程标题" v-model="searchForm.search" clearable></el-input>
              </el-form-item>
              <el-form-item label>
                <el-button class="btn1" @click="getList">查找</el-button>
              </el-form-item>
              <el-form-item label style="float: right">
                <!-- <el-button @click="Edit({}, 8)" type="primary">新增工具文档</el-button> -->
                <el-button @click="Edit({}, 1)" type="primary">新增图文</el-button>
                <el-button @click="Edit({}, 3)" type="warning">新增视频</el-button>
                <!-- <el-button @click="Edit({}, 2)" type="danger">新增音频</el-button> -->
              </el-form-item>
            </el-form>
          </div>
  
          <el-table
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            ref="table"
            style="width: 100%"
            v-loading="loading"
            :data="list"
            :row-style="{ height: '81px' }"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{
              color: '#333',
              fontSize: '14px',
              backgroundColor: '#F6F7FB',
              fontWeight: 'normal',
              textAlign: 'center',
            }"
          >
            <el-table-column prop="mch_name" label="店铺名"></el-table-column>
            <el-table-column prop="rule_type_txt" label="课程权限类型"></el-table-column>
            <el-table-column prop="title" label="课程名称" min-width="300"></el-table-column>
            <el-table-column prop="goodtype_t" label="类型" align="center" min-width="100"></el-table-column>
            <el-table-column prop="views" label="点阅数" align="center" min-width="80">
              <template slot-scope="scope">
                <div class="operation">
                 {{scope.row.views>9999?(Math.floor(scope.row.views/1000)/10)+'w':scope.row.views}}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="is_comment_t" label="评论" align="center" min-width="150"></el-table-column>
            <el-table-column prop="is_recommend_t" label="推荐" align="center" min-width="80"></el-table-column>
            <el-table-column prop="create_time" label="创建时间" align="center" min-width="150"></el-table-column>
            <el-table-column prop="status_t" label="状态" align="center" min-width="80"></el-table-column>
            <el-table-column prop="name" width="120" label="操作" fixed="right">
              <template slot-scope="scope">
                <div class="operation">
                  <el-button class="text_btn" type="text" @click="Edit(scope.row)">编辑</el-button>
                  <el-button class="text_btn" type="text" @click="Delete(scope.row)">删除</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
  
          <div class="pagination">
            <el-pagination
              @size-change="handleLimitChange"
              @current-change="handlePageChange"
              :current-page="searchForm.page"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="searchForm.limit"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            ></el-pagination>
          </div>
        </el-main>
        
        <el-dialog
          @opened="editorInit()"
          @closed="editorClear()"
          :lock-scroll="false"
          :title="title"
          :visible.sync="advEditVisible"
          :close-on-click-modal="false"
          class="studyedit"
          @close="closeDialog('courseEditForm')"
        >
          <el-form ref="courseEditForm" :model="courseEditForm" label-width="120px">
            <el-row :gutter="20">
              <el-col>
                <el-form-item label="课程权限类型">
                  <!-- 简化下逻辑，该值编辑的时候不允许修改 -->
                  <el-select v-model="courseEditForm.rule_type" clearable>
                    <el-option  key ="1" label="全员" :value="1"  ></el-option>
                    <el-option  key ="2" label="老板" :value="2"></el-option>
                    <el-option  key ="3" label="干部" :value="3"></el-option>
                    <el-option  key ="4" label="指定人员" :value="4"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col v-if="courseEditForm.rule_type == 4">
                <el-form-item label="选择成员">
                    <el-button type="primary" @click="addUser = true">+添加成员</el-button>
                    <div class="btnBox">
                        <div class="btns" v-for="(i,j) in showUserArr" :key="j">
                            <img style="top: -5px;right: -5px;position: absolute;" @click="delUser(i)" src="../../src/static/image/del.png"/>
                            {{ i.label }}
                        </div>
                    </div>
                </el-form-item>
              </el-col>
              <el-dialog title="选择成员" append-to-body :visible.sync="addUser">
                <el-tree
                    v-if="addUser"
                    :props="defaultProps"
                    :load="loadNode"
                    lazy
                    show-checkbox
                    ref="tree"
                    :check-strictly="true"
                    :default-expanded-keys="[1]"
                    node-key="value"
                    :default-checked-keys="courseEditForm.user_ids"
                    @check-change="handleCheckChange">
                    </el-tree>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="addUser = false">取 消</el-button>
                        <el-button type="primary" @click="confirmAdd">确 定</el-button>
                    </span>
              </el-dialog>
              <el-col v-if="courseEditForm.rule_type == 2">
                <!-- 图文默认可查看三分之一，音频、视频可手动输入时长 -->
                <el-form-item label="是否允许试看">
                  <el-radio-group v-model="courseEditForm.is_try_and_see">
                    <el-radio :label="0">关</el-radio>
                    <el-radio :label="1">开</el-radio>
                  </el-radio-group>
                  <span
                    v-if="courseEditForm.goodtype != 1 && courseEditForm.is_try_and_see == 1"
                    style="margin-left: 30px;"
                  >
                    <span>音频/视频试看时长：</span>
                    <el-input
                      v-model="courseEditForm.try_and_see_time"
                      placeholder="请输入音频/视频试看时长"
                      style="width: 150px"
                    ></el-input>
                  </span>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="课程名称" prop="title">
                  <el-input v-model="courseEditForm.title" placeholder="请填写课程名称" />
                </el-form-item>
              </el-col>
            
              <el-col :sm="12" :xs="24">
                <el-form-item label="点阅数" prop="views">
                  <el-input v-model="courseEditForm.views" placeholder="请填写点阅数" />
                </el-form-item>
              </el-col>
  
              <el-col :sm="12" :xs="24">
                <el-form-item label="评论开关" prop="is_comment">
                  <el-select
                    v-model="courseEditForm.is_comment"
                    filterable
                    placeholder="请选择是否允许评论"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in commentList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :xs="48">
                <el-form-item label="状态" prop="status">
                  <el-select
                    v-model="courseEditForm.status"
                    filterable
                    placeholder="请选择状态"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :label="item.label"
                      :value="item.value"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :sm="24" :xs="48" v-if="courseEditForm.goodtype==1">
                <el-form-item label="附件上传" prop="allow_download">
                  <el-upload
                    class="upload-demo"
                    action="#"
                    :http-request="httpRequest2"
                    :data="uploadData5"
                    name="file"
                    :on-success="reqUploadSuccess6"
                    :on-remove="handleRemove"
                    :show-file-list="false"
                    :before-upload="beforeFileUpload"
                  >
                    <el-button size="small" type="primary">点击上传</el-button>
                  </el-upload>
                  <div class="upfile_box" v-if="form7.file_id">
                    <div class="file_box">
                      <img src="../../src/static/image/other.png" class="img" />
                      <img src="../../src/static/image/del.png" class="del" @click="delFile()" />
                      <!--              <el-tooltip class="item" effect="dark" :content="item.file_name" placement="bottom">-->
                      <!--                <el-button type="text" class="btn_tip">{{item.file_name}}</el-button>-->
                      <!--              </el-tooltip>-->
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24" v-if="courseEditForm.goodtype==1">
                <el-form-item label="附件下载" prop="allow_download">
                  <el-switch
                    v-model="courseEditForm.allow_download"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="允许"
                    inactive-text="不允许"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24">                                      
                <el-form-item label="推荐" prop="is_recommend">
                  <el-switch
                    v-model="courseEditForm.is_recommend"
                    :active-value="1"
                    :inactive-value="0"
                    active-text="是"
                    inactive-text="否"
                  ></el-switch>
                </el-form-item>
              </el-col>
              <el-col :sm="12" :xs="24" v-if="courseEditForm.goodtype!=1">
                <el-form-item label="时长" prop="mediaminute">
                  <el-input
                    placeholder="分钟数"
                    size="small"
                    v-model="courseEditForm.mediaminute"
                    style="width: 45%"
                  >
                    <template slot="append">分</template>
                  </el-input>
                  <el-input
                    placeholder="秒数"
                    size="small"
                    v-model="courseEditForm.mediasecond"
                    style="width: 45%"
                  >
                    <template slot="append">秒</template>
                  </el-input>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="课程插图" prop="pictures">
                  <el-upload
                    :class="hideUpload ? 'hide avatar-uploader' : 'avatar-uploader'"
                    action="#"
                    :http-request="httpRequest1"
                    :data="uploadData"
                    :show-file-list="false"
                    :on-change="handleUploadChange"
                    :on-remove="handleUploadRemove"
                  >
                    <img v-if="courseEditForm.pictures" :src="courseEditForm.pictures" class="avatar" />
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    <div slot="tip" class="el-upload__tip">（图片大小不超过2M，支持JPG、JPEG及PNG格式）</div>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item :label="courseEditForm.goods_type_name" prop="url">
                  <el-upload
                    :class="hideUpload ? 'hide avatar-uploader' : 'avatar-uploader'"
                    action="#"
                    :http-request="httpRequest11"
                    :data="uploadData"
                    :show-file-list="false"
                    :on-change="officehandleUploadChange"
                    :on-remove="handleUploadRemove"
                  >
                    <el-button style="float:left;">上传office/pdf</el-button>
                  </el-upload>
                  <a :href="courseEditForm.url" target="_blank">{{ courseEditForm.url }}</a>
                </el-form-item>
              </el-col>
              <el-col>
                <el-form-item label="简介" prop="description">
                  <el-input v-model="courseEditForm.description" />
                </el-form-item>
              </el-col>
  
              <el-col>
                <el-form-item label="详情" prop="showdetail">
                  <div ref="editor" id="editor"></div>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('courseEditForm')">取 消</el-button>
            <el-button type="primary" @click="Save">确 定</el-button>
          </div>
        </el-dialog>
      </el-container>
    </div>
  </template>
  <script>
  import {
    courseCateList,
    courseList,
    courseSave,
    courseDelete,
    myCustomerHomepage,
    getMchEmployeeList
  } from "../../src/utils/API/api";
  import { upload } from "../../src/utils/API/api";
  import E from "wangeditor";
  import store from '../../src/store';
  export default {
    data() {
      return {
        loading: false,
        buttonLoading: false,
        total: 0,
        uploadData5: {
          type: "7"
        },
        form7: {
          file_id:''
        },
        list: [],
        uploadTxt:'',
        addUser:false,
        title: "添加课程",
        advEditVisible: false,
        courseEditForm: {},
        searchForm: {
          goodtype: 0,
          query_mch_id: "",
          search: "",
          rule_type: "", // 权限类型 1免费 2会员（仅针对店铺通用生效）
          page: 1,
          limit: 10
        },
        typeList: [
          { id: 0, title: "全部" },
          { id: 1, title: "图文" },
          { id: 2, title: "音频" },
          { id: 3, title: "视频" }
        ],
        sectionList: [
          { value: 1, label: "内部" },
          { value: 2, label: "外部" }
        ],
        statusList: [
          { label: "有效", value: 1 },
          { label: "失效", value: 0 }
        ],
        showstatusList: [
          { label: "显示", value: 1 },
          { label: "隐藏", value: 0 }
        ],
        commentList: [
          { label: "所有人均可评论", value: 1 },
          { label: "关闭评论", value: 0 }
        ],
        CategoryList: [],
        hideUpload: false,
        uploadData: {
          type: "2"
        },
        fileList: [],
        crmFileSaveOSSUrl: "",
        showUserArr:[],
        mch_list: [],
        rule_list: [],
        dataLists: [],
        //tree参数
        halfCheckedKeys: [],
        checkedKeys: [],
        allChecked: [],
        options1:[],
        is_boss:1,
        defaultProps: {
            label: 'label',
            value: 'value',
            children: "children",
            disabled: function (data, node) {//带子级的节点不能选中
            if (node.level == 1) {
                return true
            } else {
                return false
            }
            }
        }
      };
    },
    watch: {},
    created() {
      this.getList();
    },
    computed: {
      // 根据 mch_id 过滤频道列表
      filterCategoryList: function() {
        let CategoryList = this.CategoryList;
        return CategoryList.filter(item => {
          return item.mch_id == this.courseEditForm.mch_id;
        });
      }
    },
    mounted() {
      this.tableHeight =
        window.innerHeight - this.$refs.table.$el.offsetTop - 229;
        this.searchForm.query_mch_id = store.state.mch_id
        this.courseEditForm.mch_id = store.state.mch_id
        this.myCustomerHomepageFun()
        this.is_boss = localStorage.getItem('info').is_boss
    },
  
    methods: {
        myCustomerHomepageFun() {
        let that = this
        myCustomerHomepage(this.is_boss)
        .then(res => {
          if (res.data.data.mchStore.length == 0) {
            that.$message.error('您还没有门店，请先添加门店！')
            return
			}
            that.options1 = res.data.data.mchStore.map(item => {
                return {
                    value: item.id,
                    label: `${item.mch_name}(${item.store_name})`
                }
            })
        })
        .catch(error => {
          this.$message.error('调用接口失败！')
        })
	},
      formInit(){
        var that =this
        that.dataLists = []
      },
      changeMchId() {
        this.courseEditForm.categorys = [];
      },
      handleCheckChange(data, checked, indeterminate) {
        console.log(data, checked, indeterminate);
      },
      loadNode(node, resolve){
        let that = this
        if (node.level === 0) {
            return resolve(this.options1)
        }
        console.log(node, resolve)
        if (node.level === 2) {
            return resolve([])
        }
        if (node.level === 1) {
            getMchEmployeeList({mch_store_id:node.data.value})
            .then(res => {
                let arr = res.data.data.map(item => {
                    return {
                        value: item.user_id,
                        label: item.user_name
                    }
                })
                resolve(arr)
            })
            .catch(error => {
            this.$message.error('调用接口失败！')
            })
        }
      },
      resetCourseEditForm() {
        this.courseEditForm = {
          id: 0,
          mch_id: 0,
          pos_id: "",
          title: "",
          category: "",
          categorys: [],
          goodtype: 1,
          goodtype_t: "",
          url: "",
          pictures: "",
          sort: 100,
          start_time: "",
          end_time: "",
          rule_type: "",
          is_try_and_see: 0,
          try_and_see_time: 15,
          views:0,
          user_ids:[]
        };
        this.courseEditForm.mch_id = store.state.mch_id
      },
      editorInit() {
        var that = this;
        that.editor = new E(that.$refs.editor);
        that.editor.config.customUploadImg = (resultFiles, insertImgFn) => {
          // resultFiles 是 input 中选中的文件列表
          // insertImgFn 是获取图片 url 后，插入到编辑器的方法
          var formdata = new FormData();
          formdata.append("file", resultFiles[0]);
          let loading = this.$loading({
            lock: true,
            text: "上传中",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)"
          });
          upload(formdata)
            .then(function(res) {
              // 上传图片，返回结果，将图片插入到编辑器中
              console.log(res.data)
              insertImgFn(res.data.data.url);
            })
            .catch(function(err) {
              // console.log(err)
            })
            .finally(() => {
              loading.close();
            });
          // }
        };
        that.editor.config.onchange = html => {
          that.courseEditForm.showdetail = html;
        };
        that.editor.create();
        that.editor.txt.html(that.courseEditForm.showdetail);
      },
      editorClear() {
        this.$refs.editor.innerHTML = "";
        this.editor.destroy();
        this.editor = null;
      },
      handleRemove(file, filelist) {
        this.form7.filelist = filelist;
      },
      beforeFileUpload() {
        this.loading = true;
      },
      reqUploadSuccess6(res, file, fileList) {
        console.log(res);
        if (res.code == "200") {
          console.log(res);
          this.loading = false;
          var obj = "";
          obj = {
            content: res.data.content,
            file_name: res.data.file_name,
            file_suffix: res.data.file_suffix,
            media_id: res.data.media_id,
            created_at: res.data.created_at,
            name: res.data.file_name
          };
          this.form7.filelist.push(obj);
          console.log(this.form7.filelist);
        } else {
          this.$message.error(res.msg);
        }
      },
      delFile() {
          this.form7.file_id = '';
        },
      httpRequest2(obj) {
        var that = this;
        that.loading = false;
        var formdata = new FormData();
        formdata.append("file", obj.file);
        formdata.append("name", obj.file.name);
        upload(formdata)
          .then(function(res) {
            console.log(res);
            that.form7.file_id = res.data.url
            console.log(that.form7);
          })
          .catch(function(err) {
            // console.log(err)
          });
      },
      async getList() {
        this.loading = true;
        let res = await courseList(this.searchForm);
        if (res.data.code == 0) {
          this.list = res.data.data.list;
          this.total = res.data.data.dataCount;
          this.loading = false;
        }
      },
      async getcateList() {
        this.loading = true;
        let res = await courseCateList({ page: 1, limit: 100 });
        if (res.data.code == 0) {
          this.CategoryList = res.data.data.list;
          console.log(this.CategoryList, "999999999999999999999");
          this.loading = false;
        }
      },
      handleLimitChange(val) {
        this.searchForm.limit = val;
        this.getList();
      },
      handlePageChange(val) {
        this.searchForm.page = val;
        this.getList();
      },
      Edit(row, goodtype) {
        var that = this;
        that.form7.file_id = ''
        // this.getcateList();
        that.advEditVisible = true;
        that.title = "添加课程";
        if (row.id) {
          that.title = "编辑课程[ " + row.title + " ]";
          that.$nextTick(() => {
            that.courseEditForm = JSON.parse(JSON.stringify(row));
            // this.form7.file_id = this.courseEditForm.url
            that.courseEditForm.user_ids = []
          });
  
        } else {
          that.resetCourseEditForm();
        }
        if (goodtype) {
          that.courseEditForm.goodtype = goodtype;
          that.typeList.filter(function(item, index) {
            if (item.id == goodtype) {
              that.courseEditForm.goodtype_t = item.title;
            }
          });
        }
  
      },
      async Save() {
        this.halfCheckedKeys = []
        this.checkedKeys = []
        this.allChecked = []
        this.courseEditForm.temp_ids= null
        // this.courseEditForm.url = this.form7.file_id
        this.$refs["courseEditForm"].validate(async valid => {
          if (valid) {
            this.buttonLoading = true;
            let res = await courseSave(this.courseEditForm);
            if (res.data.code == 0) {
              this.$message({
                showClose: true,
                message: res.data.data,
                type: "success"
              });
              this.getList();
  
              this.advEditVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "error"
              });
            }
            this.buttonLoading = false;
          }
        });
      },
      Delete(row) {
        this.$confirm("删除后无法恢复，确认删除？", "提示", {
          cancelButtonClass: "btn-white",
          confirmButtonClass: "btn-blue",
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          closeOnClickModal: false
        })
          .then(async () => {
            let res = await courseDelete({ id: row.id });
            if (res.data.code == 0) {
              this.$message({
                showClose: true,
                message: res.data.data,
                type: "success"
              });
              this.getList();
              this.advEditVisible = false;
            } else {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "error"
              });
            }
          })
          .catch(() => {});
      },
      confirmAdd(){
        let arr = this.$refs.tree.getCheckedNodes().map((x)=>{
            return x.value
        })
        this.showUserArr = this.$refs.tree.getCheckedNodes()
        this.addUser = false
        console.log(arr, 'eweww')
        this.courseEditForm.user_ids = arr
      },
      delUser(data){
        this.courseEditForm.user_ids.splice(this.courseEditForm.user_ids.findIndex(x=>x.value == data.value), 1)
        this.showUserArr.splice(this.showUserArr.findIndex(x=>x.value == data.value), 1)
      },
      closeDialog(form) {
        this.advEditVisible = false;
      },
      handleUploadChange(file, fileList) {
      console.log(file, fileList)
        this.hideUpload = fileList.length >= 1;
      },
      officehandleUploadChange(file, fileList) {
        console.log(file, fileList)
        this.uploadTxt = file.raw.name
        this.hideUpload = fileList.length >= 1;
      },
      handleUploadRemove(file, fileList) {
        console.log(file, fileList)
        this.courseEditForm.pictures = "";
        this.hideUpload = fileList.length >= 1;
      },
      beforeAvatarUpload(file) {
        let types = ["image/jpeg", "image/jpg", "image/png"];
        const isImage = types.includes(file.type);
        const isLtSize = file.size / 1024 / 1024 < 200;
        if (!isImage) {
          this.$message.error("上传图片只能是JPG、JPEG及PNG格式!");
          return false;
        }
        if (!isLtSize) {
          this.$message.error("上传图片大小不能超过 200MB!");
          return false;
        }
        return isImage && isLtSize;
      },
      httpRequest1(obj) {
        var that = this;
        var formdata = new FormData();
        formdata.append("file", obj.file);
        formdata.append("name", obj.file.name);
        let loading = this.$loading({
          lock: true,
          text: "上传中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        upload(formdata)
          .then(function(res) {
            that.courseEditForm.pictures = res.data.data.url;
          })
          .finally(() => {
            loading.close();
          });
      },
  
      httpRequest11(obj) {
        var that = this;
        var formdata = new FormData();
        formdata.append("file", obj.file);
        formdata.append("name", obj.file.name);
        let loading = this.$loading({
          lock: true,
          text: "上传中",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        upload(formdata)
          .then(function(res) {
            that.courseEditForm.url = res.data.data.url;
          })
          .finally(() => {
            loading.close();
          });
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  .content_manage{
    flex: 1;
  }
  .pagination {
  width: 100%;
  margin: 30px 0;

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #2362fb;
}

.el-pagination .btn-next,
.el-pagination .btn-prev {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  background: #fff;
  border-radius: 6px;
  padding: 0;
  min-width: 30px;
  margin: 0 5px;
  border: 1px solid rgba(19, 58, 179, 0.2);
}

.el-pager li {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: #ffffff;
  border-radius: 6px;
  min-width: 30px;
  border: 1px solid rgba(19, 58, 179, 0.2);
  margin: 0 5px;
}
img {
  max-width: 100%;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 6px;
}
.btnBox{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.btns{
    display: flex;
    position: relative;
    padding: 3px 10px;
    width: 60px;
    height: 25px;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #ddd;
    justify-content: center;
    margin: 10px;
}
  .upfile_box {
            width: 100%;
            margin-top: 20px;
  
            display: flex;
            flex-wrap: wrap !important;
  
            .file_box {
              margin-right: 10px;
              width: 120px;
              position: relative;
              border-radius: 4px;
  
              .img {
                width: 35px;
                height: 40px;
                border-radius: 4px;
  
              }
  
              .del {
                position: absolute;
                top: -6px;
                left: 41px;
                width: 12px;
                height: 12px;
              }
  
              .title {
  
                font-size: 16px !important;
                font-weight: normal !important;
                line-height: 0 !important;
              }
            }
          }
  
          .upimg_box {
            width: 100%;
            margin-top: 20px;
            display: flex;
  
            flex-wrap: wrap;
  
            .img_box {
              margin-right: 10px;
              width: 60px;
              height: 60px;
              position: relative;
              border-radius: 4px;
  
              .img {
                width: 100%;
                height: 100%;
                border-radius: 4px;
  
              }
  
              .del {
                position: absolute;
                top: -6px;
                right: -6px;
                width: 12px;
                height: 12px;
              }
  
              .look {
                position: absolute;
                top: 50%;
                right: 50%;
                width: 14px;
                height: 11px;
              }
            }
          }
  </style>
  