<template>
	<div>
		<div class="tag_box_title" @click="tapEditTag">
			<div class="title">跟进阶段</div>
			<div class="surmount_section">
				<div class="section1" style="padding: 5px;display: flex;flex-direction: column;align-items: flex-end;" v-if="nowStage">
					<div style="font-weight: 600; margin-right: 10px; color: #b7905b;">{{nowStage.name}}</div>
					<!-- <div>{{nowStage.description}}</div> -->
				</div>
				<div class="notData" v-if="!nowStage || nowStage.length == 0" >暂无数据哦！</div>
				<div class="edit">
					<img src="../../src/static/image/right_img.png"/>
				</div>
			</div>
		</div>
		<el-dialog append-to-body :visible.sync="showEdit" title="选择阶段" width="40%">
			<div class="surmountBox">
				<!-- <div class="surmount_cont">
					<el-tabs bar-width="60" height='95' active-color='#B7905B' :list="tab_list" :is-scroll="false"
						:current="current" @change="changes">
					</el-tabs>
				</div> -->
				<div style="height: 80%;">
					<div class="content_box_s" v-for="(i,j) in details_list" :key="j" v-if="details_list && details_list.length>0">
						<div class="box_title">{{i.name}}</div>
						<div class="section">
							<div class="Top" v-if="i.sub_list && i.sub_list.length>0">
								<div class="li" @click="tapItem(items, i)" v-for="(items,indexs) in i.sub_list" :key="indexs+2">
									<div class="dio" :class="items.status?'act0':''" v-if="j == 0">{{indexs+1}}</div>
									<div class="dio" :class="items.status?'act1':''" v-if="j == 1">{{indexs+1}}</div>
									<div class="dio" :class="items.status?'act2':''" v-if="j == 2">{{indexs+1}}</div>
									<div class="main_a" :style="{'border':(indexs==0&&i.sub_list.length==1)||indexs+1===i.sub_list.length?'none':''}">
										<div class="section1">
											<div style="font-weight: 600;">{{items.name}}</div>
											<div style="width: 100px;">{{items.description}}</div>
										</div>
										<div class="gou">
											<img style="height: 30px;width: 30px;" src="../../src/static/image/gou1.png" v-if="items.status"/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { getCustomerStage, getMchStageList,changeCustomerStage } from '../../src/utils/API/api'
	export default {
		name: "surmount",
		data() {
			return {
				current: 0,
				showEdit:false,
				nowStage:null,
				scrollTop:0,
				tab_list: [{
						name: '引流期'
					},
					{
						name: '成长期'
					},
					{
						name: '衰退期'
					}
				],
				details_list: [],
			};
		},
		props:{
			detail_id:{
				type:[Number,String],
				default:null
			}
		},
		mounted() {
			this.getMchStageListfun()
		},
		methods: {
			getCustomerStagefun(){
				let that = this
				let param = {
					user_id:this.detail_id
				}
				getCustomerStage(param)
				.then(response => {
					this.nowStage = response.data.data
					this.details_list.map((x, y)=>{
						if(x.id == response.data.data.pid){
							this.current = y
						}
						x.sub_list.map((r)=>{
							if(r.id == response.data.data.stage_id){
								r.status = true
							} else {
								r.status = false
							}
						})
					})
				})
				.catch(error => {
					that.$message.error('调用接口失败！')
				})
			},
			getMchStageListfun(){
				let that = this
				let param = {}
				getMchStageList(param)
				.then(response => {
					this.details_list = response.data.data
					this.getCustomerStagefun()
				})
				.catch(error => {
					that.$message.error('调用接口失败！')
				})
			},
			tapItem(data, i){
				console.log(data)
				this.details_list.map((x)=>{
					x.sub_list.map((r)=>{
						if(r.id == data.id && x.id == i.id){
							r.status = true
							this.submitCustomerStage(r)
						} else {
							r.status = false
						}
					})
				})
				this.details_list = JSON.parse(JSON.stringify(this.details_list))
			},
			submitCustomerStage(data){
				if(!this.detail_id){
					this.$emit('submit', data)
					this.nowStage = data
					this.showEdit = false
					return
				}
				let that = this
				let param = {
					user_id:that.detail_id,
					stage_id:data.id
				}
				changeCustomerStage(param)
				.then(response => {
					that.getCustomerStagefun()
					that.$emit('changes')
					that.showEdit = false
					that.customer_list = response.data.data
				})
				.catch(error => {
					that.$message.error('调用接口失败！')
				})
			},
			changes(index) {
				this.current = index;
				index==0?this.scrollTop = 0:index==1?this.scrollTop = this.details_list[0].sub_list.length * 100:this.scrollTop = this.details_list[1].sub_list.length * 100
			},
			tapEditTag(){
				this.showEdit = true
				let that = this
				setTimeout(()=>{
					that.current==0?that.scrollTop = 0:that.current==1?that.scrollTop = that.details_list[0].sub_list.length * 100:that.scrollTop = that.details_list[1].sub_list.length * 100
				}, 500)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.title {
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #666666;
		font-weight: 600;
	}
	.surmountBox{
		background-color: #f8f8f8;
	}
	.surmount_section{
		display: flex;
		align-items: center;
	}
	.pop_title{
		height: 50px;
		background-color: #ffffff;
		font-size: 14px;
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		color: #666666;
		span{
			font-weight: 600;
		}
		img {
			height: 25px;
			width: 25px;
			position: absolute;
			top: 12px;
			right: 10px;
		}
	}
	.surmount_cont {
		width: 100%;
	}
	.stageBox {
		height: 28px;
		color: #000;
		padding: 0 10px;
		display: flex;
		align-items: center;
		border-radius: 40px;
		justify-content: center;
		font-size: 14px;
		margin:5px;
		border: 1px solid #5f5f5f;
	}
	.content_box_s {
		background-color: #fff;
		margin: 10px;
		padding: 10px 15px 0 15px;
		// box-shadow: 1px 1px 7px #d2d2d2;
	}
	.notData{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		color: #a8a8a8;
	}
	.box_title{
		display: flex;
		height: 40px;
		align-items: center;
		font-weight: 600;
		font-size: 14px;
	}
	.tag_box_title{
		font-size: 13px;
		display: flex;
		justify-content: space-between;
		color: #666666;
		.title{
			font-weight: 600;
		}
		.edit{
			display: flex;
			align-items: center;
			justify-content: center;
			img{
				margin: 0 5px;
				height: 15px;
				width: 15px;
			}
		}
	}
	.section {
		overflow: hidden;
		transition: all 0.2s ease;

		.Top {
			padding-top: 20px;
		}

		.close {
			width: 25px;
			height: 26px;
			position: relative;
			left: 310px;
			top: 10px;
		}

		.li {
			display: flex;
			position: relative;
			justify-content: space-between;
		}

		.xian {
			position: absolute;
			left: 36.5%;
			top: 0%;
			z-index: 7;
			border: 0.5px solid #cccccc;
		}

		.time {
			width: 165px;
			// border:1px solid red;
			font-size: 20px;
			color: #999999;
		}

		.main_a {
			width: 100%;
			padding-bottom:15px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-left: 0.5px solid #cccccc;
			padding-left: 60px;
			white-space: pre-wrap;
			word-break: break-all;
			margin-left: 20px;

			// border:1px solid red;
			:nth-child(odd) {
				font-size: 15px;
			}

			:nth-child(even) {
				padding: 10px 0px;
				font-size: 14px;
				color: #999999;
			}
		}

		.dio {
			display: flex;
			align-items: center;
			border-radius: 40px;
			justify-content: center;
			color: #ffffff;
			width: 30px;
			height: 30px;
			font-size: 13px;
			background-color: #d3d3d3;
			position: absolute;
			left: 5px;
		}

		.act0 {
			background-color: #b7905b !important;
		}
		.act1{
			background-color: #06A561 !important;
		}
		.act2{
			background-color: #F34A43 !important;
		}
	}
</style>
