<template>
  <el-dialog
    :lock-scroll="false"
    :title="title"
    :visible.sync="dialogFormVisible"
    :close-on-click-modal="false"
    class="studyChapter"
    @close="close"
  >
    <el-form ref="form" :model="form" label-width="auto">
      <el-row :gutter="20">
        <el-col class="addnewcate">
          <el-button @click="add_cate" type="primary">新增章节</el-button>
        </el-col>
        <el-col style="padding-top: 15px;">
          <el-card class="box-card" shadow="never" v-for="(item, index) in form.chapter" :key="index">
            <div slot="header" class="clearfix chapterCateHeader">
              <span v-if="item.edit==false">
                {{item.title}}
                <i class="el-icon-edit-outline" size="15" @click="item.edit=true"></i>
              </span>
              <span v-else>
                <el-input v-model="item.title" style="width: 60%;" placeholder="请输入章节名称">
                  <el-button slot="prepend" @click="removeItem(index)">删除</el-button>
                  <el-button slot="append" @click="item.edit=false">保存</el-button>
                </el-input>
              </span>
              <el-popover
                v-model="item.isShow"
                width="700"
                trigger="click">
                <el-row :gutter="20">
                  <el-col :span="7">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-select
                        v-model="searchForm.goodtype"
                        placeholder="请选择类型"
                        filterable
                        clearable
                        style="width: 100%"
                      >
                        <el-option
                          v-for="(item, index) in typeList"
                          :key="index"
                          :label="item.title"
                          :value="item.id"
                        ></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="7">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-input v-model="searchForm.search" clearable placeholder="请输入课程名称"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="3">
                    <el-form-item style="margin-bottom: 10px;">
                      <el-button type="primary" @click="searchMallGoodsList">搜索</el-button>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-table :data="mallGoodsList" @selection-change="selectionchange" style="width: 100%; min-height: 300px" max-height="300">
                  <el-table-column type="selection" width="60"></el-table-column>
                  <el-table-column property="goodtype_t" label="类型" width="60"></el-table-column>
                  <el-table-column property="title" label="名称"></el-table-column>
                </el-table>

                <el-row>
                  <el-col>
                    <el-pagination
                      :current-page="searchForm.page"
                      :layout="layout"
                      :page-size="searchForm.limit"
                      :total="total"
                      background
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange"
                      style="padding: 10px 0px 0px 0px;overflow: hidden;"
                    />
                  </el-col>
                </el-row>

                <el-row class="el-dialog__footer" style="margin: 10px -10px -10px -10px;">
                  <el-button type="primary" style="float: right;" @click="syncSelectedList(); item.isShow=false">保存</el-button>
                </el-row>

                <el-button slot="reference" style="float: right; padding: 3px 0" type="text" @click="getmallGoodsList(index);">+ 选择课程</el-button>
              </el-popover>

            </div>
            <div style="margin: -20px" class="goods_list">
              <draggable v-model="item.list" :group="'goodslist'+item.id">
                <transition-group>
                  <el-row v-for="(iitem, iindex) in item.list" :key="iindex">
                    <el-col :span="2" class="tdc">{{iindex+1}}</el-col>
                    <el-col :span="12">{{iitem.title}}</el-col>
                    <el-col :span="6" class="tdc">拖动排序</el-col>
                    <el-col :span="4" class="tdc">
                      <el-button @click="removeChapterItem(index,iindex)">移除</el-button>
                    </el-col>
                  </el-row>
                </transition-group>
              </draggable>
            </div>
          </el-card>


        </el-col>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>

import { ChapterEdit, courseList } from "../../src/utils/API/api";
import draggable from 'vuedraggable'

export default {
  name: 'studyChapter',
  components: {
    draggable,
  },
  data() {
    return {
      dialogFormVisible: false,
      title:'',
      form: {
        id:1,
        chapter:[
          {
            id:0,
            title:'第一章',
            edit:false,
            ids:[],
            list:[]
          }
        ]
      },
      searchForm:{
        page:1,
        limit:10,
        search:''
      },
      layout: 'total, sizes, prev, pager, next',
      total:0,
      listLoading: true,
      sectionList:[
        {id:0,title:'全部'},
        {id:1,title:'内部'},
        {id:2,title:'外部'},
      ],
      typeList:[
        {id:0,title:'全部'},
        {id:1,title:'图文'},
        {id:2,title:'视频'},
        {id:3,title:'音频'},
      ],
      mallGoodsList: [],
      processIndex:0,
      selectedList:[],
      mch_id: -1,
      rule_type: 0,
    }
  },
  created() {
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    showEdit(row) {
      this.title = '章节管理'
      this.form.id = row.id
      this.mch_id = row.mch_id
      this.rule_type = row.rule_type
      this.form.chapter = row.chapterList.length>0 ? row.chapterList : []
      if (this.form.chapter.length <= 0) {
        this.add_cate()
      }
      this.dialogFormVisible = true
    },
    close() {
      this.$refs['form'].resetFields()
      this.form = this.$options.data().form
      this.dialogFormVisible = false
    },
    save() {
      let that = this;
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          await ChapterEdit(this.form).then(res => {
            if (res.data.code == 0) {
              this.$message({
                showClose: true,
                message: "保存成功",
                type: "success",
              });
              this.$emit('fetch-data')
              this.close()
            } else {
              this.$message({
                showClose: true,
                message: res.data.message,
                type: "error",
              });
            }
          }).catch(() => {
          })
        } else {
          return false
        }
      })
    },
    handleSizeChange(val) {
      this.searchForm.limit = val
      this.getmallGoodsList()
    },
    handleCurrentChange(val) {
      this.searchForm.page = val
      this.getmallGoodsList()
    },
    //新增章节
    add_cate(){
      let that = this;
      let emptyChapter = {
        title:'',
        edit:true,
        ids:[],
        list:[]
      };
      this.form.chapter.push(emptyChapter);
    },
    //删除章节
    removeItem(index){
      let that = this;
      this.form.chapter.splice(index, 1);
    },
    // 搜索
    searchMallGoodsList() {
      this.searchForm.page = 1
      this.getmallGoodsList()
    },
    //获取课程列表
    getmallGoodsList(index){
      let that = this;
      if(index>=0){
        this.processIndex = index
      }
      this.listLoading = true
      this.searchForm.query_mch_id = this.mch_id
      this.searchForm.rule_type = this.rule_type
      courseList(this.searchForm).then(response => {
        let res = response.data
        this.total = res.data.dataCount;
        this.mallGoodsList = res.data.list;
        this.listLoading = false
      }).catch(() => {
        this.listLoading = false
      })
    },
    //移除商品列表
    removeChapterItem(index,iindex){
      this.form.chapter[index].ids.splice(iindex, 1);
      this.form.chapter[index].list.splice(iindex, 1);
      this.$forceUpdate()
    },
    //更改选择项
    selectionchange(data){
      let that = this;
      let processIndex = this.processIndex;
      let lists = [];
      this.selectedList = [];

      data.forEach((item, index) => {
        let list = {list_id:item.id, title:item.title}
        lists.push(list);
      })
      this.selectedList = lists;
    },
    //保存选择的列表项
    syncSelectedList(){
      let processIndex = this.processIndex;
      let lists = this.selectedList;
      let ids = this.form.chapter[processIndex].ids;

      lists.forEach((item, index) => {
        if(ids.indexOf(item.list_id) === -1){
          this.form.chapter[processIndex].ids.push(item.list_id);
          this.form.chapter[processIndex].list.push(item);
        }
      })

      this.selectedList = []
    }
  },
}
</script>

<style lang="scss" scoped>
.studyChapter {
  ::v-deep {
    .el-dialog__body{
      padding: 10px 0px;
    }
    .el-card{
      border-bottom: 0px;
      .el-card__header{
        padding: 10px 20px;
      }
    }

    .el-icon-edit-outline{
      cursor: pointer;
    }

    .addnewcate{
      margin: 0px 20px;
    }
    .chapterCate{

      .el-card__header {
        padding: 10px 20px;
        line-height: 21px;
      }
    }
    .goods_list{
      .tdc{
        text-align: center;
      }
      .el-row{
        padding: 10px 0px;
      }
    }
  }
}
</style>
