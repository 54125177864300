import { render, staticRenderFns } from "./specialTrainCreate.vue?vue&type=template&id=b0f4c800&scoped=true"
import script from "./specialTrainCreate.vue?vue&type=script&lang=js"
export * from "./specialTrainCreate.vue?vue&type=script&lang=js"
import style0 from "./specialTrainCreate.vue?vue&type=style&index=0&id=b0f4c800&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0f4c800",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\pastezuo\\Desktop\\工作\\yuyue_meirongya\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('b0f4c800')) {
      api.createRecord('b0f4c800', component.options)
    } else {
      api.reload('b0f4c800', component.options)
    }
    module.hot.accept("./specialTrainCreate.vue?vue&type=template&id=b0f4c800&scoped=true", function () {
      api.rerender('b0f4c800', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "components/specialTrain/specialTrainCreate.vue"
export default component.exports