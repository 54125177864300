<template>
  <div class="demo-app-main">
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-input v-model="activeValue" placeholder="请输入活动标题"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addMchActivity">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="编辑排班列表" :visible.sync="editStatus" width="60%" :before-close="closeSetting">
      
      <div>
        <div class="taskForm" style="display: flex;align-items: center;">
          <el-input placeholder="请输入排班名" maxlength="20" style="margin-right: 10px;width: 40%;" v-model="settingData.name"/>
          <el-time-select
          style="margin-right: 10px;"
            placeholder="起始时间"
            v-model="settingData.s_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:59'
            }">
          </el-time-select>
          <el-time-select
            placeholder="结束时间"
            v-model="settingData.e_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:59',
              minTime: settingData.s_time
            }">
          </el-time-select>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="editStatus = false">取 消</el-button>
        <el-button type="primary" @click="saveSettingClass">保存</el-button>
      </span>
    </el-dialog>
    <!-- 切换门店 -->
    <el-dialog :visible.sync="showSelectShop" append-to-body width="40%" title="切换门店">
      <div
        style="display:flex;align-items:center;justify-content:center; height:150px;margin-bottom:40px"
      >
        <el-select v-model="shopValue" placeholder="请选择" @change="optionChange">
          <el-option
            v-for="item in options1"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </el-select>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="delPop" append-to-body width="40%" height="10%" title="删除活动">
      <div
        style="display:flex;align-items:center;justify-content:center; height:150px;margin-bottom:40px"
      >
        <div v-for="(item, index) in calendarOptions.resources" class="boxs" :key="index">
          <img src="../../src/static/image/del.png" @click="delBtn(item)" style="position: absolute;top: -6px;right: -6px; height: 14px;width: 14px;" alt="" />
          {{ item.title }}</div>
      </div>
    </el-dialog>
    <FullCalendar ref="fullCalendar" class="demo-app-calendar" :options="calendarOptions"></FullCalendar>
    <FullCalendar ref="fullCalendarTwo" class="demo-app-calendar" :options="fullCalendarOptions"></FullCalendar>
    <div
      class="foot_box"
      style="height: 70px;color: #409eff;display: flex;align-items: center;">
      <div style="display: flex;align-items: center;" v-show="classList && classList.length>0">
        排班设置:
        <div style="display: flex;align-items: center;justify-content: space-between;">
          <div v-for="(item, index) in classList" :key="index" style="margin-right: 20px; display: flex;align-items: center;justify-content: center;"> <div :class="tapClassIndex == index?'acg':''" @click="tapClassFun(item,index)" style="margin: 10px;display: flex;align-items: center;justify-content: center;color: #6c6969;">{{ item.name }}</div>     <i class="el-icon-edit" @click="editSetting(item)"></i></div>
        </div>
      </div>
      <div @click="editSetting(settingData)" style="margin-left: 20px;">+新增排班</div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import moment from "moment";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { INITIAL_EVENTS, createEventId } from "../../src/event-utils";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import {
  myCustomerHomepage,
  addMchActivity,
  getMchActivityList,
  setMchActivityDate,
  delActiveApi,
  delMchActivityDate,
  getStaffClassList,
  setMyMonthClass,
  setMchClassInfo,
  getMchEmployeeClassList
} from "../../src/utils/API/api";
import store from "../../src/store";
export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      editStatus: false,
      settingData:{
        name:'',
        id:0,
        s_time:'',
        e_time:''
      },
      initData: [],
      timeValue: 0,
      shopValue: null,
      tapClassIndex:null,
      calendarTwoApi:null,
      tapClassData:null,
      value1: null,
      calendarMonth:null,
      delPop:false,
      clickTimer:null,
      currentYear:null,
      classList:[],
      clickIndex:null,
      shopTxt: "",
      options1: [],
      activeValue: "",
      currentMonth: "",
      showSelectShop: false,
      dialogVisible: false,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
          resourceTimelinePlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: "taggleShop",
          center: "title",
          right: "delCustomButton myCustomButton addButton outBtn todayBtn"
        },
        customButtons: {
          myCustomButton: {
            text: "+添加活动",
            click: this.addActive
          },
          delCustomButton: {
            text: "删除活动",
            click: this.delActive
          },
          taggleShop: { text: "切换门店", click: this.prevYearCustomClick },
          addButton: { text: "上月", click: this.oldMonthFun },
          outBtn: { text: "下月", click: this.nextMonthFun },
          todayBtn: { text: "今天", click: this.todayFun }
        },
        locale: "zh-cn",
        allDaySlot: "true",
        visibleRange: {
          start: "2024-05-01",
          end: "2024-06-01"
        },
        firstDay: 1,
        weekNumberCalculation: "ISO",
        timeGridEventMinHeight: "20",
        resourceAreaWidth: "10%",
        calendarWeekends: true,
        noEventsMessage: "暂无活动",
        stickyFooterScrollbar: true,
        stickyHeaderDates: true,
        nowIndicator: true,
        allDayDefault: false,
        weekends: true,
        editable: true,
        dragScroll: true,
        // headerToolbar: true,
        allDaySlot: false,
        resources: [],
        initialView: "resourceTimeline",
        draggable: true,
        events: [],
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        selectable: true,
        selectMirror: true,
        slotEventOverlap: true,
        dayMaxEvents: true,
        datesSet: this.handleDatesSet,
        selectMinDistance: 0,
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDrop: this.eventDrop,
        eventResize: this.handleEventResize
        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
      },
      fullCalendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          resourceTimeGridPlugin,
          resourceTimelinePlugin,
          interactionPlugin // needed for dateClick
        ],
        headerToolbar: {
          left: "",
          center: "",
          right: ""
        },
        locale: "zh-cn",
        editable:false,
        allDaySlot: "true",
        visibleRange: {
          start: "2024-05-01",
          end: "2024-06-01"
        },
        firstDay: 1,
        weekNumberCalculation: "ISO",
        timeGridEventMinHeight: "20",
        resourceAreaWidth: "10%",
        calendarWeekends: true,
        stickyFooterScrollbar: true,
        stickyHeaderDates: true,
        nowIndicator: true,
        allDayDefault: false,
        weekends: true,
        dragScroll: true,
        // headerToolbar: true,
        allDaySlot: false,
        resources: [],
        initialView: "resourceTimeline",
        draggable: true,
        events: [],
        // initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        selectable: true,
        selectMirror: true,
        slotEventOverlap: true,
        dayMaxEvents: true,
        is_boss: 1,
        datesSet: this.fullCalendarDatesSet,
        selectMinDistance: 0,
        select: this.fullCalendarDateSelect,
        eventClick: this.fullCalendarEventClick,
        eventsSet: this.fullCalendarEvents,
        eventResize: this.eventResize
        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
      }
    };
  },
  mounted() {
    let that = this;
    window.addEventListener("resize", function() {
      setTimeout(() => {
        that.changeSize();
      }, 100);
    });
    this.is_boss = store.state.is_boss;
    this.changeSize();
    this.myCustomerHomepageFun();
    this.getStaffClassList()
    // document.getElementsByClassName('fc-datagrid-body')[0].style.width= '150px'
    const today = new Date();
    this.currentYear = today.getFullYear();
    this.currentMonth = today.getMonth() + 1;
    this.currentMonth>9?this.currentMonth=this.currentMonth:this.currentMonth='0'+this.currentMonth;
  },
  methods: {
    changeSize() {
      document.getElementsByClassName(
        "fc-datagrid-cell-frame"
      )[0].style.height = "30px";
      document.getElementsByClassName(
        "fc-datagrid-cell-frame"
      )[0].style.padding = "0 0 0 20px";

      document.getElementsByClassName("fc-view-harness")[0].style.height =
        "130px";
      document.getElementsByClassName("fc-view-harness")[1].style.height =
        window.innerHeight - 270 + "px";
      document.getElementsByClassName("fc-header-toolbar")[1].style.margin =
        "0 0 0 0";
    },
    editSetting(data){
      this.settingData = data
      this.editStatus = true
    },
    closeSetting(){
      this.editStatus = false
    },
    setMyMonthClassFun(classValue, user_id){
      const wsInfo = localStorage.getItem('info');
      classValue.map((x)=>{
        x.title = x.show_title
      })
      let po = JSON.parse(wsInfo)
      let obj = {
        shop_id: this.value1,
        user_id: user_id,
        class: classValue,
        month: this.calendarMonth,
      };
      let that = this
      setMyMonthClass(obj)
        .then(res => {
          setTimeout(() => {
            that.getMchEmployeeClassListFun()
          }, 1000);
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    saveSettingClass(){
      let that = this
      setMchClassInfo(this.settingData)
        .then(res => {
          setTimeout(() => {
            that.getStaffClassList()
            that.editStatus = false
          }, 1000);
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    tapClassFun(item, index){
      if(this.tapClassIndex == index){
        this.tapClassIndex = null
        this.tapClassData = null
      } else {
        this.tapClassIndex = index
        this.tapClassData = item
      }
    },
    getStaffClassList(){
      let obj = {};
      getStaffClassList(obj)
        .then(res => {
          this.classList = res.data.data;
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    myCustomerHomepageFun() {
      let that = this;
        myCustomerHomepage(this.is_boss)
        .then(res => {
          if (res.data.data.mchStore.length == 0) {
            that.$message.error("您还没有门店，请先添加门店！");
            return;
          } else {
            let a = res.data.data.mchStore[0].mch_name;
            let b = res.data.data.mchStore[0].store_name;
            that.shopTxt = `${a}(${b})`;
            that.value1 = res.data.data.mchStore[0].id;
          }
          that.getMchActivityList();
          that.getMchEmployeeClassListFun()
          that.options1 = res.data.data.mchStore.map(item => {
            return {
              value: item.id,
              label: `${item.mch_name}(${item.store_name})`
            };
          });
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    delBtn(data){
      this.$confirm("确定要删除该活动？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(() => {
        this.delActFun(data)
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消"
        });
      });
    },
    fullCalendarDatesSet(e){
      this.calendarTwoApi =  this.$refs.fullCalendar.getApi()
    },
    delActFun(data){
      let obj = {
        activity_id: data.id,
      };
      delActiveApi(obj)
        .then(res => {
          this.delPop = false
          this.getMchActivityList();
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    addMchActivity() {
      this.loading();
      let obj = {
        mch_store_id: this.value1,
        month: this.currentYear+'-'+this.currentMonth,
        name: this.activeValue
      };
      addMchActivity(obj)
        .then(res => {

          this.getMchActivityList();
          this.dialogVisible =false
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    getMchEmployeeClassListFun(){
      let obj = {
        mch_store_id: this.value1,
        month: this.currentYear+'-'+this.currentMonth,
      };
      getMchEmployeeClassList(obj)
        .then(res => {
          res.data.data.map((a,b)=>{
            a.class_arr.map((x)=>{
              x.resourceId = res.data.data[b].user_id
              x.start = x.s_date
              x.end = x.e_date
            })
          })
          let arr =[]
          this.initData = res.data.data
          res.data.data.map((n)=>{
            arr.push(...n.class_arr)
          })
          let userArr = res.data.data.map((x)=>{
              return {
                id:x.user_id,
                title:x.user_name
              }
          })
          document.getElementsByClassName("fc-view-harness")[1].style.height = (userArr.length+1)*35+15 + "px";
          this.fullCalendarOptions.resources = userArr;
          arr.map((x)=>{
            x.title = x.title.slice(0,1)
          })
          this.fullCalendarOptions.events = arr;
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    getMchActivityList() {
      this.loading();
      let obj = {
        mch_store_id: this.value1,
        month: this.currentYear+'-'+this.currentMonth
      };
      getMchActivityList(obj)
        .then(res => {
          this.calendarOptions.resources = [];
          this.calendarOptions.events = [];
          this.calendarOptions.resources = res.data.data;
          let actArr = [];
          this.calendarOptions.resources.map(x => {
            x.title = x.name;
            x.date_arr.map(a => {
              a.resourceId = x.id;
              a.start = a.s_date;
              a.end = a.e_date;
              a.title = x.name;
            });
            actArr.push(...x.date_arr);
          });
          this.calendarOptions.events = actArr;
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    setMchActivityDate(s_date, e_date, date_id, id) {
      this.loading();
      let obj = {
        date_id: id,
        s_date: s_date,
        e_date: e_date,
        activity_id: date_id
      };
      setMchActivityDate(obj)
        .then(res => {
          this.getMchActivityList();
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    customDateRender(info) {
      const dayOfMonth = info.date.getDate();
      if (dayOfMonth <= 30) {
        info.el.innerHTML = dayOfMonth; // 设置单元格内容为1到30的数字
      } else {
        // 可选：处理31号及以后的情况，例如隐藏或显示其他内容
      }
    },
    optionChange(e) {
      this.value1 = e;
      this.$message({
        message: "切换成功！",
        type: "success"
      });
      this.getMchActivityList();
      this.showSelectShop = false;
    },
    loading: function() {
      var loading = this.$loading({
        lock: true,
        // spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.2)"
      });
      setTimeout(() => {
        loading.close();
      }, 2000);
    },
    eventDrop(selectInfo){
      this.setMchActivityDate(
        selectInfo.event.startStr,
        selectInfo.event.endStr,
        selectInfo.event._def.resourceIds[0],
        selectInfo.event.id
      )
    },
    prevYearCustomClick() {
      this.showSelectShop = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * 处理日期选择事件
     *
     * @param info 日期选择信息
     * @returns 无返回值
     */
    handleDateSelect(selectInfo) {
      let obj = {
        resourceId: selectInfo.resource.id,
        title: selectInfo.resource.title,
        start: selectInfo.startStr,
        end: selectInfo.endStr
      };
      this.setMchActivityDate(
        selectInfo.startStr,
        selectInfo.endStr,
        selectInfo.resource.id,
        0
      );
      this.calendarOptions.events.push(obj);
    },
    fullCalendarDateSelect(selectInfo) {
      if(!this.tapClassData){
        return
      }
      let days = this.getTotalDaysByDateRange(selectInfo.startStr, selectInfo.endStr)
      let that = this
      days.map((x)=>{
        x.title = that.tapClassData.name
        x.resourceId = selectInfo.resource.id
        x.date = x.start
        x.show_title = that.tapClassData.name
      })
      let eveArr = []
      this.loading()
      console.log(this.initData)
      this.initData.map((a)=>{
        if(a.user_id == selectInfo.resource.id){
          eveArr.push(...a.class_arr)
        }
      })
      eveArr.map((x)=>{
        x.date = x.s_date
      })
      let arr = days.concat(eveArr)
      let dd = this.arrDistinctByProp(arr, 'date')
      this.setMyMonthClassFun(dd, selectInfo.resource.id)
    },

    arrDistinctByProp(arr,prop){
        let obj = {};
        return arr.reduce(function(preValue,item){
            obj[item[prop]] ? '' : obj[item[prop]] = true && preValue.push(item);
            return preValue
        },[])
    },
    getTotalDaysByDateRange (startDate, endDate){
      //包含开始日期 结束日期在内的总天数
      let arr = []
      let startTime = new Date(startDate).getTime()
      let endTime = new Date(endDate).getTime()
      let total = Math.ceil(Math.abs(startTime - endTime) / 86400000)
      for(var i=0;i<total;i++){
        let start = startTime+i*86400000
        let end = startTime+(i+1)*86400000
        arr.push({
          start: this.formatDate(new Date(start)),
          end: this.formatDate(new Date(end))
        });
      }
      return arr
    },
    formatDate(date) {
        var year = date.getFullYear();
        var month = (date.getMonth() + 1).toString().padStart(2, '0');
        var day = date.getDate().toString().padStart(2, '0');
        return year + '-' + month + '-' + day;
    },
    addActive() {
      this.$refs.fullCalendar.getApi().gotoDate('2024-03-03')
      this.dialogVisible = true;
    },
    delActive(){
      this.delPop = true
    },
    handleEventResize(selectInfo) {
      this.setMchActivityDate(
        selectInfo.event.startStr,
        selectInfo.event.endStr,
        selectInfo.event._def.resourceIds[0],
        selectInfo.event.id
      );
    },
    handleDatesSet(e) {
      var Emonth = e.view.currentStart.getMonth()+1
      var Eyear = e.view.currentStart.getFullYear()
      Emonth>9?Emonth=Emonth:Emonth='0'+Emonth;
      this.calendarMonth = Eyear+'-'+Emonth
      this.currentMonth = Emonth
      this.currentYear = Eyear
      this.getMchActivityList()
      this.getMchEmployeeClassListFun()
    },
    //获取当月 下月时间
    getMonthBeginEnd(n) {
      var n = n;
      let obj = { start: "", end: "" };
      obj.start = moment(
        moment()
          .month(moment().month() + n)
          .startOf("month")
          .valueOf()
      ).format("YYYY-MM-DD");
      let endTemp = moment(
        moment()
          .month(moment().month() + (n + 1))
          .startOf("month")
          .valueOf()
      ).add(this.show_days, "days");
      switch (this.from_platform) {
        case 1:
        case 2:
          obj.end = endTemp.add(11, "days").format("YYYY-MM-DD");
          break;
        default:
          obj.end = endTemp.add(this.show_days, "days").format("YYYY-MM-DD");
      }

      return obj;
    },
    oldMonthFun() {
      this.timeValue--;
      this.calendarOptions.visibleRange = this.getMonthBeginEnd(this.timeValue);
      this.fullCalendarOptions.visibleRange = this.getMonthBeginEnd(this.timeValue);
    },
    todayFun() {
      this.timeValue = 0;
      this.calendarOptions.visibleRange = this.getMonthBeginEnd(this.timeValue);
      this.fullCalendarOptions.visibleRange = this.getMonthBeginEnd(this.timeValue);
    },
    nextMonthFun() {
      this.timeValue++;
      this.calendarOptions.visibleRange = this.getMonthBeginEnd(this.timeValue);
      this.fullCalendarOptions.visibleRange = this.getMonthBeginEnd(this.timeValue);
    },
    handleEventClick(info) {
      this.clickIndex = this.clickIndex + 1;
      if (this.clickTimer) {
          clearTimeout(this.clickTimer);
      }

      this.clickTimer = setTimeout(() => {
        if(this.clickIndex > 1){
          this.handleDelActiveTime(info)
        }
        this.clickIndex = 0;
      }, 200);
    },
    handleDelActiveTime(data){
      this.$confirm("确定要删除该数据？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(() => {
        this.delActTimeFun(data)
      })
      .catch(() => {
        this.$message({
          type: "info",
          message: "已取消"
        });
      });
    },
    delActTimeFun(data){
      let obj = {
        date_id: data.event.id,
      };
      delMchActivityDate(obj)
        .then(res => {
          this.getMchActivityList();
        })
        .catch(error => {
          this.$message.error("调用接口失败！");
        });
    },
    delClassFun(data){
      console.log(data)
      this.loading()
      let arr = []
      this.initData.map((a)=>{
        if(a.user_id == data.event._def.resourceIds[0]){
          arr.push(...a.class_arr)
        }
      })
      console.log(arr, 'eveArr------')
      arr.splice(arr.findIndex(item => item.c_id == data.event._def.extendedProps.c_id), 1)
      arr.map(x => {
        x.date = x.start
      })
      this.setMyMonthClassFun(arr, data.event._def.resourceIds[0])
    },
    fullCalendarEventClick(selectInfo) {
      let that = this
      this.clickIndex = this.clickIndex + 1;
      if (this.clickTimer) {
          clearTimeout(this.clickTimer);
      }

      this.clickTimer = setTimeout(() => {
        if(this.clickIndex > 1){
          that.$confirm("确定要删除该排班？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          })
          .then(() => {
            that.delClassFun(selectInfo)
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消"
            });
          });
        }
        this.clickIndex = 0;
      }, 200);
      // let days=[{
      //   title:that.tapClassData.name,
      //   end:selectInfo.event._def.extendedProps.e_date,
      //   start:selectInfo.event._def.extendedProps.s_date,
      //   date:selectInfo.event._def.extendedProps.s_date,
      // }]
    }
  }
};
</script>

<style lang='scss' scoped>
/deep/ .fc-timeline-slot-frame {
  height: 30px !important;
  font-size: 11px;
}
.boxs{
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 5px 15px;
  margin: 0 20px;
  border: 1px solid #8d8686;
}
.acg{
  padding: 5px 10px;border: 1px solid #409eff;
}
/deep/ .fc-timeline-lane-frame{
  border-bottom: 1px solid #ddd;
}
/deep/ .fc-datagrid-cell-frame{
  border-bottom: 1px solid #ddd;
}
/deep/ .fc-timeline-slot-cushion {
  height: 30px !important;
  line-height: 30px !important;
  padding: 0 !important;
}
/deep/.fc-day-sat {
  background: rgb(222, 245, 218) !important;
}
/deep/ .fc-day-sun {
  background: rgb(222, 245, 218) !important;
}
/deep/ .fc-toolbar.fc-header-toolbar {
  margin-bottom: 1.7em;
}
/deep/ .fc-scrollgrid-sync-table {
  position: relative;
  z-index: 1;
}
</style>