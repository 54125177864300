<template>
    <el-container>
      <div class="specialTraining">
        <div class="pageHeader">
          <el-row>
            <el-col>
              <div class="pageHeaderButtons">
                <el-button type="warning" @click="Edit">新建特训任务</el-button>
              </div>
  
              <el-form ref="form" :inline="true" class="demo-form-inline">
                <el-form-item>
                  <el-input v-model="searchForm.title" clearable placeholder="请输入搜索关键词"></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="getList">搜索</el-button>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
        </div>
  
        <div class="advList" style="width: 99%;" v-if="tableData && tableData.length>0">
          <el-table
            :data="tableData"
            v-loading="listLoading"
            :height="tableHeight"
            default-expand-all
            row-key="id"
            :show-overflow-tooltip="true"
            style="width: 100%"
            :row-style="{ height: '81px' }"
            :cell-style="{ textAlign: 'center' }"
            :header-cell-style="{
              color: '#333',
              fontSize: '14px',
              backgroundColor: '#F6F7FB',
              fontWeight: 'normal',
              textAlign: 'center',
            }"
          >
            <el-table-column prop="mch_name" label="店铺名"></el-table-column>
            <el-table-column prop="rule_type_txt" label="训练营权限类型"></el-table-column>
            <el-table-column
              prop="title"
              label="特训营主题"
              align="center"
  
            >
            </el-table-column>
            <el-table-column
              prop="start_time"
              label="开始时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="end_time"
              label="失效时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="create_time"
              label="创建时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="status_t"
              label="状态"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="任务数"
            >
              <template slot-scope="chapter">
                <el-button type="text" @click="chapterEdit(chapter.row)">({{chapter.row.chapterCount}})</el-button>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="100">
              <template slot-scope="scope">
                <el-button type="text" @click="Edit(scope.row)">编辑</el-button>
                <!-- <el-divider direction="vertical"></el-divider> -->
                <el-button type="text" @click="Del(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
  
          <el-pagination
            :current-page="searchForm.pageNo"
            :layout="layout"
            :page-size="searchForm.pageSize"
            :total="total"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
  
        </div>
  
        <!-- 新建 -->
        <SpecialTrainCreate ref="edit" @fetch-data="getList"></SpecialTrainCreate>
        <!-- 章节管理 -->
        <SpecialTrainChapter ref="chapteredit" @fetch-data="getList"></SpecialTrainChapter>
      </div>
    </el-container>
  </template>
  
  <script>
  import SpecialTrainCreate from './specialTrainCreate.vue'
  import SpecialTrainChapter from './specialTrainChapter.vue'
  import axios from "axios";
  import {specialTrainingList, specialTrainingDel} from "../../src/utils/API/api";
  export default {
    name: 'specialTraining',
    components: {
      SpecialTrainCreate,
      SpecialTrainChapter,
    },
    data() {
      return {
        tableHeight: document.documentElement.clientHeight,
        listLoading: true,
        layout: 'total, sizes, prev, pager, next, jumper',
        searchForm:{
          pageNo:1,
          pageSize:20,
          section:0,
          title:'',
          query_mch_id: '',
          rule_type: '',
        },
        sectionList:[
          {id:0,title:'全部'},
          {id:1,title:'内部'},
          {id:2,title:'外部'},
        ],
        total:0,
        tableData: [],
        mch_list: [],
        rule_list: [],
      }
    },
    computed: {
  
    },
    mounted() {
      this.getList();
    },
    methods: {
      //列表
      async getList(){
        var that = this;
        that.listLoading = true
        specialTrainingList(that.searchForm).then(response => {
          let res = response.data
          that.total = res.data.dataCount;
          that.tableData = res.data.list;
          console.log(that.tableData, 'tableData-------')
          that.listLoading = false
        }).catch(() => {
          that.listLoading = false
        })
      },
      handleSizeChange(val) {
        this.searchForm.pageSize = val
        this.getList()
      },
      handleCurrentChange(val) {
        this.searchForm.pageNo = val
        this.getList()
      },
      //新建
      Edit(row) {
        if (row) {
          this.$refs['edit'].showEdit(row)
        } else {
          this.$refs['edit'].showEdit()
        }
      },
      //章节管理
      chapterEdit(row) {
        this.$refs['chapteredit'].showEdit(row)
      },
      Del(row){
        if (!confirm('确认删除？')) {
          return false
        }
        specialTrainingDel({ id: row.id }).then(response => {
          let res = response.data
          if(res.code!==0){
            this.$message.error(res.error);
          } else {
            this.$message(
              {
                message: '删除成功',
                type: 'success'
              });
              this.getList();
          }
        }).catch(() => {
        })
      },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .specialTraining{
  
    // 盖掉那个 loading ...
    background-color: rgb(255, 255, 255);
    width: 100%;
    margin: 20px;
  
    .pageHeader{
      .el-form-item{
        margin-bottom: 12px;
      }
  
      .pageHeaderButtons{
        float: right;
      }
    }
  
    .el-pagination{
      text-align: right;
      margin: 10px 15px;
    }
  }
  </style>
  