import axios from 'axios'
import { Message } from 'element-ui'
import store from '../store'
 
const token ='xxxxxx'
const service = axios.create({
  baseURL: 'https://saas.meirongya.com',
  // baseURL:'http://192.168.110.247:8080',
  timeout: 1200000 // request timeout
})
 
service.interceptors.request.use(
 
  config => {
    if (token) {
      config.headers['Access-Id'] = store.state.token
      config.headers['store-id'] = store.state.store_id
      config.headers['Mch-Id'] = store.state.mch_id
    }
    return config
  },
  error => {
    Promise.reject(error)
  }
)


// response interceptor
service.interceptors.response.use(
  response => response,
  
  error => {
    console.log('err' + error) // for debug
    return Promise.reject(error)
  }
)
 
export default service